import { locationForEvent } from '@/Samples/stores/sampleDataStore';
import { layoutBuilder } from '@/shared/components/DDForm/layoutBuilder';
import { SelectMultipleDef } from '@/shared/components/DDForm/types/selectMultipleDef';
import {
  SelectOption,
  SelectSingleDef,
} from '@/shared/components/DDForm/types/selectSingleDef';
import { TypographyDef } from '@/shared/components/DDForm/types/typographyDef';
import { term } from '@/shared/utils/stringUtils';
import { DialogContent, DialogTitle } from '@mui/material';
import React from 'react';
import {
  Batch,
  EditInventoryEvent,
  InventoryEvent,
  Sample,
} from '../../../Samples/types';

export const INVENTORY_SAMPLE_FIELDS = 'inventory_sample_fields';
export const INVENTORY_EVENT_FIELDS = 'inventory_event_fields';

export type SharedInventoryDialog = {
  value?: { id?: number };
  isOpen: boolean;

  onSubmit: () => void;
  onClose: () => void;
  onDelete: () => void;
};

const { row, button, select, numberInput, typography, radioGroup } =
  layoutBuilder;

export const creditDebitToggleRow = ({
  unitString,
  currentAmount,
  getError,
}: {
  unitString: string;
  currentAmount: number;
  getError: () => string;
}) => {
  return row({ className: 'top-row' }, [
    numberInput({
      className: 'creditOrDebitValue',
      key: '.creditOrDebitValue',
      label: 'Amount',
      valueType: 'number',
      minValue: 0,
      error: getError(),
      width: 100,
    }),
    typography({ label: unitString, width: 50 }),
    radioGroup({
      key: '.creditOrDebit',
      horizontal: true,
      selectOptions: [
        { id: 'debit', label: 'Debit' },
        { id: 'credit', label: 'Credit' },
      ],
    }),
    typography({ label: `Current amount: ${currentAmount} ${unitString}` }),
  ]);
};

export const locationInUseWarningRow = () => {
  return row([
    typography({
      className: 'warningMessage',
      label:
        "This sample's location is in use. Before it can be restored, you must select a new location.",
    }),
  ]);
};
export const unitSelectRow = ({
  units,
  disabled,
}: {
  units: string[];
  disabled: boolean;
}) => {
  const unitSelectOptions = units.map((unit) => ({
    label: unit,
    id: unit,
  }));
  return select({
    className: 'unitsSelect',
    key: 'units',
    label: 'Units',
    valueType: 'string',
    required: true,
    typeahead: true,
    passIdOnSelect: true,
    selectOptions: unitSelectOptions,
    disabled,
  });
};
export const creditOrInitialRow = (
  value: Partial<Omit<Sample, 'inventory_events'>> & {
    inventory_events: EditInventoryEvent[];
  } & { id?: number },
  unitSelect: SelectSingleDef | SelectMultipleDef | TypographyDef,
  error: string | null,
  disabled: boolean,
) => {
  return row([
    numberInput({
      label: value.is_single_use ? 'Credit' : 'Initial Amount',
      disabled,
      key: '.initialValue',
      required: true,
      error,
    }),
    unitSelect,
  ]);
};
export const selectBatchRow = (
  batches: Pick<Batch, 'id' | 'name'>[],
  disabled: boolean,
) => {
  const batchOptions: Array<SelectOption> = [
    { id: null, label: '' },
    ...batches.map((batch) => ({ id: batch.id, label: batch.name })),
  ];

  return select({
    key: 'batch_id',
    label: term('batch', true),
    selectOptions: batchOptions,
    valueType: 'number',
    required: true,
    disabled,
  });
};
export const eventNeedsUpdatedLocation = (
  depleted: boolean,
  eventValue: Pick<
    InventoryEvent,
    'field_definitions' | 'inventory_event_fields'
  > | null,
  oldLocationValue: string | undefined,
) => {
  if (depleted) {
    const updatedLocation = locationForEvent(eventValue);
    if (updatedLocation?.value === oldLocationValue) {
      return true;
    }
  }
};
export const InventoryDialog = ({
  dialogTitle,
  contentClassName,
  children,
}: React.PropsWithChildren<{
  dialogTitle: string;
  contentClassName?: string;
}>) => {
  return (
    <>
      <DialogTitle className='muiDialog-title'>{dialogTitle}</DialogTitle>
      <DialogContent className={contentClassName}>{children}</DialogContent>
    </>
  );
};
