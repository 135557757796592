import React from 'react';

import { FieldDataType } from '@/FieldDefinitions/types';
import { DepleteButton } from '@/Samples/components/DepleteButton';
import {
  formatDateAndName,
  modifiedAtFormatter,
} from '@/Samples/components/formatDateAndName';
import { InventorySampleService } from '@/Samples/inventorySampleService';
import { ChemistryImage } from '@/components';
import { SortByColumnProps } from '@/components/SimpleDataTable/SortBy.types';
import { A } from '@/shared/components/sanitizedTags.js';
import { translateCaseInsensitive } from '@/shared/utils/stringUtils';
import { getVaultContextFromLocation } from '@/shared/utils/urlContext';
import { StringOrNumber } from '@/types';
import { Card, CircularProgress, Link } from '@mui/material';
import { Terminology } from 'javascripts/cross_app_utilities.js';
import { observer } from 'mobx-react';
import InfiniteScroll from 'react-infinite-scroller';
import { EVENT_FIELD_TYPE, SAMPLE_FIELD_TYPE } from '../consts';
import {
  CustomOrDefaultResultColumnId,
  FlattenedInventoryEvent,
  InventoryResultColumn,
  NestedInventoryResultColumn,
  ProcessedInventoryEntries,
  ResultColumnsEditorProps,
  TSearchHighlight,
  TSingleSearchHighlight,
} from '../types';
import InventoryHighlight from './InventoryHighlight';
import './InventorySearchTable.sass';
import {
  ExportColumnProps,
  NestedExpandableDataTable,
  RootedRow,
} from './NestedExpandableDataTable';
import { encodeFieldTypeInId } from './encodeUtils';

export type ClickedEntry = { row: RootedRow; childIndex: number };

export type InventorySearchTableProps =
  SortByColumnProps<CustomOrDefaultResultColumnId> &
    Pick<ExportColumnProps, 'onChangeVisibleColumns'> & {
      loading: boolean;
      hasMore: boolean;
      presorted: boolean;
      availableColumns: NestedInventoryResultColumn[];
      visibleColumns: InventoryResultColumn[];
      entryRows: ProcessedInventoryEntries;
      onClickEntry: (args: ClickedEntry) => void;
      getNextPage: (page: number) => void;
      onToggleSampleDepleted: (sample: FlattenedInventoryEvent) => void;
      highlights: TSearchHighlight;
      fieldDefinitions: ResultColumnsEditorProps['availableFilters'];
    };

const getCustomFieldDefinitionValue = (
  id: CustomOrDefaultResultColumnId,
  row: FlattenedInventoryEvent,
) => {
  // id for event & sample field could theoretically be the same, but quite unlikely - might be worth adding a distinguishing feature here though
  if (id in row.inventory_sample_fields) {
    return {
      value: row.inventory_sample_fields[id],
      field_type: SAMPLE_FIELD_TYPE,
    };
  }
  if (id in row.inventory_event_fields) {
    return {
      value: row.inventory_event_fields[id],
      field_type: EVENT_FIELD_TYPE,
    };
  }
  return { value: null };
};

// some of these props could probably be moved to state but IMO (V) having a stricter separation
// between function & UI really makes something like Storybook a lot easier to use - also
// makes this component more easily reusable elsewhere when not strictly tied to specific data
@observer
export class InventorySearchTable extends React.Component<InventorySearchTableProps> {
  renderDefaultFieldDefinitionCell(
    fieldDefinition: string | { value: string },
  ) {
    return typeof fieldDefinition === 'object' && 'value' in fieldDefinition
      ? fieldDefinition.value
      : fieldDefinition;
  }

  renderUrlFieldDefinitionCell({
    file_name,
    value,
  }: {
    file_name: string;
    value?: string;
  }) {
    const context = getVaultContextFromLocation();
    return (
      <A
        href={`${context}/files/${value}`}
        onClick={(event) => event.stopPropagation()}
      >
        {file_name}
      </A>
    );
  }

  highlightValue = (
    sampleId: number,
    columnId: StringOrNumber,
  ): TSingleSearchHighlight | null => {
    const highlightsForThisSample = this.props.highlights[sampleId];
    if (!highlightsForThisSample) {
      return null;
    }
    const highlightsForThisColumn =
      highlightsForThisSample.highlights[columnId];
    if (!highlightsForThisColumn) {
      return null;
    }
    return highlightsForThisColumn;
  };

  renderCell = (
    columnId: CustomOrDefaultResultColumnId | 'deplete',
    inventoryEntry: FlattenedInventoryEvent,
  ) => {
    const isDepleted = inventoryEntry.depleted;
    const originalEntry = this.extraInfoLookup[inventoryEntry.event_id];

    const onClickDeplete = (e: React.MouseEvent<Element, MouseEvent>) => {
      e.stopPropagation();
      e.preventDefault();
      this.props.onToggleSampleDepleted({
        ...inventoryEntry,
        ...originalEntry,
      });
    };
    const eventHighlight = this.highlightValue(
      inventoryEntry.event_id,
      columnId,
    );
    if (eventHighlight) {
      return <InventoryHighlight highlight={eventHighlight} />;
    }
    const sampleHighlight = this.highlightValue(inventoryEntry.id, columnId);
    if (sampleHighlight) {
      return <InventoryHighlight highlight={sampleHighlight} />;
    }
    switch (columnId) {
      case 'deplete': {
        return <DepleteButton onClick={onClickDeplete} depleted={isDepleted} />;
      }
      case 'depleted': {
        return inventoryEntry.depleted ? 'True' : 'False';
      }
      case 'structure': {
        return (
          <Card className="inventory_search__molecule_structure__borderless search_results__molecule_new_image_service">
            <ChemistryImage
              src={inventoryEntry.structure}
              width={250}
              height={250}
            />
          </Card>
        );
      }
      case 'molecule_name': {
        return (
          <Link
            onClick={(event) => {
              InventorySampleService.openMoleculeLinkInNewTab(
                originalEntry.molecule_id,
              );
              event.stopPropagation();
            }}
          >
            {inventoryEntry.molecule_name}
          </Link>
        );
      }
      case 'debit_credit': {
        const { Credit, Debit } =
          inventoryEntry.inventory_event_fields_name_keyed;
        const isCredit = Credit > 0;
        const plusMinus = isCredit ? '+' : '-';
        return (
          <span className={isCredit ? 'credit' : 'debit'}>
            {plusMinus} {isCredit ? Credit : Debit}({originalEntry.units})
          </span>
        );
      }

      case 'current_amount': {
        if (inventoryEntry[columnId] === 0) {
          return <>0 {originalEntry.units}</>;
        }
        const sign = inventoryEntry[columnId] > 0 ? '+' : '-';
        return (
          <span className="credit">
            {sign} {Math.abs(inventoryEntry[columnId])}({originalEntry.units})
          </span>
        );
      }

      case 'sample_created_date': {
        return formatDateAndName(
          inventoryEntry.sample_created_date,
          originalEntry.sample_created_by_user_full_name,
        );
      }

      case 'event_created_date': {
        return formatDateAndName(
          inventoryEntry.event_created_date,
          inventoryEntry.event_created_by_user_full_name,
        );
      }

      case 'sample_updated_date': {
        if (inventoryEntry?.is_single_use) {
          modifiedAtFormatter(
            inventoryEntry.sample_updated_by_user_full_name,
            inventoryEntry.sample_updated_date,
            inventoryEntry.sample_created_date,
          );
        }
        return formatDateAndName(
          inventoryEntry.sample_updated_date,
          inventoryEntry.sample_updated_by_user_full_name,
        );
      }
      case 'event_updated_date': {
        if (inventoryEntry?.is_single_use) {
          modifiedAtFormatter(
            inventoryEntry.event_modified_by_user_full_name,
            inventoryEntry.event_modified_date,
            inventoryEntry.event_created_date,
          );
        }
        return formatDateAndName(
          inventoryEntry.event_modified_date,
          inventoryEntry.event_modified_by_user_full_name,
        );
      }
      default: {
        const { value: customFieldDefinitionValue, field_type } =
          getCustomFieldDefinitionValue(columnId, inventoryEntry);
        if (
          customFieldDefinitionValue !== null &&
          customFieldDefinitionValue !== undefined
        ) {
          if (
            this.props.fieldDefinitions.byFieldThenSelectValue[field_type][
              columnId
            ].data_type_name === FieldDataType.File
          ) {
            return this.renderUrlFieldDefinitionCell(
              customFieldDefinitionValue,
            );
          }
          return this.renderDefaultFieldDefinitionCell(
            customFieldDefinitionValue,
          );
        }
        if (columnId in inventoryEntry) {
          return inventoryEntry[columnId];
        } else return <>{columnId}</>;
      }
    }
  };

  get selectedColumns(): ExportColumnProps['selectedColumns'] {
    const { availableColumns, visibleColumns } = this.props;
    const flatColumns = availableColumns.map((val) => val.children).flat();
    return visibleColumns.map((column) => {
      const flat = flatColumns.find((available) => available.id === column.id);
      return encodeFieldTypeInId({ field_type: flat.field_type, id: flat.id });
    });
  }

  get extraInfoLookup() {
    // column names used as information in other columns not of same name - TODO don't like this pattern
    const compoundedColumns: { id: keyof FlattenedInventoryEvent }[] = [
      { id: 'sample_created_by_user_full_name' },
      { id: 'sample_updated_by_user_full_name' },
      { id: 'molecule_id' },
      { id: 'units' },
      { id: 'batch' },
    ];
    const infoLookupEntries = this.props.entryRows.map((entryRow) => {
      const buildColumnEntryForRow = ({ id }) => [id, entryRow[id]];

      const nestedObjectEntries = entryRow.inventory_events.map(
        (entryEvent) => {
          return [
            entryEvent.event_id,
            Object.fromEntries(compoundedColumns.map(buildColumnEntryForRow)),
          ];
        },
      );

      return nestedObjectEntries;
    });

    return Object.fromEntries(infoLookupEntries.flat());
  }

  render() {
    const {
      loading,
      entryRows,
      visibleColumns,
      setSortBy,
      sortBy,
      getNextPage,
    } = this.props;
    const unsortableColumns: CustomOrDefaultResultColumnId[] = ['structure'];
    const rootedSamples = entryRows
      .filter((val) => val.inventory_events.length > 0)
      .map((sample, ii) => {
        const sharedColumnValues = Object.fromEntries(
          [
            { id: 'id' },
            { id: 'depleted' },
            { id: 'inventory_sample_fields' },
            { id: 'inventory_event_fields' },
            ...visibleColumns,
          ].map(({ id }) => [id, sample[id]]),
        );
        const sortedInventoryEvents = [...sample.inventory_events].map(
          (event) => ({
            ...sharedColumnValues,
            ...event,
          }),
        );
        const [rootEvent, ...childrenEvents] = sortedInventoryEvents;

        const children = sample.is_single_use ? [] : childrenEvents;
        return {
          root: rootEvent,
          children,
          rootID: `${ii}`,
          rootExpandable: children.length > 0,
          ...sample,
        };
      });
    return (
      <InfiniteScroll
        element="div"
        pageStart={0}
        loadMore={(page) => {
          if (!loading) {
            getNextPage(page);
          }
        }}
        hasMore={this.props.hasMore}
        loader={
          <CircularProgress
            key={0}
            className="circular_progress infinite_scroll__loader"
          />
        }
      >
        <div className="InventorySearchTable">
          <NestedExpandableDataTable<CustomOrDefaultResultColumnId | 'deplete'>
            presorted={this.props.presorted}
            headerClassName="sticky-header"
            editorProps={{
              availableColumns: this.props.availableColumns,
              onChangeVisibleColumns: this.props.onChangeVisibleColumns,
              selectedColumns: this.selectedColumns,
            }}
            rowColors={{
              rootRow: '#FFFFFF',
              childRow: '#DDDDDD',
            }}
            columns={[
              ...visibleColumns.map((val) => ({
                ...val,
                label: translateCaseInsensitive(val.label, true),
                isSortable: !unsortableColumns.includes(val.id),
              })),
              { id: 'deplete', label: 'Deplete Sample' },
            ]}
            rootExclusiveColumnIds={[
              'deplete',
              'current_amount',
              'solvent_name',
              'salt_name',
              'name',
              'structure',
              'sample_created_date',
            ]}
            getRowClassName={(row) => {
              const depletedClass = row.depleted ? ' depleted-row' : '';
              return `molecule-${
                rootedSamples[row.index].molecule_name
              }${depletedClass}`;
            }}
            rootRows={rootedSamples}
            onClickRow={(clickedOn) => {
              if (!rootedSamples[clickedOn.rootIndex].depleted) {
                return this.props.onClickEntry({
                  row: clickedOn.row,
                  childIndex: clickedOn.childIndex,
                });
              }
            }}
            sortBy={sortBy}
            setSortBy={setSortBy}
            renderCell={(columnId, entry) =>
              this.renderCell(columnId, entry as FlattenedInventoryEvent)
            }
            tooltipTextMap={{
              alreadyCollapsed: `Show Older ${Terminology.dictionary['entry.other']}`,
              alreadyExpanded: `Hide Older ${Terminology.dictionary['entry.other']}`,
            }}
          />
        </div>
      </InfiniteScroll>
    );
  }
}
