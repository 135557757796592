import { deepClone } from '@/Annotator/data/utils';
import { FIELD_WELLNAME } from './TableDataComposer';

export type TabbedCells = string[][];

export enum ParsingType {
  FixedValue = 'fixed_value',
  TabularData = 'tabular_data',
  PlateBlock = 'plate_block',
}

export interface ParsingArea {
  x: number;
  y: number;
  w: number;
  h: number;
}

function areasEqual(area1: ParsingArea, area2: ParsingArea): boolean {
  return area1.x == area2.x && area1.y == area2.y && area1.w == area2.w && area1.h == area2.h;
}

export interface ReplicateDetails {
  fieldName: string;
  columnValueField: string;
}

export interface FixedValue {
  areaValue?: ParsingArea;
  specificValue?: string;
  fieldName: string;
}

export interface ParsingSection {
  type: ParsingType;
  tabIndex: number;
}

export interface ParsingFixedValue extends ParsingSection, FixedValue {
}

export interface ParsingTabularData extends ParsingSection {
  areaContent: ParsingArea;
  areaHeader: ParsingArea;
  joinField: string | string[];
  replicateSets: string[][];
  replicateDetails: ReplicateDetails[];
  fixedValues: FixedValue[];
}

export type ParsingPlateBlockWellPadding = null | 2 | 3; // null=strip leading zeros, >=2 to pad on left with zeros

export enum ParsingPlateBlockMultiStamp {
  Local = 'local',
  Global = 'global',
}

export interface ParsingPlateBlock extends ParsingSection {
  areaBlock: ParsingArea;
  areaColumns: ParsingArea;
  areaRows: ParsingArea;
  joinField: string | string[],
  fieldName: string;
  fixedValues: FixedValue[];
  wellNumberPadding: ParsingPlateBlockWellPadding;
  multiStamp?: ParsingPlateBlockMultiStamp;
}

export interface SavedTemplateContent {
  tabNames: string[];
  parsingSections: ParsingSection[];
}

export interface SavedTemplate {
  id: number;
  name: string;
  template_json: SavedTemplateContent;
  user_id?: number;
  created_at?: string;
  updated_at?: string;
  user?: {
    first_name: string,
    last_name: string,
    email: string,
  }
  can_edit?: boolean;
}

export interface SavedTemplateMatch extends SavedTemplate {
  score: number;
}

const ALPHABASE = 10000, CODE_A = 'A'.charCodeAt(0), CODE_Z = 'Z'.charCodeAt(0);

export class ParserUtil {
  public static ascertainGridSize(sheet: TabbedCells): {nrows: number, ncols: number, pages: number[][]} {
    let nrows = sheet.length, ncols = 0;
    for (const row of sheet) {
      let sz = row.length;
      while (sz > 0 && !row[sz - 1]) sz--;
      ncols = Math.max(ncols, sz);
    }
    while (nrows > 0) {
      if (sheet[nrows - 1].some((v) => !!v)) break;
      nrows--;
    }
    const pages = this.formulatePages(sheet, nrows);
    return { nrows, ncols, pages };
  }

  private static formulatePages(sheet: TabbedCells, nrows: number): number[][] {
    const indices: number[] = [], cellWidth: number[] = [];
    for (let n = 0; n < nrows; n++) {
      indices.push(n);
      let cw = 0;
      for (const cell of sheet[n] ?? []) {
        if (cell) cw++;
      }
      cellWidth.push(cw);
    }

    const PAGE_SIZE = 200, MULLIGAN = 15;
    if (nrows < PAGE_SIZE + MULLIGAN) return [indices];

    const pages: number[][] = [];

    while (indices.length > 0) {
      if (indices.length < PAGE_SIZE + MULLIGAN) {
        pages.push(indices);
        break;
      }

      let cutidx = PAGE_SIZE;
      for (let delta = 1; delta < MULLIGAN; delta++) {
        const i1 = PAGE_SIZE - delta, i2 = PAGE_SIZE + delta;
        if (i1 >= 0 && cellWidth[i1] < cellWidth[cutidx]) cutidx = i1;
        if (i2 < indices.length && cellWidth[i2] < cellWidth[cutidx]) cutidx = i2;
      }

      pages.push(indices.splice(0, cutidx + 1));
      cellWidth.splice(0, cutidx + 1);
    }

    return pages;
  }

  public static sanitizeSections(sheets: TabbedCells[], inSections: ParsingSection[]): ParsingSection[] {
    const sanitizeTabularData = (section: ParsingTabularData): ParsingTabularData => {
      const sheet = sheets[section.tabIndex];
      let { joinField } = section;
      if (typeof joinField == 'string') joinField = [joinField];
      if (!joinField) joinField = [];
      const fieldNames = ParserUtil.tableFieldNames(sheet, section, true);
      joinField = joinField.filter((fv) => fieldNames.includes(fv));
      if (joinField.length == 0) joinField = [fieldNames[0]];

      return { ...section, joinField };
    };

    const sanitizePlateBlock = (section: ParsingPlateBlock): ParsingPlateBlock => {
      let { joinField } = section;
      if (typeof joinField == 'string') joinField = [joinField];
      if (!joinField) joinField = [];
      const fieldNames = ParserUtil.plateFieldNames(section, true);
      joinField = joinField.filter((fv) => fieldNames.includes(fv));
      if (joinField.length == 0) joinField = [fieldNames[0]];

      return { ...section, joinField };
    };

    return inSections.map((section) => {
      if (section.type == ParsingType.TabularData) {
        return sanitizeTabularData(section as ParsingTabularData);
      } else if (section.type == ParsingType.PlateBlock) {
        return sanitizePlateBlock(section as ParsingPlateBlock);
      } else {
        return section;
      }
    });
  }

  public static guessInitialSections(tabNames: string[], sheets: TabbedCells[]): ParsingSection[] {
    const sections: ParsingSection[] = [];

    for (let n = 0; n < sheets.length; n++) {
      let topY = 0;
      for (let count = 0; ; count++) {
        const fieldName = count == 0 ? tabNames[n] : `${tabNames[n]}${count + 1}`;
        const plateBlock = this.findNextPlateBlock(n, sheets[n], topY, fieldName);
        if (plateBlock) {
          sections.push(plateBlock);
          topY = plateBlock.areaBlock.y + plateBlock.areaBlock.h;
        } else {
          break;
        }
      }
    }

    return sections;
  }

  private static mapBand(strlist: string[]): number[] {
    return strlist.map((str) => {
      if (!str) return 0;
      const v = parseInt(str);
      if (v > 0) return v;
      if (str.length == 1) {
        const code = str.charCodeAt(0);
        if (code >= CODE_A && code <= CODE_Z) return ALPHABASE + code - CODE_A;
      } else if (str.length == 2) {
        const code1 = str.charCodeAt(0), code2 = str.charCodeAt(1);
        if (code1 >= CODE_A && code1 <= CODE_Z && code2 >= CODE_A && code2 <= CODE_Z) return ALPHABASE + (code1 - CODE_A + 1) * 26 + code2 - CODE_A;
      }
      return 0;
    });
  }

  private static findNextPlateBlock(tabIndex: number, sheet: TabbedCells, topY: number, fieldName: string): ParsingPlateBlock {
    const { nrows, ncols } = this.ascertainGridSize(sheet);

    const findStripColumns = (initY: number): ParsingArea => {
      const band = this.mapBand(sheet[initY]);
      for (let x = 1; x < ncols - 1; x++) {
        if (band[x] != 1 && band[x] != ALPHABASE) continue;
        let w = 1;
        for (; x + w < ncols; w++) {
          if (band[x + w] != band[x + w - 1] + 1) break;
        }
        if (w > 1) return { x, y: initY, w, h: 1 };
      }
      return null;
    };

    const findStripRows = (initX: number, initY: number): ParsingArea => {
      const band = this.mapBand(sheet.map((row) => row[initX]));
      if (band[initY] != 1 && band[initY] != ALPHABASE) return null;
      let h = 1;
      for (; initY + h < nrows; h++) {
        if (band[initY + h] != band[initY + h - 1] + 1) break;
      }
      if (h > 1) return { x: initX, y: initY, w: 1, h };
      return null;
    };

    for (let y = topY; y < nrows; y++) {
      const areaColumns = findStripColumns(y);
      if (!areaColumns) continue;
      const areaRows = findStripRows(areaColumns.x - 1, y + 1);
      if (!areaRows) continue;

      return {
        type: ParsingType.PlateBlock,
        tabIndex,
        areaBlock: { x: areaColumns.x, y: areaRows.y, w: areaColumns.w, h: areaRows.h },
        areaColumns,
        areaRows,
        joinField: [FIELD_WELLNAME],
        fieldName,
        fixedValues: [],
        wellNumberPadding: null,
      };
    }

    return null;
  }

  public static produceFixedValue(tabIndex: number, sheet: TabbedCells, area: ParsingArea): ParsingFixedValue {
    if (!area) {
      return {
        type: ParsingType.FixedValue,
        tabIndex,
        specificValue: '',
        fieldName: '',
      };
    }
    if (area.w != 1 || area.h != 1 || !sheet[area.y][area.x]) return null;

    let fieldName = '';
    if (area.x > 0 && sheet[area.y][area.x - 1]) {
      fieldName = sheet[area.y][area.x - 1];
    } else if (area.y > 0 && sheet[area.y - 1][area.x]) {
      fieldName = sheet[area.y - 1][area.x];
    }
    fieldName = fieldName.trim();
    if (fieldName.endsWith(':')) fieldName = fieldName.substring(0, fieldName.length - 1);

    return {
      type: ParsingType.FixedValue,
      tabIndex,
      areaValue: { ...area },
      fieldName,
    };
  }

  public static produceShrunkArea(sheet: TabbedCells, plateArea: ParsingArea, selectedArea: ParsingArea): ParsingArea {
    if (!selectedArea) return null;

    const px1 = plateArea.x, py1 = plateArea.y, px2 = px1 + plateArea.w, py2 = py1 + plateArea.h;
    const sx1 = selectedArea.x, sy1 = selectedArea.y, sx2 = sx1 + selectedArea.w, sy2 = sy1 + selectedArea.h;

    if (px1 == sx1 && py1 == sy1 && px2 == sx2 && py2 == sy2) return null;
    if (sx1 < px1 || sx1 > px2 || sy1 < py1 || sy1 > py2) return null;
    if (sx2 < px1 || sx2 > px2 || sy2 < py1 || sy2 > py2) return null;

    return selectedArea;
  }

  public static produceTabularData(tabIndex: number, sheet: TabbedCells, area: ParsingArea): ParsingTabularData {
    if (!area) return null;
    if (area.y == 0) return null;
    if (area.w <= 1 && area.h <= 1) return null;

    let joinField: string[] = null;
    for (let n = 0; n < area.w; n++) {
      const fname = sheet[area.y - 1][area.x + n];
      if (fname) {
        joinField = [fname];
        break;
      }
    }

    return {
      type: ParsingType.TabularData,
      tabIndex,
      areaContent: { ...area },
      areaHeader: { x: area.x, y: area.y - 1, w: area.w, h: 1 },
      joinField,
      replicateSets: [],
      replicateDetails: [],
      fixedValues: [],
    };
  }

  public static producePlateBlock(tabIndex: number, sheet: TabbedCells, area: ParsingArea): ParsingPlateBlock {
    if (!area) return null;
    if (area.x == 0 || area.y == 0) return null;
    if (area.w <= 1 && area.h <= 1) return null;

    for (let n = 0; n < area.w; n++) {
      if (!sheet[area.y - 1][area.x + n]) return null;
    }
    for (let n = 0; n < area.h; n++) {
      if (!sheet[area.y + n][area.x - 1]) return null;
    }

    return {
      type: ParsingType.PlateBlock,
      tabIndex,
      areaBlock: { ...area },
      areaColumns: { x: area.x, y: area.y - 1, w: area.w, h: 1 },
      areaRows: { x: area.x - 1, y: area.y, w: 1, h: area.h },
      joinField: [FIELD_WELLNAME],
      fieldName: '',
      fixedValues: [],
      wellNumberPadding: null,
    };
  }

  public static reassignTabularHeader(section: ParsingTabularData, area: ParsingArea): ParsingArea {
    if (!area || area.y + area.h > section.areaContent.y) return null;
    if (area.y == section.areaHeader.y && area.h == section.areaHeader.h) return null; // it's the same, no reassign
    return { x: section.areaContent.x, y: area.y, w: section.areaContent.w, h: area.h };
  }

  public static extendTabularContent(sheet: TabbedCells, section: ParsingTabularData): ParsingArea {
    const area = { ...section.areaContent };
    for (; area.y + area.h < sheet.length; area.h++) {
      let anyCells = false;
      for (let n = 0; n < area.w; n++) {
        if (sheet[area.y + area.h][area.x + n]) {
          anyCells = true;
          break;
        }
      }
      if (!anyCells) break;
    }
    return area.h > section.areaContent.h ? area : null;
  }

  public static tableFieldNames(sheet: TabbedCells, section: ParsingTabularData, withFixed: boolean): string[] {
    const fieldNames: string[] = [];
    const area = section.areaHeader;

    for (let i = 0; i < area.w; i++) {
      const bits: string[] = [];
      for (let j = 0; j < area.h; j++) {
        const cell = sheet[area.y + j][area.x + i];
        if (cell) bits.push(cell);
      }
      fieldNames.push(bits.length == 0 ? '?' : bits.join('-'));
    }

    const fixedNames = withFixed ? (section.fixedValues ?? []).map((fv) => fv.fieldName).filter((fv) => !!fv) : [];

    return [
      ...fixedNames,
      ...fieldNames,
    ];
  }

  public static plateFieldNames(section: ParsingPlateBlock, withFixed: boolean): string[] {
    const fixedNames = withFixed ? (section.fixedValues ?? []).map((fv) => fv.fieldName) : [];

    return [
      FIELD_WELLNAME,
      section.fieldName,
      ...fixedNames,
    ].filter((fv) => !!fv);
  }

  public static matchTemplateToSheets(template: SavedTemplate, tabNames: string[], sheets: TabbedCells[]): SavedTemplateMatch {
    const tableHeaderHash = (section: ParsingTabularData): string => {
      return `${section.tabIndex}:x=${section.areaHeader.x}:y=${section.areaHeader.y}`;
    };
    const countOfHeaders = new Map<string, number>();
    for (const section of template.template_json.parsingSections) {
      if (section.type == ParsingType.TabularData) {
        const hash = tableHeaderHash(section as ParsingTabularData);
        countOfHeaders.set(hash, (countOfHeaders.get(hash) || 0) + 1);
      }
    }

    const isCellBlank = (sheet: TabbedCells, x: number, y: number): boolean => {
      if (y >= sheet.length) return true;
      return !sheet[y][x];
    };

    const validateFixedValue = (section: ParsingFixedValue, sheet: TabbedCells): boolean => {
      if (!section.areaValue) return true;
      return !isCellBlank(sheet, section.areaValue.x, section.areaValue.y);
    };

    const validateTabularData = (section: ParsingTabularData, sheet: TabbedCells): boolean => {
      const { areaContent, areaHeader } = section;
      if (areaContent.y + 1 /* areaContent.h */ > sheet.length || areaHeader.y + areaHeader.h > sheet.length) return false;

      const isOverlappingTable = countOfHeaders.get(tableHeaderHash(section)) > 1;
      if (isOverlappingTable && areaContent.y + areaContent.h > sheet.length) return false;

      const fieldNames = this.tableFieldNames(sheet, section, true);
      if (fieldNames.some((fieldName) => !fieldName)) return false;

      for (let y = areaContent.y, j = 0; y < sheet.length && j < areaContent.h; y++, j++) {
        let anyNonBlank = false;
        for (let x = areaContent.x, i = 0; i < areaContent.w; x++, i++) {
          if (sheet[y][x]) {
            anyNonBlank = true;
            break;
          }
        }
        if (!anyNonBlank) return false;
      }

      for (const fv of (section.fixedValues ?? [])) {
        if (fv.areaValue && isCellBlank(sheet, fv.areaValue.x, fv.areaValue.y)) return false;
      }

      return true;
    };

    const validatePlateBlock = (section: ParsingPlateBlock, sheet: TabbedCells): boolean => {
      const { areaBlock, areaColumns, areaRows } = section;
      if (areaBlock.y + areaBlock.h > sheet.length || areaColumns.y + areaColumns.h > sheet.length || areaRows.y + areaRows.h > sheet.length) return false;

      const strCol: string[] = [], strRow: string[] = [];
      for (let n = 0; n < areaColumns.w; n++) strCol.push(sheet[areaColumns.y][areaColumns.x + n]);
      for (let n = 0; n < areaRows.h; n++) strRow.push(sheet[areaRows.y + n][areaRows.x]);
      const bandCol = this.mapBand(strCol), bandRow = this.mapBand(strRow);
      if ((bandCol[0] != 1 && bandCol[0] != ALPHABASE) || (bandRow[0] != 1 && bandRow[0] != ALPHABASE)) return false;
      for (let n = 1; n < bandCol.length; n++) if (bandCol[n] != bandCol[n - 1] + 1) return false;
      for (let n = 1; n < bandRow.length; n++) if (bandRow[n] != bandRow[n - 1] + 1) return false;

      for (const fv of (section.fixedValues ?? [])) {
        if (fv.areaValue && isCellBlank(sheet, fv.areaValue.x, fv.areaValue.y)) return false;
      }

      return true;
    };

    const adjustTabularRows = (section: ParsingTabularData, sheet: TabbedCells): ParsingTabularData => {
      const isOverlappingTable = countOfHeaders.get(tableHeaderHash(section)) > 1;

      const areaContent = { ...section.areaContent };

      if (areaContent.y + areaContent.h > sheet.length) areaContent.h = sheet.length - areaContent.y;

      const rowHasAnything = (y: number): boolean => {
        for (let j = 0; j < areaContent.w; j++) {
          if (sheet[y][areaContent.x + j]) return true;
        }
      };

      for (let i = 0; i < areaContent.h - 1; i++) {
        if (rowHasAnything(areaContent.y + i)) continue;
        areaContent.h = i;
        break;
      }

      if (!isOverlappingTable) {
        while (areaContent.y + areaContent.h < sheet.length) {
          if (!rowHasAnything(areaContent.y + areaContent.h)) break;
          areaContent.h++;
        }
      }

      return { ...section, areaContent };
    };

    // NOTE: tab names have to match exactly; could consider relaxing this requirement
    const match: SavedTemplateMatch = {
      id: template.id,
      name: template.name,
      can_edit: template.can_edit,
      template_json: null,
      score: null,
    };

    for (const tabIndex of new Set(template.template_json.parsingSections.map((section) => section.tabIndex))) {
      if (tabIndex >= tabNames.length) return match;
      const tabSections = template.template_json.parsingSections.filter((section) => section.tabIndex == tabIndex);
      const anyGlobalStamps = tabSections.some((section) => section.type == ParsingType.PlateBlock && (section as ParsingPlateBlock).multiStamp == ParsingPlateBlockMultiStamp.Global);
      if (!anyGlobalStamps && tabNames[tabIndex] != template.template_json.tabNames[tabIndex]) return match;
    }

    const parsingSections: ParsingSection[] = [];
    for (const section of template.template_json.parsingSections) {
      const sheet = sheets[section.tabIndex];
      if (section.type == ParsingType.FixedValue) {
        if (validateFixedValue(section as ParsingFixedValue, sheet)) parsingSections.push({ ...section });
      } else if (section.type == ParsingType.TabularData) {
        if (validateTabularData(section as ParsingTabularData, sheet)) parsingSections.push({ ...adjustTabularRows(section as ParsingTabularData, sheet) });
      } else if (section.type == ParsingType.PlateBlock) {
        if (validatePlateBlock(section as ParsingPlateBlock, sheet)) parsingSections.push({ ...section });
      }
    }

    match.template_json = {
      tabNames,
      parsingSections,
    };
    match.score = parsingSections.length / template.template_json.parsingSections.length;
    return match;
  }

  public static impliedStampedBlocks(parsingSections: ParsingSection[], sheets: TabbedCells[]): ParsingPlateBlock[] {
    const reservedAreas = new Map<number, ParsingArea[]>();
    for (const section of parsingSections) {
      const areaList = reservedAreas.get(section.tabIndex) ?? [];
      if (section.type == ParsingType.FixedValue) {
        const { areaValue } = section as ParsingFixedValue;
        if (areaValue) {
          reservedAreas.set(section.tabIndex, [...areaList, areaValue]);
        }
      } else if (section.type == ParsingType.TabularData) {
        const { areaContent } = section as ParsingTabularData;
        reservedAreas.set(section.tabIndex, [...areaList, areaContent]);
      } else if (section.type == ParsingType.PlateBlock) {
        const { areaBlock } = section as ParsingPlateBlock;
        reservedAreas.set(section.tabIndex, [...areaList, areaBlock]);
      }
    }

    const sourceBlocks = parsingSections.filter((section) => section.type == ParsingType.PlateBlock && (section as ParsingPlateBlock).multiStamp != null) as ParsingPlateBlock[];
    sourceBlocks.sort((pb1, pb2) => {
      if (pb1.tabIndex != pb2.tabIndex) return pb1.tabIndex - pb2.tabIndex;
      if (pb1.areaBlock.y != pb2.areaBlock.y) return pb1.areaBlock.y - pb2.areaBlock.y;
      return pb1.areaBlock.x - pb2.areaBlock.x;
    });
    const sourceTabs: number[] = [];
    for (const plate of sourceBlocks) {
      if (!sourceTabs.includes(plate.tabIndex)) {
        sourceTabs.push(plate.tabIndex);
      }
    }

    const impliedBlocks: ParsingPlateBlock[] = [];

    const considerPlateBlock = (tabIndex: number, refBlock: ParsingPlateBlock, plateBlock: ParsingPlateBlock): boolean => {
      if (refBlock.areaBlock.w > plateBlock.areaBlock.w || refBlock.areaBlock.h > plateBlock.areaBlock.h) return false;

      const ax1 = plateBlock.areaBlock.x, ay1 = plateBlock.areaBlock.y, ax2 = ax1 + plateBlock.areaBlock.w, ay2 = ay1 + plateBlock.areaBlock.h;
      for (const area of (reservedAreas.get(tabIndex) ?? [])) {
        const bx1 = area.x, by1 = area.y, bx2 = bx1 + area.w, by2 = by1 + area.h;
        if (Math.max(0, Math.min(ax2, bx2) - Math.max(ax1, bx1)) * Math.max(0, Math.min(ay2, by2) - Math.max(ay1, by1)) > 0) return false;
      }

      return true;
    };

    const performStamping = (tabIndex: number, stampBlocks: ParsingPlateBlock[]): void => {
      if (stampBlocks.length == 0) return;

      let topY = 0;
      let idx = 0;
      while (true) {
        const initIdx = idx;
        const refBlocks = [stampBlocks[idx]];
        while (true) {
          idx = (idx + 1) % stampBlocks.length;
          if (idx == initIdx) break;
          if (!areasEqual(refBlocks[0].areaColumns, stampBlocks[idx].areaColumns) || !areasEqual(refBlocks[0].areaRows, stampBlocks[idx].areaRows)) break;
          refBlocks.push(stampBlocks[idx]);
        }

        const outerPlateBlock = this.findNextPlateBlock(tabIndex, sheets[tabIndex], topY, refBlocks[0].fieldName);
        if (!outerPlateBlock) break;

        topY = outerPlateBlock.areaBlock.y + outerPlateBlock.areaBlock.h;

        if (!refBlocks.every((refBlock) => considerPlateBlock(tabIndex, refBlock, outerPlateBlock))) continue;
        const offsetX = outerPlateBlock.areaColumns.x - refBlocks[0].areaColumns.x;
        const offsetY = outerPlateBlock.areaColumns.y - refBlocks[0].areaColumns.y;

        for (const refBlock of refBlocks) {
          const plateBlock = deepClone(outerPlateBlock);
          plateBlock.areaBlock = { x: refBlock.areaBlock.x + offsetX, y: refBlock.areaBlock.y + offsetY, w: refBlock.areaBlock.w, h: refBlock.areaBlock.h };
          plateBlock.fieldName = refBlock.fieldName;
          plateBlock.joinField = refBlock.joinField;
          plateBlock.fieldName = refBlock.fieldName;
          for (let n = 0; n < refBlock.fixedValues?.length; n++) {
            const fv = deepClone(refBlock.fixedValues[n]);
            if (fv.areaValue) {
              fv.areaValue.x += offsetX;
              fv.areaValue.y += offsetY;
            }
            plateBlock.fixedValues = [...(plateBlock.fixedValues ?? []), fv];
          }
          plateBlock.wellNumberPadding = refBlock.wellNumberPadding;
          plateBlock.multiStamp = null;

          impliedBlocks.push(plateBlock);
          reservedAreas.set(tabIndex, [...(reservedAreas.get(tabIndex) ?? []), plateBlock.areaBlock]);
        }
      }
    };

    for (const tabIndex of sourceTabs) {
      const tabBlocks = sourceBlocks.filter((plate) => plate.tabIndex == tabIndex);
      performStamping(tabIndex, tabBlocks);

      const globalBlocks = sourceBlocks.filter((plate) => plate.tabIndex == tabIndex && plate.multiStamp == ParsingPlateBlockMultiStamp.Global);
      for (let n = tabIndex + 1; n < sheets.length; n++) {
        performStamping(n, globalBlocks);
      }
    }

    return impliedBlocks;
  }
}
