/* eslint-disable @typescript-eslint/no-unused-vars */

import { AccountLayoutHelper } from '@/Accounts/accountLayoutHelper';
import { AccountsUtils } from '@/Accounts/accountsUtils';
import {
  ProjectSchema,
  UserSchema,
  UserWithPermissionsSchema,
  vaultRoleOptions,
} from '@/Accounts/types';
import { CDDModalForm } from '@/shared/components/CDDForm/CDDForm';
import { layoutBuilder } from '@/shared/components/DDForm/layoutBuilder';
import { RowColumnDef } from '@/shared/components/DDForm/types/rowColumnDef';
import { deleteButtonLayout } from '@/shared/components/CDDForm/cddElements';
import { AnyObject } from '@/types';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { action, computed, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { EditTeamsStore } from '../stores/editTeamStore';
import './EditTeamDialog.sass';

type Props = {
  store: EditTeamsStore;
};

const {
  page,
  stepper,
  row,
  column,
  textInput,
  select,
  typography,
  button,
} = layoutBuilder;

type UserWithPermissionsSchemaWithLabel = UserWithPermissionsSchema & {
  label: string;
};
@observer
export class EditTeamDialog extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    makeObservable(this, {
      layout: computed,
      handleOK: action,
      originalName: computed,
    });
  }

  get originalName() {
    return this.props.store.valueBeforeEdits?.name ?? '';
  }

  get formState() {
    return this.props.store.formState;
  }

  handleClose = (event: AnyObject, reason: 'backdropClick' | 'escapeKeyDown') => {
    if (reason === 'escapeKeyDown') {
      this.props.store.handleCancelEdit();
    }
  };

  handleOK = () => {
    this.props.store.handleSubmit();
  };

  private get layoutDetailsPage() {
    const {
      props: {
        store: {
          value,
          prohibitedNames,
        },
      },
    } = this;

    return page({ label: 'Team Details' }, [
      row([
        textInput({
          key: 'name',
          label: 'Name:',
          required: true,
          autoFocus: true,
          error: prohibitedNames.includes(value.name)
            ? 'This name is already in use'
            : null,
        }),
      ]),
    ]);
  }

  // just the tab with the team's name, for now
  private get layoutProjectMembershipPage() {
    const {
      props: {
        store: {
          usersMap,
          vaultsMap,
          availableProjectsToAdd,
          projectsInTeam,
          value,
          value: { user_membership, add_users_to_vaults },
          handleAddProject,
          handleRemoveProject,
        },
      },
    } = this;

    const rows: Array<RowColumnDef> = [];

    if (availableProjectsToAdd.length > 0) {
      rows.push(row({},
        [
          select({
            className: 'add-option-select',
            typeahead: true,
            visible: availableProjectsToAdd.length > 0,
            key: '.addOption',
            placeholder: 'Add project',
            autoFocus: true,
            selectOptions: availableProjectsToAdd,
            optionFieldId: 'id',
            optionFieldLabel: 'label',
            /**
             * Before we set the value into the object from the multiple select, translate it from an array of selected ids
             * into an object mapping the team ids to the role
             */
            translateSetValue: (value: ProjectSchema) => {
              if (value?.id) {
                handleAddProject(value);
              }
              return null;
            },
          }),
        ]));
    }

    projectsInTeam.forEach(project => {
      rows.push(AccountLayoutHelper.projectRow(project.label, project,
        () => {
          handleRemoveProject(project);
        }),
      );
    });

    if (!rows.length) {
      rows.push(row([typography({ label: 'No available projects' })]));
    }

    return page({ label: 'Projects' }, rows);
  }

  private get layoutMembersPage() {
    const {
      props: {
        store: {
          usersMap,
          availableUsersToAdd,
          value,
          value: { user_membership },
          handleAddUser,
          handleRemoveUser,
        },
      },
    } = this;

    const currentUsers: Array<UserWithPermissionsSchemaWithLabel> = Object.keys(
      user_membership,
    )
      .filter(userId => usersMap[userId])
      .map(userId => {
        const user = usersMap[userId];
        return { ...user, label: AccountsUtils.formatUserName(user) };
      });

    const rows: Array<RowColumnDef> = [];

    if (availableUsersToAdd.length > 0) {
      rows.push(row({},
        [
          select({
            className: 'add-option-select',
            typeahead: true,
            visible: availableUsersToAdd.length > 0,
            key: '.addOption',
            placeholder: 'Add user',
            autoFocus: true,
            selectOptions: availableUsersToAdd,
            optionFieldId: 'id',
            optionFieldLabel: 'label',
            /**
             * Before we set the value into the object from the multiple select, translate it from an array of selected ids
             * into an object mapping the team ids to the role
             */
            translateSetValue: (value: UserSchema) => {
              if (value?.id) {
                handleAddUser(value);
              }
              return null;
            },
          }),
        ]));
    }

    currentUsers.forEach(user => {
      rows.push(AccountLayoutHelper.userPermissionForTeamRow(user, value,
        () => {
          handleRemoveUser(user);
        }),
      );
    });

    if (!rows.length) {
      rows.push(row([typography({ label: 'No available users' })]));
    }

    return page({ label: 'Members' }, rows);
  }

  /**
   * Optional page shown when the team contains users without roles in projects' vaults.
   */
  get layoutVaultMembership() {
    const {
      props: {
        store: {
          vaultsWithUsersLackingAccess,
        },
      },
    } = this;

    const rows: Array<RowColumnDef> = [
      row([
        typography({
          label:
            'Please assign appropriate vault roles for the following users so they can access all of the team\'s projects.',
        }),
      ]),
    ];

    vaultsWithUsersLackingAccess.forEach(({ vault, users }) => {
      rows.push(column({ legend: vault.name },
        [
          ...users.map(user =>
            row([
              typography({
                label: AccountsUtils.formatUserName(user),
                className: 'permission-label',
                width: 'expand',
              }),
              select({
                key: `add_users_to_vaults.${user.id}.${vault.id}`,
                id: 'add_users_to_vaults',
                selectOptions: vaultRoleOptions,
                placeholder: '* Select role',
                required: true,
              }),
            ])),
        ]));
    });
    return page({ label: 'Vault Membership' }, rows);
  }

  get steps() {
    const result = [
      this.layoutDetailsPage,
      this.layoutProjectMembershipPage,
      this.layoutMembersPage,
    ];

    const {
      props: {
        store: {
          value: { add_users_to_vaults },
        },
      },
    } = this;
    if (Object.keys(add_users_to_vaults).length) {
      result.push(this.layoutVaultMembership);
    }
    return result;
  }

  get layout() {
    return stepper(
      {
        key: '.step',
        className: 'mainStepper',
      },
      this.steps,
    );
  }

  get layoutDelete() {
    return [
      deleteButtonLayout({
        visible: this.isEditingExisting,
        onClickButton: this.props.store.handleDelete,
      }),
    ];
  }

  get isCreatingNew() {
    return !this.value?.id;
  }

  get value() {
    return this.props.store.value;
  }

  get isEditingExisting() {
    return !this.isCreatingNew;
  }

  get dialogTitle() {
    return this.isEditingExisting
      ? `Edit Team: ${this.originalName}`
      : 'Create Team';
  }

  get renderValueIfPresent() {
    const {
      store: {
        value,
        handleCancelEdit,
        handleSubmit,
      },
    } = this.props;

    if (this.value) {
      return (
        <>
          <DialogTitle className='muiDialog-title'>
            {this.dialogTitle}
          </DialogTitle>
          <DialogContent>
            <CDDModalForm
              data={value ?? {}}
              formState={this.formState}
              layout={this.layout}
              showNextBackStepper={this.isCreatingNew}
              onOK={handleSubmit}
              onCancel={handleCancelEdit}
              bottomLeftElements={this.layoutDelete}
              terminology={{ OK: 'Save' }}
            />
          </DialogContent>
        </>
      );
    }
  }

  render() {
    return (
      <>
        <Dialog
          open={this.props.store.isOpen}
          onClose={this.handleClose}
          className='EditTeamDialog edit-account-object-dialog'
          PaperProps={{ className: 'main-dialog-paper' }}
        >
          {this.renderValueIfPresent}
        </Dialog>
      </>
    );
  }
}
