import React, { ChangeEvent } from 'react';
import { FormControl, Grid, TextField } from '@mui/material';
import { SaltPicker } from '../Salt/SaltPicker';
import { SolventPicker } from '../SolventOfCrystallization/SolventPicker';
import { StringOrNumber } from '@/types';
import { CondensedTheme } from '@/shared/components/MuiTheme';

const NoSaltFreeBaseOrAcid = 'No Salt, free base or acid';
const NoSolvent = 'No Solvent';

interface QuantityEditorProps {
  disabled: boolean;
  id: string;
  name: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  value: string;
  objectName?: string;
}

const QuantityEditor: React.FC<QuantityEditorProps> = (props) => {
  const inputProps = {
    step: 1,
    min: 0,
  };

  return (
    <>
      <TextField
        disabled={props.disabled}
        fullWidth
        id='specified_batch_salt_ratio'
        inputProps={inputProps}
        autoComplete="off"
        {...props}
      />
    </>
  );
};

interface SaltAndSolventEditorProps {
  solventOptions: Array<{ label: string; value: string }>;
  defaultSaltData: {
    salt_count: number;
    solvent_of_crystallization_count: number;
    salt_name?: string;
    solvent_of_crystallization_id?: string;
    solvent_of_crystallization_name?: string;
  };
  objectName: string;
  disabled?: boolean;
}

interface SaltAndSolventEditorState {
  saltCount: number;
  saltValue: { label: string; value: string };
  solventOfCrystallizationCount: number;
  solventValue: { label: string; value: string } | null;
}

class SaltAndSolventEditor extends React.Component<SaltAndSolventEditorProps, SaltAndSolventEditorState> {
  static noTheme = true;

  constructor(props: SaltAndSolventEditorProps) {
    super(props);
    const { salt_count, solvent_of_crystallization_count, salt_name, solvent_of_crystallization_id, solvent_of_crystallization_name } = this.props.defaultSaltData;

    const solventValue = solvent_of_crystallization_id
      ? { label: solvent_of_crystallization_name || '', value: solvent_of_crystallization_id }
      : null;

    const saltValue = {
      label: salt_name || NoSaltFreeBaseOrAcid,
      value: salt_name || NoSaltFreeBaseOrAcid,
    };

    this.state = {
      saltCount: salt_count || 0,
      saltValue,
      solventOfCrystallizationCount: solvent_of_crystallization_count || 0,
      solventValue,
    };
  }

  handleChange = (key: keyof SaltAndSolventEditorState) => (value: any) => {
    this.setState({ [key]: value } as Pick<SaltAndSolventEditorState, keyof SaltAndSolventEditorState>);
  };

  handleChangeEvent = (key: keyof SaltAndSolventEditorState) => (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    this.setState({ [key]: value } as unknown as Pick<SaltAndSolventEditorState, keyof SaltAndSolventEditorState>);
  };

  noneOrOneOrCurrent = (currentQuantity: StringOrNumber, selectionRepresentsNone: boolean) => {
    if (currentQuantity == 0 && !selectionRepresentsNone) {
      return 1;
    } else if (currentQuantity != 0 && selectionRepresentsNone) {
      return 0;
    }
    return currentQuantity as number;
  };

  setSalt = (option: { label: string; value: string } | null) => {
    const value = option ? option.value : NoSaltFreeBaseOrAcid;
    const saltCount = this.noneOrOneOrCurrent(this.state.saltCount, this.isNoSalt(value));
    this.setState({
      saltCount,
      saltValue: option || { label: NoSaltFreeBaseOrAcid, value: NoSaltFreeBaseOrAcid },
    });
  };

  setSolvent = (option: { label: string; value: string } | null) => {
    const value = option ? option.value : NoSolvent;
    const solventOfCrystallizationCount = this.noneOrOneOrCurrent(
      this.state.solventOfCrystallizationCount,
      this.isNoSolvent(value),
    );
    this.setState({
      solventOfCrystallizationCount,
      solventValue: option,
    });
  };

  isNoSalt = (value: string): boolean => {
    return value === null || value === undefined || value === NoSaltFreeBaseOrAcid;
  };

  isNoSolvent = (value: string): boolean => {
    return value === null || value === undefined || value === '' || value === NoSolvent;
  };

  handleChangeSaltCount = (event: ChangeEvent<HTMLInputElement>) => {
    this.handleChangeEvent('saltCount')(event);
  };

  handleChangeSolventOfCrystallizationCount = (event: ChangeEvent<HTMLInputElement>) => {
    this.handleChangeEvent('solventOfCrystallizationCount')(event);
  };

  render() {
    return (
      <CondensedTheme>
        <FormControl component="fieldset" fullWidth>
          <Grid container className='SaltAndSolventEditor'
              alignItems="flex-end" spacing={1}>
            <Grid item xs={2}>
              <QuantityEditor
                disabled={this.props.disabled}
                id="specified_batch_salt_ratio"
                name={`${this.props.objectName}[salt_ratio]`}
                onChange={this.handleChangeSaltCount}
                value={String(this.state.saltCount)}
              />
            </Grid>
            <Grid item xs={10}>
              <SaltPicker
                disabled={this.props.disabled}
                name={`${this.props.objectName}[salt_name]`}
                setValue={this.setSalt}
                value={this.state.saltValue}
                NoSaltFreeBaseOrAcid={NoSaltFreeBaseOrAcid}
              />
            </Grid>

            <Grid item xs={2}>
              <QuantityEditor
                disabled={this.props.disabled}
                id="specified_batch_solvent_of_crystallization_ratio"
                name={`${this.props.objectName}[solvent_of_crystallization_ratio]`}
                onChange={this.handleChangeSolventOfCrystallizationCount}
                value={String(this.state.solventOfCrystallizationCount)}
              />
            </Grid>
            <Grid item xs={10}>
              <SolventPicker
                disabled={this.props.disabled}
                name={`${this.props.objectName}[solvent_of_crystallization_id]`}
                options={this.props.solventOptions}
                setValue={this.setSolvent}
                value={this.state.solventValue}
              />
            </Grid>
          </Grid>
        </FormControl>
      </CondensedTheme>
    );
  }
}

export default SaltAndSolventEditor;
