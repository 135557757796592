import { observer } from 'mobx-react';
import React from 'react';

import { LocationNode } from '@/Samples/types';
import { getRootStore } from '@/stores/rootStore';
import { Checkbox, FormControlLabel, Grid, TextField, Button } from '@mui/material';
import { runInAction } from 'mobx';
import { LocationUtils } from '../location/locationUtils';
import GridOnIcon from '@mui/icons-material/GridOn';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

export const MaxBoxDimension = 20;

type Props = {
  selectedTreeNode: LocationNode;
  onCreateBox: (node: LocationNode, organized: boolean) => void;
};

type State = {
  isNew?: boolean;
  isBox?: boolean;
  errorMessage?: string;
  rename?: string;
  originalName?: string;
  destination?: LocationNode;
};

@observer
export class LocationEditorPanel extends React.Component<Props, State> {
  get store() {
    return getRootStore().locationStore;
  }

  constructor(props) {
    super(props);
    this.state = {
      errorMessage: null,
      rename: null,
    };
  }

  clampRowColumns(node: Partial<LocationNode>) {
    node = { ...node };
    if (node.num_columns !== undefined) {
      node.num_columns = Math.max(1, Math.min(MaxBoxDimension, node.num_columns || 1));
    }
    if (node.num_rows !== undefined) {
      node.num_rows = Math.max(1, Math.min(MaxBoxDimension, node.num_rows || 1));
    }
    if (node.num_columns && node.num_rows) {
      node.position_limit = node.num_columns * node.num_rows;
      node.filled_position_count = node.filled_position_count ?? 0;
    }
    return node;
  }

  setSelectedNodeValue(node: Partial<LocationNode>) {
    runInAction(() => Object.assign(this.props.selectedTreeNode, this.clampRowColumns(node)));
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    if (this.props.selectedTreeNode !== prevProps.selectedTreeNode) {
      this.setState({
        isBox: this.props.selectedTreeNode?.position_limit > 0,
        isNew: !this.props.selectedTreeNode?.value,
        rename: this.props.selectedTreeNode?.value,
        originalName: this.props.selectedTreeNode?.value,
      });

      setTimeout(() => {
        const input = document.getElementById('location-box-node-name') as HTMLInputElement;
        if (input) {
          input.focus();
          input.select();
        }
      });
    }
  }

  render() {
    const { selectedTreeNode } = this.props;
    const { errorMessage } = this.state;

    const { addBox: canAddBox = false } = selectedTreeNode ? LocationUtils.availableFeaturesAtLevel(selectedTreeNode) : {};

    if (!selectedTreeNode?.parent) {
      return <>
        <h2>Edit Locations</h2>
        <section style={{ maxWidth: 500 }}>
          <p>
            Use the panel on the left to set up locations and boxes for inventory samples.
          </p>
          <p>
            To create, duplicate or delete locations and boxes, use the icons on each row. Drag locations and boxes to change the order.
          </p>
        </section>
      </>;
    }

    const handleRename = (e: React.ChangeEvent<HTMLInputElement>) => {
      const newName = e.currentTarget.value;
      const legality = LocationUtils.isNameLegal(newName, selectedTreeNode);
      if (legality !== true) {
        this.setSelectedNodeValue({ value: this.state.originalName });
      }
      this.setState({ rename: newName });
      if (typeof legality === 'string') {
        this.setState({
          errorMessage: legality,
        });
        return;
      }
      if (legality === true) {
        this.setSelectedNodeValue({ value: newName });
        this.setState({
          errorMessage: null,
        });
      }
    };

    const handleCheckOrganized = () => {
      this.setSelectedNodeValue({ organized: !selectedTreeNode.organized });
    };

    const disabledDimensions = selectedTreeNode.filled_position_count > 0;
    const nodeIsBox = selectedTreeNode.position_limit > 0 || selectedTreeNode.capacity > 0;
    return <div className='mode-editor'>
      <TextField id='location-box-node-name' className='node-name' label="Name" value={this.state.rename ?? ''}
        onChange={handleRename}
        error={!!errorMessage}
        helperText={errorMessage}
        onFocus={event => event.target.select()}
      />

      {nodeIsBox
        ? <>
          {/* Node is a box */}
          {selectedTreeNode.organized
            ? <>
              <TextField type='number' inputProps={{ min: 1, max: MaxBoxDimension }} className='row-col-input'
                label="# columns"
                aria-label="# columns"
                value={selectedTreeNode.num_columns ?? ''}
                disabled={disabledDimensions} title={disabledDimensions ? 'Only dimensions of empty boxes can be modified.' : null}
                onChange={e => this.setSelectedNodeValue({ num_columns: parseInt(e.currentTarget.value) })}
                onFocus={event => event.target.select()}
              />

              <TextField type='number' inputProps={{ min: 1, max: MaxBoxDimension }} className='row-col-input'
                label="# rows"
                aria-label="# rows"
                value={selectedTreeNode.num_rows ?? ''}
                disabled={disabledDimensions} title={disabledDimensions ? 'Only dimensions of empty boxes can be modified.' : null}
                onChange={e => this.setSelectedNodeValue({ num_rows: parseInt(e.currentTarget.value) })}
                onFocus={event => event.target.select()}
              />
            </>
            : <TextField type='number' inputProps={{ min: 1, max: 1000 }} className='row-col-input'
              label="Capacity"
              aria-label="Capacity"
              value={selectedTreeNode.capacity ?? 1}
              disabled={disabledDimensions} title={disabledDimensions ? 'Only dimensions of empty boxes can be modified.' : null}
              onChange={e => this.setSelectedNodeValue({ capacity: parseInt(e.currentTarget.value) })}
              onFocus={event => event.target.select()}
            />
          }
          <FormControlLabel
            style={{ marginLeft: '0.5rem' }}
            label="Organized"
            control={<Checkbox
              checked={selectedTreeNode.organized}
              onClick={handleCheckOrganized}
            />
            } />
        </>
        : <>
          {/* Node is a location */}
          {canAddBox && <>

            <Grid container spacing={2} direction="column" padding={5}>
              <Grid item xs={12} className="empty-location-button-container">
                <Button fullWidth size="large" variant="outlined"
                  onClick={() => this.props.onCreateBox(this.props.selectedTreeNode, true)}>
                  <div className='add-box-button-contents'>
                    <div>
                      <GridOnIcon />
                    </div>
                    <div>
                      Add an organized box at this location
                    </div>
                  </div>
                </Button>
              </Grid>
              <Grid item xs={12} className="empty-location-button-container">
                <Button fullWidth size="large" variant="outlined"
                  onClick={() => this.props.onCreateBox(this.props.selectedTreeNode, false)}>
                  <div className='add-box-button-contents'>
                    <div>
                      <CheckBoxOutlineBlankIcon />
                    </div>
                    <div>
                      Add an unorganized box at this location
                    </div>
                  </div>
                </Button>
              </Grid>
            </Grid>
          </>
          }
        </>}
    </div>;
  }
}
