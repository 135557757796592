import React from 'react';

import {
  EncodedFieldString,
  encodeFieldTypeInId,
  unencodeFieldTypeFromId,
} from '@/Inventory/components/Table/encodeUtils';
import {
  CustomOrDefaultResultColumnId,
  FieldType,
  InventorySearchViewAPIProps,
  ResultColumnsEditorProps,
  TInventorySearchFieldDefinitions,
} from '@/Inventory/components/types';
import { translateCaseInsensitive } from '@/shared/utils/stringUtils';
import { StringOrNumber } from '@/types';
import { FormControl, MenuItem, Select } from '@mui/material';

// TODO dedupe with eln entries stuff where possible
export type FilterFieldSelectProps = {
  handleFieldChange: (
    value: CustomOrDefaultResultColumnId,
    field_type: FieldType,
  ) => void;
  selectedFieldValues: StringOrNumber[];
  multiple: boolean;
  className: string;
  selectedValue: EncodedFieldString | '';
  vaultNameMap: InventorySearchViewAPIProps['vault_name_map'];
} & Pick<ResultColumnsEditorProps, 'availableFilters'>;
export class FilterFieldSelect extends React.Component<FilterFieldSelectProps> {
  handleChange = (event) => {
    const { field_type, id } = unencodeFieldTypeFromId(event.target.value);
    this.props.handleFieldChange(id, field_type);
  };

  renderItems = (
    fieldDefinitions: TInventorySearchFieldDefinitions[],
    lastItemForFieldType: boolean,
  ) => {
    const { selectedFieldValues } = this.props;
    return fieldDefinitions
      .filter((fd) => fd.selectValue != 'Structure')
      .map((fd, ii) => {
        const lastItemForGroup = ii == fieldDefinitions.length - 1;
        return (
          <MenuItem
            className='search-bar__filters__field-name'
            style={{
              paddingLeft: '3rem',
            }}
            key={`${fd.id}-${fd.name}-${ii}`}
            value={encodeFieldTypeInId({
              field_type: fd.field_type,
              id: fd.id,
            })}
            disabled={selectedFieldValues.includes(fd.id)}
            divider={lastItemForFieldType && lastItemForGroup}
          >
            {translateCaseInsensitive(fd.name, true)}
          </MenuItem>
        );
      });
  };

  renderCategorizedMenuItems = () => {
    const { availableFilters: inventoryFieldDefinitions, vaultNameMap } =
      this.props;

    return Object.keys(inventoryFieldDefinitions.byFieldThenVault).map(
      (fieldType) => {
        const fieldDefinitionsCategorizedByVault =
          inventoryFieldDefinitions.byFieldThenVault[fieldType];
        return [
          <MenuItem
            key={fieldType + '__divider_label'}
            disabled
            className='search-bar__filters__top-level-category'
          >
            {translateCaseInsensitive(fieldType, true)}
          </MenuItem>,
          ...Object.keys(fieldDefinitionsCategorizedByVault)
            .map((vaultId, ii) => {
              const isLastInVault =
                ii ==
                Object.keys(fieldDefinitionsCategorizedByVault).length - 1;
              return [
                <MenuItem
                  key={`${vaultId}-${ii}__divider_label`}
                  disabled
                  className='search-bar__filters__second-level-category'
                >
                  {vaultNameMap[vaultId] || 'Default'}
                </MenuItem>,
                ...this.renderItems(
                  fieldDefinitionsCategorizedByVault[vaultId],
                  isLastInVault,
                ),
              ];
            })
            .flat(),
        ];
      },
    );
  };

  render() {
    const { selectedValue: selectedValues, className, multiple } = this.props;
    return (
      <FormControl className='filter-field-select'>
        <Select
          variant='outlined'
          className={className}
          value={selectedValues}
          onChange={this.handleChange}
          multiple={multiple}
        >
          {this.renderCategorizedMenuItems()}
        </Select>
      </FormControl>
    );
  }
}
