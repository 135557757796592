import React from 'react';

import ExportLink from '@/shared/components/ExportLink';

// TODO dedupe with eln entries stuff where possible
export type EntriesExportLinksProps = {
  exportsDisabled: boolean;
  terminology: string;
  onClickExport: () => void;
  exportsAllowed: boolean;
};

export class EntriesExportLinks extends React.Component<EntriesExportLinksProps> {
  render() {
    const { exportsDisabled, terminology, onClickExport, exportsAllowed } =
      this.props;
    return (
      <ExportLink
        hidden={!exportsAllowed}
        className="search-bar__export_link"
        disabled={exportsDisabled}
        onClick={onClickExport}
      >
        Export {terminology}
      </ExportLink>
    );
  }
}
