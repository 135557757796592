import { AccountsUtils } from '@/Accounts/accountsUtils';
import {
  UserWithPermissionsSchema,
  vaultRoleOptions,
  WithLabel,
} from '@/Accounts/types';
import { CDDElements, deleteButtonLayout } from '@/shared/components/CDDForm/cddElements';
import { CDDModalForm } from '@/shared/components/CDDForm/CDDForm';
import { layoutBuilder } from '@/shared/components/DDForm/layoutBuilder';
import { ElementType } from '@/shared/components/DDForm/types';
import { RowColumnDef } from '@/shared/components/DDForm/types/rowColumnDef';
import { TreeItemDef } from '@/shared/components/DDForm/types/treeDef';
import { keysAsNumbers } from '@/shared/utils/objectKeys';
import { Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import { action, computed, makeObservable, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { EditProjectStore } from '../stores/editProjectStore';
import { UserSchema } from '../../types';
import { AccountLayoutHelper } from '@/Accounts/accountLayoutHelper';
import './EditProjectDialog.sass';
import { SelectOption } from '@/shared/components/DDForm/types/selectSingleDef';

const { formatUserName } = AccountsUtils;

const {
  page,
  stepper,
  row,
  textInput,
  select,
  typography,
  checkbox,
  button,
  tree,
  treeItem,
} = layoutBuilder;

type Props = {
  store: EditProjectStore
};

type UserWithPermissionsSchemaWithLabel = WithLabel<UserWithPermissionsSchema>;

@observer
export class EditProjectDialog extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    makeObservable(this, {
      layout: computed,
      handleOK: action,
      originalName: computed,
    });
  }

  get originalName() {
    return this.props.store.valueBeforeEdits?.name ?? '';
  }

  handleClose = (event: unknown, reason: 'backdropClick' | 'escapeKeyDown') => {
    if (reason === 'escapeKeyDown') {
      this.props.store.handleCancelEdit();
    }
  };

  handleOK = () => {
    this.props.store.handleSubmit();
  };

  get value() {
    return this.props.store.value;
  }

  private get layoutDetailsPage() {
    const {
      isReadonly,
      props: {
        store: {
          value,
          prohibitedNames,
        },
      },
    } = this;

    return page({ label: 'Project Details' }, [
      row([
        textInput({
          key: 'name',
          label: 'Name:',
          disabled: isReadonly,
          required: true,
          autoFocus: true,
          error: prohibitedNames.includes(value.name)
            ? 'This name is already in use'
            : null,
        }),
      ]),
      row([
        textInput({
          key: 'description',
          label: 'Description:',
          disabled: isReadonly,
        }),
      ]),
    ]);
  }

  private get layoutMembersPage() {
    const {
      isReadonly,
      props: {
        store,
        store: {
          value,
          usersMap,
          teamDetailsMap,
          userToRoleNameMap,
          availableOptionsToAdd,
          userReadOnlyVaultMap,
          canModifyTeams,
          value: { project_permissions, team_ids },
          handleRemoveMember,
        },
      },
    } = this;

    const currentUsers: Array<UserWithPermissionsSchemaWithLabel> =
      value.user_order
        .filter(userId => usersMap[userId])
        .map(userId => {
          const user = usersMap[userId];
          return { ...user, label: formatUserName(user) };
        });

    const rows: Array<RowColumnDef> = [];

    if (availableOptionsToAdd.length > 0 && !isReadonly) {
      rows.push(row([
        select({
          className: 'add-option-select',
          typeahead: true,
          autoFocus: true,

          key: '.addOption',
          placeholder: 'Add user or team',
          selectOptions: availableOptionsToAdd,
          optionFieldId: 'id',
          optionFieldLabel: 'label',
          /**
           * Before we set the value into the object from the multiple select, translate it from an array of selected ids
           * into an object mapping the project ids to the role
           */
          translateSetValue: (value: UserSchema) => {
            if (value) {
              store.handleAddMember(value);
            }
            return null;
          },

          controlAttributes: {
            getOptionDisabled: (option: SelectOption) => {
              return option.disabled;
            },

            renderOption: (props: object, option: SelectOption) => {
              return <li key={option.id} {...props}>
                <Typography>{option.label}</Typography>
                {option.disabled ? <Typography style={{ fontStyle: 'italic' }}>{option.disabledMessage}</Typography> : null}
              </li>;
            },
          },
        })]));
    }

    rows.push(
      // We don't display computed ones here
      ...currentUsers.filter(user => project_permissions[user.id].compiled_from_teams !== true)
        .map(user => {
          let readOnlyMode;
          if (this.isReadonly) {
            readOnlyMode = 'ViewOnly';
          } else {
            if (userReadOnlyVaultMap[user.id]) {
              readOnlyMode = 'ReadOnlyVault';
            }
          }
          const role = userToRoleNameMap[user.id];

          return AccountLayoutHelper.userPermissionForProjectRow(value,
            user,
            () => { handleRemoveMember(user); },
            readOnlyMode,
            AccountsUtils.isReadonlyVaultRole(role) ? role : undefined,
          );
        }),
    );

    if (Object.keys(team_ids).length > 0) {
      const treeItems: Array<TreeItemDef> = [];
      team_ids.forEach((teamId) => {
        const team = teamDetailsMap[teamId];
        const teamUserItems: Array<TreeItemDef> = [];

        team.team_memberships.forEach(membership => {
          const userId = membership.user_id;
          const user = usersMap[userId];
          if (!user) {
            return;
          }
          const label = AccountsUtils.formatUserName(user);
          const key = `.tree${team.id}.${userId}`;

          const role = userToRoleNameMap[user.id];
          const isReadonly = AccountsUtils.isReadonlyVaultRole(role);
          const readOnlyRole = isReadonly ? role : undefined;

          teamUserItems.push(
            treeItem({
              className: 'team-user-row',
              key,
              itemField:
                AccountLayoutHelper.permissionsRow(
                  label,
                  key,
                  membership,
                  null,
                  'ProjectMemberInTeam',
                  readOnlyRole,
                ),
            }),
          );
        });

        treeItems.push(
          treeItem(
            {
              key: `.tree${team.id}`,
              label: `👥 ${team.name}`,
              className: 'team-membership-row',
              itemField: row([
                typography({ label: `👥 ${team.name}`, width: 'expand' }),
                CDDElements.deleteIconButton({
                  key: `delete_${team.id}`,
                  visible: !isReadonly && canModifyTeams,
                  onClickButton: () => {
                    runInAction(() => {
                      handleRemoveMember(team);
                    });
                  },
                }),
              ]),
            },
            teamUserItems,
          ),
        );
      });

      const treeElem = tree({ key: '.tree' }, treeItems) as ElementType;
      const rowTree = row({ className: 'no-padding' }, [treeElem]);
      rows.push(rowTree);
    }

    if (!rows.length) {
      rows.push(row([typography({ label: 'No available members' })]));
    }

    return page({ label: 'Members' }, rows);
  }

  get layoutVaultMembership() {
    const {
      isReadonly,
      props: {
        store: {
          usersMap,
          value: {
            add_users_to_vault,
          },
        },
      },
    } = this;

    const rows: Array<RowColumnDef> = [
      row([
        typography({
          label:
            'The following users have been added to this project, but are not in the vault. Please set their vault roles:',
        }),
      ]),
    ];

    rows.push(
      ...keysAsNumbers(add_users_to_vault).map((userId) =>
        row([
          typography({
            label: AccountsUtils.formatUserName(usersMap[userId]),
            className: 'permission-label',
            width: 'expand',
          }),

          select({
            key: `add_users_to_vault.${userId}.role_name`,
            selectOptions: vaultRoleOptions,
            placeholder: 'Select role',
            required: true,
            autoFocus: true,
            visible: !isReadonly,
          }),
        ]),
      ),
    );

    return page({ label: 'Vault Membership' }, rows);
  }

  get steps() {
    const result = [this.layoutDetailsPage, this.layoutMembersPage];
    if (Object.keys(this.value.add_users_to_vault).length) {
      result.push(this.layoutVaultMembership);
    }
    return result;
  }

  get layout() {
    return stepper(
      {
        key: '.step',
        className: 'mainStepper',
      },
      this.steps,
    );
  }

  get buttonDelete() {
    if (this.isCreatingNew || this.isReadonly) {
      return [];
    }
    const {
      props: {
        store: {
          handleDelete,
          value: { deletable, not_deletable_message },
        },
      },
    } = this;

    return [
      deleteButtonLayout({
        visible: this.isEditingExisting,
        disabled: !deletable,
        onClickButton: handleDelete,
        tooltip: deletable ? undefined : not_deletable_message,
        tooltipProps: deletable ? undefined : { placement: 'top-start' },
      }),
    ];
  }

  get isCreatingNew() {
    return !this.value?.id;
  }

  get isEditingExisting() {
    return !this.isCreatingNew;
  }

  get isReadonly() {
    return this.isEditingExisting && !this.value?.editable;
  }

  get dialogTitle() {
    if (this.isEditingExisting) {
      if (this.isReadonly) {
        return `View Project: ${this.originalName}`;
      } else {
        return `Edit Project: ${this.originalName}`;
      }
    } else {
      return 'Create project';
    }
  }

  render() {
    const { isOpen, formState, value, handleCancelEdit } = this.props.store;

    return (
      <>
        <Dialog
          open={isOpen}
          onClose={this.handleClose}
          className='EditProjectDialog edit-account-object-dialog'
          PaperProps={{ className: 'main-dialog-paper' }}
        >
          {value && (
            <>
              <DialogTitle className='muiDialog-title'>
                {this.dialogTitle}
              </DialogTitle>
              <DialogContent>
                <CDDModalForm
                  data={value ?? {}}
                  formState={formState}
                  layout={this.layout}
                  showNextBackStepper={this.isCreatingNew}
                  onOK={this.handleOK}
                  onCancel={this.isReadonly ? null : handleCancelEdit}
                  bottomLeftElements={this.buttonDelete}
                  terminology={{ OK: this.isReadonly ? 'OK' : 'Save' }}
                />
              </DialogContent>
            </>
          )}
        </Dialog>
      </>
    );
  }
}
