import { ExpandMoreButton } from '@/components/Buttons/ExpandMoreButton';
import { Box, Collapse, Stack } from '@mui/material';
import React, { ReactNode } from 'react';

const ExpandableGroup = (props: {
  groupNode: ReactNode;
  group: ReactNode[];
  groupCaption?: string;
}) => {
  const { groupNode, group, groupCaption } = props;
  const [expanded, setExpanded] = React.useState(true);
  const caption =
    groupCaption ||
    `${group.length} ${group.length == 1 ? 'structure' : 'structures'}`;
  return (
    <Stack direction='row' spacing={2} alignItems='start'>
      {groupNode}
      <ExpandMoreButton
        expanded={expanded}
        onClick={() => setExpanded(!expanded)}
      />
      <Stack direction='column'>
        <Box style={{ fontWeight: 'bold' }}>{caption}</Box>
        <Collapse in={expanded} timeout='auto' unmountOnExit>
          <Box
            style={{ display: 'flex' }}
            sx={{ flexWrap: 'wrap' }}
            aria-label='scaffold-group'
          >
            {group}
          </Box>
        </Collapse>
      </Stack>
    </Stack>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CenterContent = (content: any) => {
  return (
    <Box display='flex' height='100%'>
      <Box m='auto' paddingLeft='200px' paddingRight='200px'>
        {content}
      </Box>
    </Box>
  );
};

const Overlay = (props: { children: ReactNode }) => {
  return (
    <Box
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
      }}
    >
      {props.children}
    </Box>
  );
};

export { CenterContent, ExpandableGroup, Overlay };
