import { InventoryFieldUniqueKey } from '@/Protocols/types';
import { StringOrNumber } from '@/types';
import { FieldTypeValues } from '../consts';
import { FieldType } from '../types';

const DIVIDER = '::' as const;

export type EncodedFieldString =
  `${FieldType}${typeof DIVIDER}${StringOrNumber}`;

const fieldTypeGuard = (
  potentialFieldType: string,
): potentialFieldType is FieldType => {
  return FieldTypeValues.includes(potentialFieldType as any);
};

export const encodeFieldTypeInId = ({
  field_type: fieldType,
  id,
}: InventoryFieldUniqueKey): EncodedFieldString =>
  `${fieldType}${DIVIDER}${id}`;

export const unencodeFieldTypeFromId = (
  encoded: EncodedFieldString,
): InventoryFieldUniqueKey => {
  const [fieldType, rawId] = encoded.split(DIVIDER);
  if (!fieldTypeGuard(fieldType)) {
    throw new Error('Invalid Field Type!');
  }
  const id = Number.isNaN(Number.parseInt(rawId))
    ? rawId
    : Number.parseInt(rawId);
  return { id: id as InventoryFieldUniqueKey['id'], field_type: fieldType };
};
