import React, { useState } from 'react';
import { Link } from '@mui/material';
import { HazardClassIcons } from './components/HazardClassIcons';
import { SafetySummaryDialog } from './components/SafetySummary';
import { get_safety_data } from './data';

export const HazardCodeIconsView = (props: {
  hazard_codes: string[] | string;
  pubchem_id?: number;
  ban_external_links: boolean;
  skip_external_link_warning: boolean;
  size?: number;
}) => {
  const { size = 50 } = props;
  const [open, setOpen] = useState(false);

  const onClick = (e: React.MouseEvent) => {
    setOpen(true);
    e.preventDefault();
  };
  const onClose = () => {
    setOpen(false);
  };

  const safetyData = get_safety_data(props.hazard_codes);
  return (
    <Link href={'#'} onClick={onClick} style={{ textDecoration: 'none' }}>
      <HazardClassIcons safetyData={safetyData} size={size} display_assume_toxic={false} />
      {open &&
        <SafetySummaryDialog
          hazard_codes={props.hazard_codes}
          pubchem_id={props.pubchem_id}
          open={open}
          onClose={onClose}
          banExternalLinks={props.ban_external_links}
          skipExternalLinkWarning={props.skip_external_link_warning} />
      }
    </Link>
  );
};
