import React from 'react';
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';

import { EditFormDefinitionStore } from '@/FormDefinitions/stores/editFormDefinitionStore';
import { SidebarField } from './SidebarField';
import { observer } from 'mobx-react';
import QuestionIcon from '@/shared/components/icons/QuestionIcon';
import { term } from '@/shared/utils/stringUtils';
import Tooltip from '@mui/material/Tooltip';
import { TestableTextFieldSelect } from '@/shared/components/TestableMuiComponents/TestableSelect';

type SideBarProps = {
  store: EditFormDefinitionStore;
}

@observer
export default class Sidebar extends React.Component<SideBarProps> {
  handleSelectTemplate = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { store, store: { templates } } = this.props;

    for (const template of templates) {
      if (template.root.name === event.target.value) {
        store.setSelectedTemplate(template);
        return;
      }
    }
    store.setSelectedTemplate(null);
  };

  render() {
    const {
      store,
      store: {
        currentFormName,
        labelSidebarItem,
        sidebarDefinitionFields,
        availableTemplates,
        selectedTemplate,
        sidebarTemplateFieldsInGroups,
        currentTemplateSchemaPrefix,
      },
    } = this.props;

    const definitionLabelText = {
      'run_form': `${term('run', true)} Fields`,
      'protocol_form': `${term('protocol', true)} Fields`,
    }[currentFormName];

    return (
      <div className="Sidebar">
        <SidebarField key={labelSidebarItem.layoutID} field={labelSidebarItem} store={store} />

        {sidebarDefinitionFields.length > 0 && <>
          <label className="label">{definitionLabelText}&nbsp;
            <Tooltip arrow={true} title={`Go the the ${definitionLabelText} page (under Settings > Vault) to modify the list of available fields.`}>
              <span><QuestionIcon width="14" height="14" color="#3399FF"/></span>
            </Tooltip>
          </label>
          {sidebarDefinitionFields.map((definition) => (
            <SidebarField key={definition.layoutID} field={definition} store={store} />
          ))}
        </>}

        {availableTemplates.length > 0 && <>
          {currentTemplateSchemaPrefix
            ? null
            : <TestableTextFieldSelect
            className='template-select'
            value={selectedTemplate?.root.name ?? ' '}
            label="Ontology Template"
            select
            onChange={this.handleSelectTemplate}
          >
            <MenuItem value=' '> None </MenuItem>

            {availableTemplates.map(template =>
              <MenuItem key={template.root.name} value={template.root.name} >
                {template.root.name}
              </MenuItem>)}
          </TestableTextFieldSelect>}

          {currentTemplateSchemaPrefix ? <label className='label'>{availableTemplates[0].root.name}</label> : null}

          {sidebarTemplateFieldsInGroups.map(f => (
            <SidebarField key={f.layoutID} field={f} isTemplateField={true} store={store}/>))}
        </>}
      </div>
    );
  }
}
