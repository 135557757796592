import React from 'react';

import {
  Autocomplete,
  TextField,
} from '@mui/material';
import { extractLabelAndValue, LabelAndValue } from '../Salt/SaltPicker';

interface Props {
  setValue?: (option: LabelAndValue) => void;
  value?: LabelAndValue;
  defaultValue?: LabelAndValue;
  disabled?: boolean;
  name?: string;
  NoSaltFreeBaseOrAcid?: string;
  options: LabelAndValue[];
}

export class SolventPicker extends React.Component<Props> {
  handleChangeOption = (_e, option) => {
    if (this.props.setValue != undefined) {
      this.props.setValue(option);
    }
  };

  render() {
    const { options, disabled, name } = this.props;
    const value = this.props.value ?? null;

    return (
      <>
        <Autocomplete
          className={this.props.disabled ? 'Autocomplete-disabled' : ''}
          value={value}
          disabled={disabled}
          onChange={this.handleChangeOption}
          fullWidth
          options={options}
          isOptionEqualToValue={(option, value) => {
            return extractLabelAndValue(option).value == extractLabelAndValue(value).value;
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="No Solvent"
              variant="outlined"
              fullWidth
              InputProps={{
                ...params.InputProps,
                inputProps: {
                  ...params.inputProps,
                  'aria-label': 'Solvent',
                },
              }}
            />
          )}
        />
        <input name={name} type="hidden" disabled={disabled} value={extractLabelAndValue(value).value} />
        <span className="solvent-name">{extractLabelAndValue(value).label}</span>
      </>
    );
  }
}
