import React from 'react';
import {
  Box,
  SxProps,
} from '@mui/material';
import { MoleculeProperties, molecule_properties } from './calculator';

type PropertyListViewProps = {
  properties: object;
  displayProperties: string[];
  reference?: MoleculeProperties
  separator?: string;
};

const HorizontalPropertyListView = (props: PropertyListViewProps) => {
  const { properties, separator, displayProperties } = props;

  const showProperties = molecule_properties
    .filter(p => displayProperties.includes(p.key))
    .filter(p => properties[p.key]);
  if (showProperties.length === 0) {
    return null;
  }

  const items = [];
  showProperties.forEach((property, idx) => {
    if (!properties[property.key]) {
      return;
    }
    const value = properties[property.key].toFixed(property.precision);
    let item = `${property.short}: ${value}`;
    if (idx < showProperties.length - 1) {
      item += `${separator || ', '} `;
    }
    const style: SxProps = { whiteSpace: 'nowrap' };
    if (props.reference && props.reference[property.key] != undefined) {
      const referenceValue = props.reference[property.key].toFixed(property.precision);
      if (referenceValue < value) {
        style.color = '#DC143C';
      } else if (referenceValue > value) {
        style.color = '#133bdc';
      } else {
        style.color = 'black';
      }
    }
    items.push(<Box key={idx} component="span"><Box component="span" sx={style}>{item}</Box>{' '}</Box>);
  });
  return (
    <Box sx={{ textWrap: 'pretty' }} data-testid='propertylist'>
      {items}
    </Box>
  );
};

export { PropertyListViewProps, HorizontalPropertyListView };
