/* eslint-disable multiline-ternary, no-nested-ternary */

import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Typography,
} from '@mui/material';
import CancelOrSubmit from '@/shared/components/CancelOrSubmit.jsx';
import React from 'react';
import { OntologyTemplate, TemplateGroup, TemplateAssignment, TemplateValue, SuggestionType, SpecificationType } from '../data/templates';
import { Schema, SchemaBranch } from '../data/Schema';
import './Template.sass';
import { TemplateServices } from '../data/TemplateServices';
import Tooltip from '@mui/material/Tooltip';
import { PickTermDialog } from './PickTermDialog';
import { TemplateComponentDialog, TemplateComponentEditType } from './TemplateComponentDialog';
import { SearchCache, SearchCacheRoot } from '../data/SearchCache';
import { deepClone, embedSchemaTrees } from '../data/utils';

import { ClipboardDialog } from './ClipboardDialog';
import { ModalUtils } from '@/shared/utils/modalUtils';
import { Img } from '@/shared/components/sanitizedTags.js';
import branchOpenIcon from 'ASSETS/images/branch_open_icon.svg';
import branchCloseIcon from 'ASSETS/images/branch_close_icon.svg';
import branchDotIcon from 'ASSETS/images/branch_dot_icon.svg';

type Props = {
  isOpen: boolean,
  isNewTemplate: boolean,
  svc: TemplateServices,
  template: OntologyTemplate, // incoming
  readOnly: boolean,
  canMakePublic: boolean,
  onDialogCancel: () => void,
  onDialogSubmit: (template: OntologyTemplate) => void,
  onDeleteTemplate: (pfx: string) => void,
  onMakePublic: (pfx: string) => void,
};

type State = {
  watermarkUpdate: number,
  hoverButtonKey: string,
  isPickerOpen: boolean,
  pickerTemplate: OntologyTemplate,
  pickerAssn: TemplateAssignment,
  pickerNest: string[],
  pickerRoots: SchemaBranch[],
  pickerSelected: string[],
  pickerSearch: SearchCache,
  isComponentOpen: boolean,
  componentDialogTitle: string;
  componentEditType: TemplateComponentEditType;
  componentGroup: TemplateGroup,
  componentAssn: TemplateAssignment,
  componentValue: TemplateValue,
  componentCustomURI: string,
  componentApply: (object: TemplateGroup | TemplateAssignment | TemplateValue) => void,
  isClipboardOpen: boolean,
  clipboardDataType: TemplateComponentEditType,
  clipboardText: string,
  clipboardEntity: TemplateGroup | TemplateAssignment | TemplateValue,
  clipboardTemplate: OntologyTemplate,
  clipboardPaste: (text: string) => void,
  isDownloadOpen: boolean,
};

export class TemplateEditorDialog extends React.Component<Props, State> {
  private template: OntologyTemplate = null;
  private openBranches: Set<string> = null;
  private undoStack: OntologyTemplate[] = [];
  private redoStack: OntologyTemplate[] = [];

  constructor(props) {
    super(props);

    this.state = {
      watermarkUpdate: 0,
      hoverButtonKey: null,
      isPickerOpen: false,
      pickerTemplate: null,
      pickerAssn: null,
      pickerNest: null,
      pickerRoots: null,
      pickerSelected: null,
      pickerSearch: null,
      isComponentOpen: false,
      componentDialogTitle: null,
      componentEditType: null,
      componentGroup: null,
      componentAssn: null,
      componentValue: null,
      componentCustomURI: null,
      componentApply: null,
      isClipboardOpen: false,
      clipboardDataType: null,
      clipboardText: '',
      clipboardEntity: null,
      clipboardTemplate: null,
      clipboardPaste: null,
      isDownloadOpen: false,
    };
  }

  get dialogTitle() {
    return 'Edit Ontology Template';
  }

  private makeKey(uriList: string[]) {
    return uriList.join('::');
  }

  public render() {
    if (!this.props.isOpen) {
      this.template = null;
      this.openBranches = new Set<string>();
      this.undoStack = [];
      this.redoStack = [];
    } else if (!this.template) {
      this.template = this.props.template;

      const openGroup = (parentNest: string[], group: TemplateGroup) => {
        const groupNest = [group.groupURI, ...parentNest];
        this.openBranches.add(this.makeKey(groupNest));
        for (const sub of group.subGroups) openGroup(groupNest, sub);
      };
      openGroup([], this.template.root);
    }

    return (
      <Dialog
        open={this.props.isOpen}
        onClose={this.handleCancel}
        className='EditTeamDialog edit-account-object-dialog'
        PaperProps={{ className: 'main-dialog-paper' }}
      >
        {this.renderHierarchy()}
      </Dialog>
    );
  }

  private renderHierarchy(): JSX.Element {
    const { template } = this;
    if (!template) return null;

    const btnPublic = this.props.onMakePublic && !this.props.isNewTemplate && this.props.canMakePublic ? (
      <div
        className="OntologyTemplate-menubutton OntologyTemplate-clickable"
        onClick={() => this.props.onMakePublic(this.props.template.schemaPrefix)}
      >
      Make Public
      </div>
    ) : null;

    const btnDelete = this.props.onDeleteTemplate && !this.props.isNewTemplate ? (
      <div
        className="OntologyTemplate-menubutton OntologyTemplate-clickable"
        onClick={() => this.props.onDeleteTemplate(this.props.template.schemaPrefix)}
      >
      Delete
      </div>
    ) : null;

    const fnBase = this.template.root.name.replace(/[^\w]/g, '_');
    const fnTemplate = fnBase + '.json';
    const fnFullSchema = fnBase + '_fullschema.json';

    const btnDownload = (
      <div className="OntologyTemplate-menubutton">
        <div className="OntologyTemplate-menuparent">
          <div
            className="OntologyTemplate-clickable"
            onClick={this.handleToggleDownload}
            >
            Download
          </div>
          <div
            className="menuboundary"
            style={{ display: this.state.isDownloadOpen ? 'block' : 'none' }}
            >
            <div>
              Download template
            </div>
            <div
              className="OntologyTemplate-menubutton OntologyTemplate-clickable"
              onClick={() => this.handleDownload(fnTemplate, false).then()}
              >
              {fnTemplate}
            </div>
            <div>
              Download template with full schema tree embedded (large)
            </div>
            <div
              className="OntologyTemplate-menubutton OntologyTemplate-clickable"
              onClick={() => this.handleDownload(fnFullSchema, true).then()}
              >
              {fnFullSchema}
            </div>
          </div>
        </div>
      </div>
    );

    return (
      <>
        <DialogTitle className='muiDialog-title'>
          {this.dialogTitle}
        </DialogTitle>
        <DialogContent className="OntologyTemplate-toppadding">
          <div className="OntologyTemplate-footspace OntologyTemplate-oppositesides">
            <div>
              <b>Schema Prefix</b>:
              &nbsp;
              <span className="OntologyTemplate-schemaPrefix">{template.schemaPrefix}</span>
            </div>
            <div>
              {btnPublic}
              {btnDelete}
              {btnDownload}
            </div>
          </div>
          {this.renderGroup(template.root, null, [])}
        </DialogContent>
        <DialogActions className="project__action OntologyTemplate-topborder">
          <div className="OntologyTemplate-oppositesides">
            <div className="OntologyTemplate-edit-hbox">
              <div
                className={`OntologyTemplate-menubutton OntologyTemplate-${this.undoStack.length > 0 ? 'clickable' : 'unclickable'}`}
                onClick={this.handleUndo}
                >
                Undo
              </div>
              <div
                className={`OntologyTemplate-menubutton OntologyTemplate-${this.redoStack.length > 0 ? 'clickable' : 'unclickable'}`}
                onClick={this.handleRedo}
                >
                Redo
              </div>
            </div>
            <CancelOrSubmit
              green={true}
              onCancel={this.handleCancel}
              onSubmit={this.handleSubmit}
              disabled={this.props.readOnly}
            >
              Save
            </CancelOrSubmit>
          </div>
        </DialogActions>

        <PickTermDialog
          isOpen={this.state.isPickerOpen}
          template={this.state.pickerTemplate}
          assn={this.state.pickerAssn}
          groupNest={this.state.pickerNest}
          roots={this.state.pickerRoots || []}
          selected={[]}
          search={this.state.pickerSearch}
          onDialogCancel={this.handleClosePickTerm}
          onDialogSubmit={null}
        />

        <TemplateComponentDialog
          isOpen={this.state.isComponentOpen}
          dialogTitle={this.state.componentDialogTitle}
          editType={this.state.componentEditType}
          group={this.state.componentGroup}
          assn={this.state.componentAssn}
          value={this.state.componentValue}
          customURI={this.state.componentCustomURI}
          onDialogCancel={this.handleCloseComponent}
          onDialogApply={this.state.componentApply}
        />

        <ClipboardDialog
          isOpen={this.state.isClipboardOpen}
          dataType={this.state.clipboardDataType}
          initText={this.state.clipboardText}
          entity={this.state.clipboardEntity}
          template={this.state.clipboardTemplate}
          onDialogCancel={() => this.setState({ isClipboardOpen: false })}
          onDialogPaste={this.state.clipboardPaste}
        />
      </>
    );
  }

  private renderGroup(group: TemplateGroup, parentNest: string[], keyseq: number[]): JSX.Element {
    if (!group) return null;

    const useKey = 'group-' + keyseq.join(',');
    const isRoot = parentNest == null;

    const groupNest = isRoot ? [] : [group.groupURI, ...parentNest];
    const isOpen = this.openBranches.has(this.makeKey(groupNest));
    const anyChildren = (group.assignments && group.assignments.length > 0) || (group.subGroups && group.subGroups.length > 0);

    let canMoveUp = false, canMoveDown = false;
    if (!isRoot) {
      const parentGroup = new Schema(this.template).findGroup(parentNest);
      if (parentGroup.subGroups.length > 1) {
        const idx = parentGroup.subGroups.findIndex((look) => look.groupURI == group.groupURI);
        canMoveUp = idx > 0;
        canMoveDown = idx < parentGroup.subGroups.length - 1;
      }
    }

    let toggle: JSX.Element = null;
    if (!isRoot) {
      const svgPath = !anyChildren ? branchDotIcon : isOpen ? branchCloseIcon : branchOpenIcon;
      toggle = (
        <div
          className="TermPicker-toggle"
          onClick={() => this.handleToggleBranch(groupNest)}
          >
          <Img className="TermPicker-icon" src={svgPath}/>
        </div>
      );
    }

    const tooltip = (
      <Typography component="span" className="ProtocolAnnotator-tooltip">
        {group.groupURI ? (<div><b>{group.groupURI}</b></div>) : null}
        <div>{group.descr || '(no description)'}</div>
      </Typography>
    ); // NOTE: not showing 'canDuplicate', not sure if we're going to implement that

    const hoverKey = 'hover-' + useKey;
    const hoverButtons = (
      <div
        key={hoverKey}
        className="OntologyTemplate-hoverbuttons"
        style={{ visibility: this.state.hoverButtonKey == hoverKey ? 'visible' : 'hidden' }}
        >
        <div
          className="OntologyTemplate-menubutton OntologyTemplate-clickable"
          onClick={() => this.handleAppendGroup(groupNest)}
          >
          +Group
        </div>
        <div
          className="OntologyTemplate-menubutton OntologyTemplate-clickable"
          onClick={() => this.handleAppendAssignment(groupNest)}
          >
          +Assignment
        </div>
        {
          !isRoot ? (<div
            className="OntologyTemplate-menubutton OntologyTemplate-clickable"
            onClick={() => this.handleDeleteGroup(group, parentNest)}
            >
            Delete
          </div>) : null
        }
        {
          canMoveUp ? (<div
            className="OntologyTemplate-menubutton OntologyTemplate-clickable"
            onClick={() => this.handleMoveGroup(group, parentNest, -1)}
            >
            Move Up
          </div>) : null
        }
        {
          canMoveDown ? (<div
            className="OntologyTemplate-menubutton OntologyTemplate-clickable"
            onClick={() => this.handleMoveGroup(group, parentNest, 1)}
            >
            Move Down
          </div>) : null
        }
        <div
          className="OntologyTemplate-menubutton OntologyTemplate-clickable"
          onClick={() => this.handleClipboard(isRoot ? TemplateComponentEditType.Root : TemplateComponentEditType.Group, group, groupNest)}
          >
          Clipboard
        </div>
      </div>
    );

    return (
      <React.Fragment key={useKey}>
        <div
          className="OntologyTemplate-branch"
          onMouseEnter={() => this.setState({ hoverButtonKey: hoverKey })}
          onMouseLeave={() => this.setState({ hoverButtonKey: null })}
          >
          {toggle}
          <Tooltip
            title={tooltip}
            arrow
            placement="left"
            >
            <div
              className="OntologyTemplate-group OntologyTemplate-clickable"
              onClick={() => this.handleEditGroup(group, parentNest, keyseq.length == 0)}
              >
              {group.name}
            </div>
          </Tooltip>
          {hoverButtons}
        </div>
        {isOpen && anyChildren ? (
          <div className="OntologyTemplate-indent">
            {(group.assignments || []).map((assn, idx) => this.renderAssignment(assn, groupNest, [...keyseq, idx]))}
            {(group.subGroups || []).map((sgrp, idx) => this.renderGroup(sgrp, groupNest, [...keyseq, idx]))}
          </div>
        ) : null}
      </React.Fragment>
    );
  }

  private renderAssignment(assn: TemplateAssignment, groupNest: string[], keyseq: number[]): JSX.Element {
    const useKey = 'assn-' + keyseq.join(',');

    const uriNest = [assn.propURI, ...groupNest];
    const isOpen = this.openBranches.has(this.makeKey(uriNest));
    const anyChildren = assn.values && assn.values.length > 0;

    let canMoveUp = false, canMoveDown = false;
    const parentGroup = new Schema(this.template).findGroup(groupNest);
    if (parentGroup.assignments.length > 1) {
      const idx = parentGroup.assignments.findIndex((look) => look.propURI == assn.propURI);
      canMoveUp = idx > 0;
      canMoveDown = idx < parentGroup.assignments.length - 1;
    }

    const svgPath = !anyChildren ? branchDotIcon : isOpen ? branchCloseIcon : branchOpenIcon;
    const toggle = (
      <div
        className="TermPicker-toggle"
        onClick={() => this.handleToggleBranch(uriNest)}
        >
        <Img className="TermPicker-icon" src={svgPath}/>
      </div>
    );

    const typeInfo = [assn.suggestions as string];
    if (assn.mandatory) typeInfo.push('mandatory');
    const tooltip = (
      <Typography component="span" className="ProtocolAnnotator-tooltip">
        <div><b>{assn.propURI}</b></div>
        <div><i>{typeInfo.join(', ')}</i></div>
        {assn.descr ? (<div>{assn.descr}</div>) : null}
      </Typography>
    );

    const hoverKey = 'hover-' + useKey;
    const hasTree = assn.values.length > 0 && (assn.suggestions == SuggestionType.Full || assn.suggestions == SuggestionType.Disabled);

    const hoverButtons = (
      <div
        key={hoverKey}
        className="OntologyTemplate-hoverbuttons"
        style={{ visibility: this.state.hoverButtonKey == hoverKey ? 'visible' : 'hidden' }}
        >
        {
          hasTree ? (<div
            className="OntologyTemplate-menubutton OntologyTemplate-clickable"
            onClick={() => this.handleViewTree(assn, groupNest)}
            >
            View Tree
          </div>) : null
        }
        <div
          className="OntologyTemplate-menubutton OntologyTemplate-clickable"
          onClick={() => this.handleAppendValue(assn, groupNest)}
          >
          +Value
        </div>
        <div
          className="OntologyTemplate-menubutton OntologyTemplate-clickable"
          onClick={() => this.handleDeleteAssignment(assn, groupNest)}
          >
          Delete
        </div>
        {
          canMoveUp ? (<div
            className="OntologyTemplate-menubutton OntologyTemplate-clickable"
            onClick={() => this.handleMoveAssignment(assn, groupNest, -1)}
            >
            Move Up
          </div>) : null
        }
        {
          canMoveDown ? (<div
            className="OntologyTemplate-menubutton OntologyTemplate-clickable"
            onClick={() => this.handleMoveAssignment(assn, groupNest, 1)}
            >
            Move Down
          </div>) : null
        }
        <div
          className="OntologyTemplate-menubutton OntologyTemplate-clickable"
          onClick={() => this.handleClipboard(TemplateComponentEditType.Assignment, assn, [assn.propURI, ...groupNest])}
          >
          Clipboard
        </div>
      </div>
    );

    return (
      <React.Fragment key={useKey}>
        <div
          className="OntologyTemplate-branch"
          onMouseEnter={() => this.setState({ hoverButtonKey: hoverKey })}
          onMouseLeave={() => this.setState({ hoverButtonKey: null })}
          >
          {toggle}
          <Tooltip
            title={tooltip}
            arrow
            placement="left"
            enterDelay={1000}
            >
            <div
              className="OntologyTemplate-assn OntologyTemplate-clickable"
              onClick={() => this.handleEditAssignment(assn, groupNest)}
              >
              {assn.name}
            </div>
          </Tooltip>
          {hoverButtons}
        </div>
        {isOpen && anyChildren ? (
          <div className="OntologyTemplate-indent">
            {(assn.values || []).map((value, idx) => this.renderValue(value, assn.propURI, groupNest, [...keyseq, idx]))}
          </div>
        ) : null}
      </React.Fragment>
    );
  }

  private renderValue(value: TemplateValue, propURI: string, groupNest: string[], keyseq: number[]): JSX.Element {
    const useKey = 'assn-' + keyseq.join(',');

    const tooltip = (
      <Typography component="span" className="ProtocolAnnotator-tooltip">
        <div><b>{value.uri}</b></div>
        <div><i>{value.spec}</i></div>
        {value.descr ? (<div>{value.descr}</div>) : null}
        {value.altLabels && value.altLabels.length > 0 ? (<div>Labels: {value.altLabels.join(', ')}</div>) : null}
        {value.externalURLs && value.externalURLs.length > 0 ? (<div>URLs: {value.externalURLs.join(', ')}</div>) : null}
        {value.parentURI ? (<div>Parent: {value.parentURI}</div>) : null}
      </Typography>
    );

    const hoverKey = 'hover-' + useKey;
    const hoverButtons = (
      <div
        key={hoverKey}
        className="OntologyTemplate-hoverbuttons"
        style={{ visibility: this.state.hoverButtonKey == hoverKey ? 'visible' : 'hidden' }}
        >
        <div
          className="OntologyTemplate-menubutton OntologyTemplate-clickable"
          onClick={() => this.handleChildValue(value, propURI, groupNest)}
          >
          Add Child
        </div>
        <div
          className="OntologyTemplate-menubutton OntologyTemplate-clickable"
          onClick={() => this.handleDeleteValue(value, propURI, groupNest)}
          >
          Delete
        </div>
        <div
          className="OntologyTemplate-menubutton OntologyTemplate-clickable"
          onClick={() => this.handleClipboard(TemplateComponentEditType.Value, value, [value.uri, propURI, ...groupNest])}
          >
          Clipboard
        </div>
      </div>
    );

    return (
      <React.Fragment key={useKey}>
        <div
          className="OntologyTemplate-branch"
          onMouseEnter={() => this.setState({ hoverButtonKey: hoverKey })}
          onMouseLeave={() => this.setState({ hoverButtonKey: null })}
          >
          <Tooltip
            title={tooltip}
            arrow
            placement="left"
            >
            <div
              className="OntologyTemplate-value OntologyTemplate-clickable"
              onClick={() => this.handleEditValue(value, propURI, groupNest)}
              >
              {value.name}
            </div>
          </Tooltip>
          {hoverButtons}
        </div>
      </React.Fragment>
    );
  }

  private replaceTemplate(template: OntologyTemplate): void {
    this.undoStack.push(this.template); // note: we assume that the template has been deep cloned, i.e. template !== this.template
    while (this.undoStack.length > 20) this.undoStack.shift();
    this.redoStack = [];
    this.template = template;
    this.setState({
      watermarkUpdate: this.state.watermarkUpdate + 1,
      isDownloadOpen: false,
    });
  }

  private hasBeenModified(): boolean {
    const valueThen = JSON.stringify(this.props.template);
    const valueNow = JSON.stringify(this.template);
    return valueThen != valueNow;
  }

  private handleUndo = (): void => {
    if (this.undoStack.length == 0) return;
    this.redoStack.push(this.template);
    this.template = this.undoStack.pop();
    this.setState({
      watermarkUpdate: this.state.watermarkUpdate + 1,
      isDownloadOpen: false,
    });
  };

  private handleRedo = (): void => {
    if (this.redoStack.length == 0) return;
    this.undoStack.push(this.template);
    this.template = this.redoStack.pop();
    this.setState({
      watermarkUpdate: this.state.watermarkUpdate + 1,
      isDownloadOpen: false,
    });
  };

  private handleToggleBranch(uriNest: string[]): void {
    const key = this.makeKey(uriNest);
    if (this.openBranches.has(key)) {
      this.openBranches.delete(key);
    } else {
      this.openBranches.add(key);
    }
    this.setState({
      watermarkUpdate: this.state.watermarkUpdate + 1,
      isDownloadOpen: false,
    });
  }

  private handleEditGroup(srcGroup: TemplateGroup, groupNest: string[], isRoot: boolean): void {
    this.setState({
      isComponentOpen: true,
      componentDialogTitle: isRoot ? 'Edit Root' : 'Edit Group',
      componentEditType: isRoot ? TemplateComponentEditType.Root : TemplateComponentEditType.Group,
      componentGroup: srcGroup,
      componentAssn: null,
      componentValue: null,
      componentCustomURI: new Schema(this.template).pickCustomURI(isRoot ? null : srcGroup.groupURI),
      componentApply: (modGroup: TemplateGroup) => {
        const schema = new Schema(deepClone(this.template));
        const group = schema.findGroup(isRoot ? [] : [srcGroup.groupURI, ...groupNest]);
        group.name = modGroup.name;
        group.descr = modGroup.descr;
        group.groupURI = modGroup.groupURI;
        group.canDuplicate = modGroup.canDuplicate;
        this.replaceTemplate(schema.template);
        this.setState({ isComponentOpen: false });
      },
      isDownloadOpen: false,
    });
  }

  private handleAppendGroup(groupNest: string[]): void {
    this.setState({
      isComponentOpen: true,
      componentDialogTitle: 'Append Group',
      componentEditType: TemplateComponentEditType.Group,
      componentGroup: { name: '', groupURI: '', assignments: [], subGroups: [] },
      componentAssn: null,
      componentValue: null,
      componentCustomURI: new Schema(this.template).pickCustomURI(null),
      componentApply: (group: TemplateGroup) => {
        const schema = new Schema(deepClone(this.template));
        const parentGroup = schema.findGroup(groupNest);
        parentGroup.subGroups.push(group);
        this.replaceTemplate(schema.template);
        this.openBranches.add(this.makeKey(groupNest));
        this.setState({ isComponentOpen: false });
      },
      isDownloadOpen: false,
    });
  }

  private handleAppendAssignment(groupNest: string[]): void {
    this.setState({
      isComponentOpen: true,
      componentDialogTitle: 'Append Assignment',
      componentEditType: TemplateComponentEditType.Assignment,
      componentGroup: null,
      componentAssn: { name: '', propURI: '', suggestions: SuggestionType.Full, mandatory: false, values: [] },
      componentValue: null,
      componentCustomURI: new Schema(this.template).pickCustomURI(null),
      componentApply: (assn: TemplateAssignment) => {
        const schema = new Schema(deepClone(this.template));
        const parentGroup = schema.findGroup(groupNest);
        parentGroup.assignments.push(assn);
        this.replaceTemplate(schema.template);
        this.openBranches.add(this.makeKey(groupNest));
        this.setState({ isComponentOpen: false });
      },
      isDownloadOpen: false,
    });
  }

  private handleDeleteGroup(group: TemplateGroup, parentNest: string[]): void {
    const schema = new Schema(deepClone(this.template));
    const parentGroup = schema.findGroup(parentNest);
    const idx = parentGroup.subGroups.findIndex((look) => look.groupURI == group.groupURI);
    parentGroup.subGroups.splice(idx, 1);
    this.replaceTemplate(schema.template);
  }

  private handleEditAssignment(srcAssn: TemplateAssignment, groupNest: string[]): void {
    this.setState({
      isComponentOpen: true,
      componentDialogTitle: 'Edit Assignment',
      componentEditType: TemplateComponentEditType.Assignment,
      componentGroup: null,
      componentAssn: srcAssn,
      componentValue: null,
      componentCustomURI: new Schema(this.template).pickCustomURI(srcAssn.propURI),
      componentApply: (modAssn: TemplateAssignment) => {
        const schema = new Schema(deepClone(this.template));
        const assn = schema.findAssignment(srcAssn.propURI, groupNest);
        assn.name = modAssn.name;
        assn.descr = modAssn.descr;
        assn.propURI = modAssn.propURI;
        assn.suggestions = modAssn.suggestions;
        assn.mandatory = modAssn.mandatory;
        this.replaceTemplate(schema.template);
        this.setState({ isComponentOpen: false });
      },
      isDownloadOpen: false,
    });
  }

  private handleAppendValue(srcAssn: TemplateAssignment, parentNest: string[]): void {
    this.setState({
      isComponentOpen: true,
      componentDialogTitle: 'Append Value',
      componentEditType: TemplateComponentEditType.Value,
      componentGroup: null,
      componentAssn: null,
      componentValue: { uri: null, name: null, spec: null },
      componentCustomURI: new Schema(this.template).pickCustomURI(null),
      componentApply: (value: TemplateValue) => {
        const schema = new Schema(deepClone(this.template));
        const parentAssn = schema.findAssignment(srcAssn.propURI, parentNest);
        parentAssn.values.push(value);
        this.replaceTemplate(schema.template);
        this.openBranches.add(this.makeKey([srcAssn.propURI, ...parentNest]));
        this.setState({ isComponentOpen: false });
      },
      isDownloadOpen: false,
    });
  }

  private handleDeleteAssignment(assn: TemplateAssignment, groupNest: string[]): void {
    const schema = new Schema(deepClone(this.template));
    const parentGroup = schema.findGroup(groupNest);
    const idx = parentGroup.assignments.findIndex((look) => look.propURI == assn.propURI);
    parentGroup.assignments.splice(idx, 1);
    this.replaceTemplate(schema.template);
  }

  private handleViewTree(assn: TemplateAssignment, groupNest: string[]): void {
    (async () => {
      const schema = new Schema(this.template);
      const roots = await schema.composeBranch(assn, []);
      const cacheRoots: SearchCacheRoot[] = roots.map((branch) => {
        return {
          assn,
          groupNest,
          branch,
        };
      });

      this.setState({
        isPickerOpen: true,
        pickerTemplate: schema.template,
        pickerAssn: assn,
        pickerNest: groupNest,
        pickerRoots: roots,
        pickerSelected: [],
        pickerSearch: new SearchCache(schema, cacheRoots),
        isDownloadOpen: false,
      });
    })();
  }

  private handleEditValue(srcValue: TemplateValue, propURI: string, groupNest: string[]): void {
    this.setState({
      isComponentOpen: true,
      componentDialogTitle: 'Edit Value',
      componentEditType: TemplateComponentEditType.Value,
      componentGroup: null,
      componentAssn: null,
      componentValue: srcValue,
      componentCustomURI: new Schema(this.template).pickCustomURI(srcValue.uri),
      componentApply: (modValue: TemplateValue) => {
        const schema = new Schema(deepClone(this.template));
        const assn = schema.findAssignment(propURI, groupNest);
        const idx = assn.values.findIndex((look) => look.uri == srcValue.uri);
        assn.values[idx] = modValue;
        this.replaceTemplate(schema.template);
        this.setState({ isComponentOpen: false });
      },
      isDownloadOpen: false,
    });
  }

  private handleDeleteValue(value: TemplateValue, propURI: string, groupNest: string[]): void {
    const schema = new Schema(deepClone(this.template));
    const parentAssn = schema.findAssignment(propURI, groupNest);
    const idx = parentAssn.values.findIndex((look) => look.uri == value.uri);
    parentAssn.values.splice(idx, 1);
    this.replaceTemplate(schema.template);
  }

  private handleChildValue(value: TemplateValue, propURI: string, groupNest: string[]): void {
    const customURI = new Schema(this.template).pickCustomURI(null);
    this.setState({
      isComponentOpen: true,
      componentDialogTitle: 'Append Value',
      componentEditType: TemplateComponentEditType.Value,
      componentGroup: null,
      componentAssn: null,
      componentValue: { uri: customURI, name: null, spec: SpecificationType.Item, parentURI: value.uri },
      componentCustomURI: customURI,
      componentApply: (value: TemplateValue) => {
        const schema = new Schema(deepClone(this.template));
        const parentAssn = schema.findAssignment(propURI, groupNest);
        parentAssn.values.push(value);
        this.replaceTemplate(schema.template);
        this.setState({ isComponentOpen: false });
      },
    });
  }

  private handleMoveGroup(group: TemplateGroup, parentNest: string[], dir: number): void {
    const schema = new Schema(deepClone(this.template));
    const parentGroup = schema.findGroup(parentNest);
    const list = parentGroup.subGroups, idx = list.findIndex((look) => look.groupURI == group.groupURI);
    [list[idx], list[idx + dir]] = [list[idx + dir], list[idx]];
    this.replaceTemplate(schema.template);
  }

  private handleMoveAssignment(assn: TemplateAssignment, parentNest: string[], dir: number): void {
    const schema = new Schema(deepClone(this.template));
    const parentGroup = schema.findGroup(parentNest);
    const list = parentGroup.assignments, idx = list.findIndex((look) => look.propURI == assn.propURI);
    [list[idx], list[idx + dir]] = [list[idx + dir], list[idx]];
    this.replaceTemplate(schema.template);
  }

  private handleClipboard(dataType: TemplateComponentEditType, entity: TemplateGroup | TemplateAssignment | TemplateValue, uriSequence: string[]): void { // !! TODO: add {type}
    this.setState({
      isClipboardOpen: true,
      clipboardDataType: dataType,
      clipboardText: JSON.stringify(entity, null, 4),
      clipboardEntity: entity,
      clipboardTemplate: this.template,
      clipboardPaste: (text: string) => {
        const schema = new Schema(deepClone(this.template));
        try {
          const json = JSON.parse(text);
          if (dataType == TemplateComponentEditType.Root || dataType == TemplateComponentEditType.Group) {
            schema.pasteIntoGroup(uriSequence, json);
          } else if (dataType == TemplateComponentEditType.Assignment) {
            schema.pasteIntoAssignment(uriSequence[0], uriSequence.slice(1), json);
          }
          this.openBranches.add(this.makeKey(uriSequence));
          this.setState({ isClipboardOpen: false });
          this.replaceTemplate(schema.template);
        } catch (ex) {
          ModalUtils.showModal('Unable to paste: ' + ex, {});
        }
      },
      isDownloadOpen: false,
    });
  }

  private handleClosePickTerm = (): void => {
    this.setState({ isPickerOpen: false });
  };

  private handleCloseComponent = (): void => {
    this.setState({ isComponentOpen: false });
  };

  private handleSubmit = (): void => {
    this.setState({ isDownloadOpen: false });
    this.props.onDialogSubmit(this.template);
  };

  private handleCancel = (): void => {
    if (this.hasBeenModified()) {
      const MSG = 'Template has been modified. Abandon changes?';
      if (!confirm(MSG)) return;
    }

    this.setState({ isDownloadOpen: false });
    this.props.onDialogCancel();
  };

  private handleToggleDownload = (): void => {
    this.setState({ isDownloadOpen: !this.state.isDownloadOpen });
  };

  // manufactures the schema JSON string and triggers a download; if requested, a full schema tree will be included (this can get big)
  private async handleDownload(fn: string, withSchemaTree: boolean): Promise<void> {
    let json: string;
    if (withSchemaTree) {
      const template = deepClone(this.template);
      await embedSchemaTrees(template);
      json = JSON.stringify(template);
    } else {
      json = JSON.stringify(this.template, null, 4);
    }

    const file = new Blob([json], { type: 'text/plain;charset=utf-8' });
    const element = document.createElement('a');
    element.href = URL.createObjectURL(file);
    element.download = fn;
    document.body.appendChild(element);
    element.click();
    element.remove();

    // give it a moment to start, before hiding
    setTimeout(() => {
      this.setState({ isDownloadOpen: false });
    }, 500);
  }
}
