import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import {
  CircularProgress,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material';
import { ProtocolsColumnsEditor } from './ProtocolsColumnsEditor';
import { TableHeader } from './TableHeader';
import { observable, computed, runInAction, makeObservable, action } from 'mobx';
import { observer } from 'mobx-react';
import { ColumnForDisplay, ID, Protocol } from '@/Protocols/types';

type Props = {
  displayedColumns: ColumnForDisplay[],
  availableColumns: ColumnForDisplay[],
  rows: Protocol[],
  hasMoreRows?: boolean,
  displayRowCount?: number,
  loadingBranches?: string[],

  onChangeVisibleColumnIds: (value: ID[]) => void,
  onClickColumn: (id: ID) => void,
  onFetchMoreRows?: () => void,
  renderCell: (row: Protocol, column: ColumnForDisplay) => JSX.Element,
};

const DisplayRowCount = 100;

@observer
export class SearchResultsTable extends React.Component<Props> {
  rowLimit = 100;

  constructor(props) {
    super(props);
    makeObservable(this, {
      rowLimit: observable,
      displayedRows: computed,
      setRowLimit: action,
    });
  }

  componentDidMount() {
    this.setRowLimit(this.props.displayRowCount ?? DisplayRowCount);
  }

  setRowLimit(value: number) {
    this.rowLimit = value;
  }

  handleClickColumn = (id: ID) => {
    this.props.onClickColumn(id);
  };

  handleFetchMoreRows = () => {
    if (this.props.onFetchMoreRows) {
      this.props.onFetchMoreRows();
    } else {
      runInAction(() => {
        this.rowLimit += this.props.displayRowCount ?? DisplayRowCount;
      });
    }
  };

  get displayedColumns(): ColumnForDisplay[] {
    return this.props ? this.props.displayedColumns : [];
  }

  get displayedRows(): Protocol[] {
    if (!this.props.rows) {
      return [];
    }
    if (this.props.rows.length <= this.rowLimit) {
      return this.props.rows;
    }
    return this.props.rows.slice(0, this.rowLimit);
  }

  renderTableHeader = () => {
    const options = this.props.availableColumns;
    const value = this.props.displayedColumns.map(col => col.id);

    return (
      <TableHead className='search_results__header'>
        <TableRow className='search_results__row'>
          {this.displayedColumns.map((column) => (
            <TableHeader
              id={`${column.id}`}
              key={column.id}
              label={column.label || column.name}
              direction={column.direction}
              onClick={this.handleClickColumn}
            />
          ))}

          <TableCell className='search_results__item search_results__item__edit'>
            <ProtocolsColumnsEditor
              options={options}
              value={value}
              onChangeValue={this.props.onChangeVisibleColumnIds}
            />
          </TableCell>
        </TableRow>
      </TableHead>
    );
  };

  render() {
    const { availableColumns, renderCell, hasMoreRows } = this.props;
    const rows = this.displayedRows;
    const hasMore = (hasMoreRows === undefined) ? (this.props.rows.length > this.rowLimit) : hasMoreRows;

    if (!rows || !availableColumns) {
      return null;
    }

    return (
      <InfiniteScroll
        element='div'
        pageStart={0}
        loadMore={this.handleFetchMoreRows}
        hasMore={hasMore}
        loader={
          <CircularProgress
            key={0}
            className='circular_progress infinite_scroll__loader'
          />
        }
      >
        <div className='search_results__container'>
          <Table className='search_results__table'>
            {this.renderTableHeader()}
            <TableBody>
              {rows.map(row => <TableRow key={row.id}>
                {this.displayedColumns.map((column) => (
                  <TableCell key={column.id} className='search_results__item '>{
                    (renderCell ? renderCell(row, column) : null) || row[column.id]
                  }</TableCell>))}
                <TableCell className='search_results__item search_results__item__edit'></TableCell>
              </TableRow>)
              }
            </TableBody>
          </Table>
        </div>
      </InfiniteScroll>
    );
  }
}
