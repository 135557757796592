import precautionary_codes from './assets/precautionary_codes.json';
import hazard_codes from './assets/hazard_codes.json';
import { NOT_CLASSIFIED } from './components/HazardClassIcons';

export type PCode = {
  code: string;
  details: string;
}

export const PrecautionaryType = {
  '1': 'general',
  '2': 'prevention',
  '3': 'response',
  '4': 'storage',
  '5': 'disposal',
};

export type HCode = {
  hazard_code: string;
  hazard_statement: string;
  hazard_class: string[];
  category: string;
  pictogram: string;
  signal_word: string;
  precautionary_codes: string;
}

export const get_hcode_data = (hcodes: string[] | string) => {
  if (!Array.isArray(hcodes)) {
    hcodes = hcodes.split(',');
  }
  return hcodes.map((hcode) => hazard_codes[hcode] as HCode);
};

export const get_pcode_data = (pcodes: string[] | string) => {
  if (!Array.isArray(pcodes)) {
    pcodes = pcodes.split(',');
  }
  return pcodes.map((pcode) => precautionary_codes[pcode] as PCode).filter((pcode) => pcode);
};

export const group_pcodes = (pcodes: PCode[]) => {
  const grouped: { [key: string]: PCode[] } = {};
  pcodes.forEach((pcode) => {
    const group = pcode.code[1];
    if (!grouped[group]) {
      grouped[group] = [];
    }
    grouped[group].push(pcode);
  });
  return grouped;
};

export type SafetyData = {
  hcodes: string[]; // hazard codes
  pcodes: string[]; // precautionary codes
  hclasses: string[]; // GHS hazard classes
  not_classified: boolean;
  no_hazard_codes: boolean;
}

export const get_safety_data = (hcodes: string | string[]): SafetyData => {
  const no_hazard_codes = !hcodes || hcodes == '';
  if (!Array.isArray(hcodes)) {
    hcodes = hcodes.split(',');
  }
  const result: SafetyData = {
    hcodes: [],
    pcodes: [],
    hclasses: [],
    not_classified: hcodes.includes(NOT_CLASSIFIED),
    no_hazard_codes,
  };
  if (result.no_hazard_codes) {
    return result;
  }

  if (result.not_classified) {
    return result;
  }

  get_hcode_data(hcodes).forEach((hcode) => {
    if (!hcode) {
      return;
    }
    result.hcodes.push(hcode.hazard_code);
    if (hcode.precautionary_codes) {
      result.pcodes.push(...hcode.precautionary_codes.split(','));
    }
    if (hcode.pictogram) {
      result.hclasses.push(...hcode.pictogram.split(','));
    }
  });
  result.hcodes = [...new Set(result.hcodes)].sort();
  result.pcodes = [...new Set(result.pcodes)].sort();
  result.hclasses = [...new Set(result.hclasses)].sort();
  if (result.hcodes.length == 0) {
    result.no_hazard_codes = true;
  }
  return result;
};
