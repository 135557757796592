import { FieldDefinition } from '@/FieldDefinitions/types';
import {
  currentAmountForSample,
  locationForEvent,
  setEventCreditDebit,
} from '@/Samples/stores/sampleDataStore';
import {
  Batch,
  EditInventoryEvent,
  EditSample,
  LocationValue,
} from '@/Samples/types';
import { CDDModalForm } from '@/shared/components/CDDForm/CDDForm';
import { layoutBuilder } from '@/shared/components/DDForm/layoutBuilder';
import { ElementType } from '@/shared/components/DDForm/types';
import { SelectMultipleDef } from '@/shared/components/DDForm/types/selectMultipleDef';
import { SelectSingleDef } from '@/shared/components/DDForm/types/selectSingleDef';
import { TypographyDef } from '@/shared/components/DDForm/types/typographyDef';
import { term } from '@/shared/utils/stringUtils';
import { AnyObject } from '@/types';
import { Dialog } from '@mui/material';
import { makeObservable, observable, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { FieldDefinitionFormUtils } from '../../../Samples/components/fieldDefinitionFormUtils';
import {
  INVENTORY_EVENT_FIELDS,
  INVENTORY_SAMPLE_FIELDS,
  InventoryDialog,
  SharedInventoryDialog,
  creditOrInitialRow,
  eventNeedsUpdatedLocation,
  locationInUseWarningRow,
  selectBatchRow,
  unitSelectRow,
} from './SharedComponents';
import { deleteButtonLayout } from '@/shared/components/CDDForm/cddElements';

const { column, row, typography } = layoutBuilder;
const SAMPLES_STRING = term('sample', true);

enum SampleDialogVariety {
  SINGLE_USE = 'SINGLE_USE',
  SEARCH_PAGE = 'SEARCH_PAGE',
  ADD = 'ADD',
  EDIT = 'EDIT',
}
type Props = {
  value: EditSample;
  fieldDefinitions: FieldDefinition[];
  eventFieldDefinitions: FieldDefinition[];
  searchDialog?: boolean;
  units: Array<string>;
  batches: Array<Pick<Batch, 'id' | 'name'>>;
} & SharedInventoryDialog;

type FormState = {
  data: {
    initialValue: number;
    originalLocation: LocationValue | null;
  };
  errors: AnyObject;
};
@observer
export default class EditInventorySampleDialog extends React.Component<Props> {
  formState: FormState = {
    data: {
      initialValue: 0,
      originalLocation: null,
    },
    errors: {},
  };

  disableSampleIdField = false;

  constructor(props: Props) {
    super(props);

    makeObservable(this, {
      formState: observable,
      disableSampleIdField: observable,
    });
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    const { value, fieldDefinitions } = this.props;
    const valueChanged = value !== prevProps.value;
    if (value && fieldDefinitions && valueChanged) {
      Object.assign(this.formState.data, {
        initialValue: currentAmountForSample(value) ?? 0,
        originalLocation: locationForEvent(value.inventory_events[0]),
      });

      // Disable the sample id field if the sample already has a value for that field.
      // If it's blank (the user made a field the sample identifier with existing samples), then allow editing.
      this.disableSampleIdField = false;
      this.actionOnChange();
    }
  }

  get firstEvent(): EditInventoryEvent | undefined {
    return this.props.value.inventory_events[0];
  }

  get dialogVariety() {
    const { is_single_use, id } = this.props.value;

    if (is_single_use) {
      return SampleDialogVariety.SINGLE_USE;
    }

    if (this.props.searchDialog) {
      return SampleDialogVariety.SEARCH_PAGE;
    }

    if (id === undefined) {
      return SampleDialogVariety.ADD;
    }

    return SampleDialogVariety.EDIT;
  }

  get dialogTitle(): string {
    switch (this.dialogVariety) {
      case SampleDialogVariety.ADD: {
        return `Create a New ${SAMPLES_STRING}`;
      }
      case SampleDialogVariety.SINGLE_USE: {
        return `Edit Single Use ${SAMPLES_STRING}`;
      }
      default: {
        return `Edit ${SAMPLES_STRING}`;
      }
    }
  }

  actionOnChange = () => {
    const editAction = () => {
      const sampleId = this.props.fieldDefinitions.find(
        (fieldDefinition) => fieldDefinition.is_sample_id,
      )?.id;
      if (sampleId) {
        runInAction(() => {
          this.disableSampleIdField =
            !!this.props.value.inventory_sample_fields?.[sampleId];
        });
      }
    };
    switch (this.dialogVariety) {
      case SampleDialogVariety.EDIT: {
        return editAction();
      }
      case SampleDialogVariety.SEARCH_PAGE: {
        return editAction();
      }
      default: {
        break;
      }
    }
  };

  get excludedNames() {
    switch (this.dialogVariety) {
      case SampleDialogVariety.EDIT: {
        return [];
      }
      default: {
        return ['Current Amount', 'Credit', 'Debit'];
      }
    }
  }

  get showEventFields(): boolean {
    switch (this.dialogVariety) {
      case SampleDialogVariety.EDIT: {
        return false;
      }
      default: {
        return true;
      }
    }
  }

  get disabledCreditDebitField(): boolean {
    switch (this.dialogVariety) {
      case SampleDialogVariety.SINGLE_USE: {
        return !this.props.searchDialog && this.props.value?.current_amount > 0;
      }
      default: {
        return false;
      }
    }
  }

  get needsUpdatedLocation() {
    switch (this.dialogVariety) {
      case SampleDialogVariety.SINGLE_USE: {
        const { value } = this.props;

        return eventNeedsUpdatedLocation(
          value.depleted,
          this.firstEvent,
          this.formState.data.originalLocation?.value,
        );
      }
      default: {
        return false;
      }
    }
  }

  get paperClass() {
    switch (this.dialogVariety) {
      case SampleDialogVariety.EDIT: {
        return 'main-dialog-paper';
      }

      default: {
        return 'main-dialog-paper-wide';
      }
    }
  }

  get unitSelect(): SelectSingleDef | SelectMultipleDef | TypographyDef {
    const { value, units } = this.props;
    return unitSelectRow({
      disabled: value?.inventory_events?.length > 0 && this.isEdit,
      units,
    });
  }

  get layoutRows() {
    const { batches, value } = this.props;
    const { initialValue } = this.formState.data;

    const rows: Array<ElementType> = [
      selectBatchRow(batches, value?.id !== undefined),
    ];
    if (this.showEventFields) {
      rows.push(
        creditOrInitialRow(
          value,
          this.unitSelect,
          initialValue > 0 ? null : 'Amount must be greater than 0',
          this.disabledCreditDebitField,
        ),
      );
    } else {
      rows.push(this.unitSelect);
    }
    return rows;
  }

  get filteredFieldDefinitions(): FieldDefinition[] {
    return this.props.fieldDefinitions.filter(
      (fieldDefinition) => !this.excludedNames.includes(fieldDefinition.name),
    );
  }

  get filteredEventFieldDefinitions(): FieldDefinition[] {
    return this.props.eventFieldDefinitions.filter(
      (fieldDefinition) => !this.excludedNames.includes(fieldDefinition.name),
    );
  }

  get layoutColumns() {
    return column({ spacing: 2 }, [
      ...this.layoutRows,
      ...FieldDefinitionFormUtils.layoutForFieldDefinitions(
        this.filteredFieldDefinitions,
        this.props.value,
        INVENTORY_SAMPLE_FIELDS,
        this.disableSampleIdField,
      ),
    ]);
  }

  get layoutEventColumn() {
    const eventRows: Array<ElementType> = [];

    if (this.needsUpdatedLocation) {
      eventRows.push(locationInUseWarningRow());
    }

    eventRows.push(
      ...FieldDefinitionFormUtils.layoutForFieldDefinitions(
        this.filteredEventFieldDefinitions,
        this.props.value,
        `inventory_events[0].${INVENTORY_EVENT_FIELDS}`,
      ),
    );

    return column({ spacing: 2, className: 'eventFieldsColumn' }, eventRows);
  }

  get layout() {
    const columns: Array<ElementType> = [this.layoutColumns];

    if (this.showEventFields) {
      columns.push(this.layoutEventColumn);
    }

    return row({}, columns);
  }

  handleSubmit = () => {
    if (this.showEventFields) {
      setEventCreditDebit(this.firstEvent, this.formState.data.initialValue);
    }

    this.props.onSubmit();
  };

  handleCancelEdit = () => {
    this.props.onClose();
  };

  get isEdit() {
    return this.props?.value?.id !== undefined;
  }

  get bottomLeftElements() {
    return [deleteButtonLayout({ visible: this.isEdit, onClickButton: this.props.onDelete })];
  }

  get dialogContentProps() {
    return (
      <CDDModalForm
        data={this.props.value ?? {}}
        onCancel={this.handleCancelEdit}
        onOK={this.handleSubmit}
        bottomLeftElements={this.bottomLeftElements}
        terminology={{ OK: 'Save' }}
        layout={this.layout}
        okDisabled={this.needsUpdatedLocation}
        formState={this.formState}
      />
    );
  }

  get renderValueIfPresent() {
    const { value } = this.props;
    if (value) {
      return (
        <InventoryDialog dialogTitle={this.dialogTitle}>
          {this.dialogContentProps}
        </InventoryDialog>
      );
    }
  }

  render() {
    if (!this.props.value) {
      return <></>;
    }
    const { isOpen, onClose } = this.props;
    return (
      <>
        <Dialog
          open={isOpen}
          onClose={onClose}
          className='EditInventorySampleDialog'
          PaperProps={{ className: this.paperClass }}
        >
          {this.renderValueIfPresent}
        </Dialog>
      </>
    );
  }
}
