import React from 'react'
import PropTypes from 'prop-types'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import ConfirmDeleteButton from '@/shared/components/ConfirmDeleteButton.jsx'
import { A } from '@/shared/components/sanitizedTags.js'
import MuiTheme from '@/shared/components/MuiTheme.tsx'
import { Terminology } from 'javascripts/cross_app_utilities.js'

class Delete extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      expanded: false,
    }
  }

  toggleExpand = (e) => {
    this.setState((state, props) => ({ expanded: !state.expanded }))
    return false
  }

  render() {
    if (this.state.expanded) {
      return this.renderConfirmation()
    } else {
      return this.renderDelete()
    }
  }

  renderDelete() {
    return (
      <A className="cancel" href="#" onClick={this.toggleExpand}>
        {this.props.children}
      </A>
    )
  }

  confirmDeleteProps = () => {
    return {
      confirmationMessage: this.props.confirmationMessage || `Yes, delete this ${Terminology.t(this.props.resourceClass)}`,
      buttonProps: {
        href: this.props.buttonHref,
        'data-method': 'put',
        className: 'delete-button',
      },
    }
  }

  renderConfirmation() {
    return (
      <>
        <Dialog open={true} onClose={this.toggleExpand} className='deleteDialog'>
          <DialogTitle>
            Delete {this.props.resourceName}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <span>
                By deleting this {Terminology.t(this.props.resourceClass)} you will also delete ALL the data associated with it.
              </span>
              <br/>
              {this.props.additionalMessage}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <ConfirmDeleteButton {...this.confirmDeleteProps()} />
          </DialogActions>
        </Dialog>
      </>
    )
  }
}

Delete.propTypes = {
  resourceName: PropTypes.string.isRequired,
  resourceClass: PropTypes.string.isRequired,
  buttonHref: PropTypes.string.isRequired,
}

export { Delete }
