import {
  ColumnDef,
} from '@/components';
import EditFieldDefinitionView from '@/FieldDefinitions/shared/EditFieldDefinitionView';
import { EditFieldDefinitionViewProps, FieldDefinitionUtils } from '@/FieldDefinitions/shared/fieldDefinitionUtils';
import React from 'react';
import { FieldDataType, FieldDefinition } from '../types';
import { EditFieldDefinitionRow } from '../shared/EditFieldDefinitionsList';

type Props = EditFieldDefinitionViewProps & {
  allows_multiple_batches_per_structure: boolean;
};

type State = Props;

export class ElnFieldDefinitions extends React.Component<Props, State> {
  processRows = (inRows: Array<FieldDefinition>) => {
    // create a new array of field definitions that is sized one more than the length of this.state.field_definitions
    // and add a placeholder project field definition to the end of the array
    const rows = new Array<EditFieldDefinitionRow>(inRows.length + 1).fill(null);
    inRows.forEach(field_definition => {
      rows[field_definition.display_order] = field_definition;
    });

    const PROJECT_PLACEHOLDER: EditFieldDefinitionRow = {
      id: 'FAKE-ID-PROJECT-PLACEHOLDER',
      name: 'Project',
      data_type_name: FieldDataType.Project,
      required: true,
      pick_list_values: [],
      display_order: 0,
    } as EditFieldDefinitionRow;

    return rows.map((field_definition, index) => {
      return field_definition ?? ({ ...PROJECT_PLACEHOLDER, display_order: index });
    });
  };

  getRowDisplayOptions = (row: FieldDefinition) => {
    return {
      disableDelete: row.data_type_name === FieldDataType.Project,
    };
  };

  renderCellForEditing = (columnId: keyof FieldDefinition, data: FieldDefinition) => {
    if (columnId === 'data_type_name') {
      return (
        <div className="data-type">
          {data.data_type_name}
        </div>
      );
    }
    return null;
  };

  constructor(props: Props) {
    super(props);

    const state = FieldDefinitionUtils.stripEntitiesFromProps(WebMolKit.deepClone(props), 'eln') as Props;
    state.field_definitions = this.processRows(
      state.field_definitions.map(({ field_definition }) => field_definition))
      .map(field_definition => ({ field_definition }));

    this.state = state;
  }

  get columns(): Array<ColumnDef> {
    const columns: Array<ColumnDef & { id: keyof FieldDefinition | 'unique'; }> = [
      {
        label: 'Name',
        id: 'name',
        width: 140,
      },
      {
        label: 'Data Type',
        id: 'data_type_name',
        type: 'pickList',
        width: 120,
      },
      {
        label: 'Must be Unique',
        id: 'unique',
        type: 'checkmark',
        name: 'unique',
        width: 50,
        style: { textAlign: 'center' },
      },
      {
        label: 'Is Required',
        id: 'required',
        type: 'checkmark',
        width: 50,
        style: { textAlign: 'center' },
      },
    ];
    return columns as Array<ColumnDef>;
  }

  get fixedRows(): Array<FieldDefinition> {
    return [];
  }

  render() {
    return <EditFieldDefinitionView
      {...this.state}
      columns={this.columns}
      fixedRows={this.fixedRows}
      dataType={'eln'}
      addFieldLabel={'Add ELN Field'}
      processRows={this.processRows}
      getRowDisplayOptions={this.getRowDisplayOptions}
      renderCellForEditing={this.renderCellForEditing}
    />;
  }
}
