import React, { useCallback } from 'react';

import { StandardDialogActions } from '@/components/Buttons/DialogButtons';
import { LocationNode, Sample } from '@/Samples/types';
import { getRootStore } from '@/stores/rootStore';
import { StringOrNumber } from '@/types';
import { Dialog, DialogContent, DialogTitle, Grid } from '@mui/material';
import { Box } from '@mui/system';
import { reaction } from 'mobx';
import { observer } from 'mobx-react';
import { LocationBoxPicker } from '../location/LocationBoxPicker';
import { LocationUtils } from '../location/locationUtils';
import { EditLocationTreeView } from './EditLocationTreeView';
import { LocationEditorPanel } from './LocationEditorPanel';

type State = {
  selectedTreeNode?: LocationNode;
  expanded?: Array<string>;
  loading?: boolean;
  samplesInBox?: Array<Sample>;
};

@observer
export class EditLocationsDialog extends React.Component<unknown, State> {
  get store() {
    return getRootStore().locationStore;
  }

  containerRef: React.RefObject<HTMLDivElement> = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      selectedTreeNode: null,
    };

    reaction(() => ({ locations: this.store.currentlyEditingLocations, inited: this.store.inited }),
      ({ locations, inited }) => {
        if (!locations || !inited) {
          this.setState({
            expanded: null,
          });
        }
      });
  }

  fetchBoxContentsOnChange() {
    const node = this.state.selectedTreeNode;
    if (node?.position_limit) {
      if (node.id >= 0) {
        // it's a box, so fetch the samples
        this.setState({ loading: true, samplesInBox: [] });
        getRootStore().locationStore.fetchBoxContents(node.id).then(samples => {
          this.setState({ samplesInBox: samples, loading: false });
        });
      } else {
        this.setState({ samplesInBox: [], loading: false });
      }
    }
  }

  componentDidMount() {
    this.fetchBoxContentsOnChange();
  }

  handleChangeNodes = (nodes: Array<LocationNode>) => {
    this.store.currentlyEditingLocations = nodes;
  };

  handleNodeSelectEvent = (_event: React.ChangeEvent, value: string) => {
    const selectedTreeNode = LocationUtils.findSelectedLocationNode(value, this.store.currentlyEditingLocations);
    this.setState({
      selectedTreeNode,
    }, this.fetchBoxContentsOnChange);
  };

  handleSetSelectedNode = (id: StringOrNumber) => {
    const { currentlyEditingLocations } = this.store;
    const selectedTreeNode = LocationUtils.findSelectedLocationNode(id, currentlyEditingLocations);
    if (!selectedTreeNode) {
      return;
    }

    let expanded = this.state.expanded;
    if (!expanded?.length) {
      expanded = LocationUtils.getAllExpandedNodes(currentlyEditingLocations);
    }

    const expandedSet = new Set(expanded);
    expandedSet.add('' + selectedTreeNode.id);
    let parent = selectedTreeNode.parent;
    while (parent) {
      expandedSet.add('' + parent.id);
      parent = parent.parent;
    }
    this.setState({
      selectedTreeNode,
      expanded: Array.from(expandedSet),
    }, () => {
      this.fetchBoxContentsOnChange();
    });
  };

  handleNodeToggle = (event: React.ChangeEvent, nodeIds: Array<string>) => {
    if (event.type === 'click' &&
      ((!!event.target.closest('.tree-item-icons')) ||
        (event.target?.tagName !== 'path' && event.target?.tagName !== 'svg'))) {
      event.stopPropagation();
      event.preventDefault();
      return;
    }
    this.setState({
      expanded: nodeIds,
    });
  };

  handleSetCurrentlyEditingLocations = (locations: Array<LocationNode>,
    droppedNodeId: StringOrNumber,
    expanded: Array<string>) => {
    this.store.setCurrentlyEditingLocations(locations, droppedNodeId);
    if (droppedNodeId) {
      const droppedNode = LocationUtils.findSelectedLocationNode(droppedNodeId, locations);
      if (droppedNode) {
        this.handleSetSelectedNode(droppedNodeId);
      } else {
        console.log('could not find node ', droppedNodeId);
      }
      if (droppedNode && expanded) {
        this.setState({
          expanded,
        });
      }
    }
  };

  handleCreateBox = (node: LocationNode, organized: boolean) => {
    const selectedTreeNode = LocationUtils.createNew(node, true, organized);
    this.handleSetSelectedNode(selectedTreeNode.id);
  };

  render() {
    const { handleCancelEditLocations, handleSubmitEditLocations, currentlyEditingLocations, inited } = this.store;
    const { selectedTreeNode } = this.state;
    let { expanded } = this.state;

    if (!currentlyEditingLocations || !inited) {
      return null;
    }

    if (currentlyEditingLocations && inited && !expanded) {
      expanded = LocationUtils.getAllExpandedNodes(currentlyEditingLocations);
    }

    return <>
      <Dialog
        className='EditLocationsDialog'
        open={!!currentlyEditingLocations}
        fullWidth
        maxWidth="lg">

        <DialogTitle className='muiDialog-title'>Edit Locations</DialogTitle>

        <DialogContent className='contents'>
          <Grid container>
            {/* First Column: 5/12 width, overflow auto */}
            <Grid item className='left-column' xs={5}>
              <EditLocationTreeView
                selectedTreeNode={selectedTreeNode}
                nodes={currentlyEditingLocations}
                selected={'' + selectedTreeNode?.id}
                expanded={expanded ?? []}
                onNodeToggle={this.handleNodeToggle}
                onNodeSelect={this.handleNodeSelectEvent}
                onChangeOrder={this.handleSetCurrentlyEditingLocations}
                onSetSelectedNode={this.handleSetSelectedNode}
              />
            </Grid>

            {/* Second Column: 7/12 width, filling available height */}
            <Grid item className='right-column' xs={7} >
              {/* First Row: Sized to fit its contents */}
              <Grid item>
                <LocationEditorPanel selectedTreeNode={selectedTreeNode} onCreateBox={this.handleCreateBox} />
              </Grid>

              {/* Second Row: Fills the remaining vertical space */}
              <Grid item className="LocationPicker">
                <LocationBoxPicker
                  box={this.state.selectedTreeNode}
                  loading={this.state.loading}
                  samples={this.state.samplesInBox}
                />
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>

        <Box className='full-width'>
          <StandardDialogActions
            cancelProps={{
              onClick: handleCancelEditLocations,
            }}
            defaultButtonProps={{
              onClick: handleSubmitEditLocations,
              label: 'Save',
            }} />
        </Box>
      </Dialog>
    </>;
  }
}
