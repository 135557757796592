import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  Stack,
  Divider,
} from '@mui/material';
import { SimilarityResults } from './SimilarityResults';
import {
  DLSimilarityResponse,
  DLError,
  SimilarStructure,
  SimilarityMetadata,
  SimilaritySettings,
  ReferenceStructure,
} from './SimilarityResultsTypes';
import {
  DownloadSearchButton,
  GroupButton,
  SimilarityInfoButton,
  SimilaritySettingsButton,
  CollectionFilters,
  SimilarityLaunchVisualizationButton,
} from './SimilarityResultsControls';
import { PropertyCalculator } from '../Properties/calculator';
import { usePersistentState } from '../persistence';
import { adjustFilters, allCollections, getCollections } from './Collections';

type SimilarityResultsDialogProps = {
  open: boolean;
  fetchDLSimilarity: (
    smiles: string,
    count: number,
  ) => Promise<DLSimilarityResponse | DLError>;
  name: string;
  smiles: string;
  banExternalLinks: boolean;
  skipExternalLinkWarning: boolean;
  onClose?: () => void;
};

type LoadingFailure = {
  failed: boolean;
  message: string;
}

const SimilarityResultsDialog = (props: SimilarityResultsDialogProps) => {
  const { open, smiles, name } = props;
  const [similarStructures, setSimilarStructures] = useState<
    Array<SimilarStructure>
  >([]);
  const [reference, setReference] = useState<ReferenceStructure>({ name, structure: '', scaffold: '', properties: {} });
  const [loading, setLoading] = useState(false);
  const [loadingFailed, setLoadingFailed] = useState<LoadingFailure>({ failed: false, message: '' });
  const [grouped, setGrouped] = useState(false);
  const [settings, setSettings] = usePersistentState<SimilaritySettings>(
    'deep-learning-similarity',
    {
      properties: [],
      collections: allCollections,
      filters: allCollections,
      showCollectionInformation: false,
      count: 100,
    });
  const [metadata, setMetadata] = useState<Array<SimilarityMetadata>>([]);

  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>,
  ): void => {
    if (event.key === 'Escape') {
      props.onClose();
    }
  };

  useEffect(() => {
    if (!open) {
      return;
    }
    setLoading(true);
    props.fetchDLSimilarity(smiles, settings.count).then((response) => {
      setLoading(false);
      if ('error' in response || response.status !== 'OK') {
        response = response as DLError;
        setLoadingFailed({ failed: true, message: response.message });
        return;
      }

      if ('result' in response) {
        const result = response.result;
        setMetadata(result.metadata);
        const calculator = new PropertyCalculator();
        calculator.is_ready().then(() => {
          setReference({
            name,
            structure: result.query,
            scaffold: result.query_scaffold,
            properties: calculator.predict(result.query),
          });
          result.hits.forEach((hit) => {
            hit.properties = calculator.predict(hit.structure);
          });
        });

        setSimilarStructures(result.hits);
        const collections = getCollections(result.hits);
        const filters = adjustFilters(collections, settings.collections, settings.filters);
        setSettings({ ...settings, collections: [...collections].sort(), filters });
        setLoadingFailed({ failed: false, message: '' });
        return;
      }
      setLoadingFailed({ failed: true, message: 'Please contact support.' });
    });
  }, [smiles, open, settings.count]);

  const changeSettings = (settings: SimilaritySettings) => {
    setSettings(settings);
  };

  const content = () => {
    if (loadingFailed.failed) {
      return renderErrorFallback(loadingFailed.message);
    }
    return (
      <SimilarityResults
        loading={loading}
        grouped={grouped}
        similarStructures={similarStructures}
        banExternalLinks={props.banExternalLinks}
        reference={reference}
        settings={settings}
      />
    );
  };

  return (
    <Dialog
      className='SimilarityResultsDialog'
      open={open}
      fullWidth
      maxWidth='xl'
      PaperProps={{ sx: { height: 'calc(100% - 64px)' } }}
      onKeyDown={handleKeyDown}
    >
      <SimilarityResultsDialogTitle
        similarStructures={similarStructures}
        reference={reference}
        loading={loading}
        settings={settings}
        grouped={grouped}
        setGrouped={setGrouped}
        onChangeSettings={changeSettings}
        similaritySettings={settings}
      />
      <DialogContent>{content()}</DialogContent>
      <Stack direction='row' justifyContent='space-between' alignItems='end'>
        <Stack
          direction='row'
          justifyContent='flex-start'
          spacing={1}
          divider={<Divider orientation='vertical' flexItem />}
          sx={{ padding: '10px' }}
        >
          <SimilarityFooter
            metadata={metadata}
            skipExternalLinkWarning={props.skipExternalLinkWarning}
            banExternalLinks={props.banExternalLinks}
          />
        </Stack>
        <DialogActions className='bottom-modal-row'>
          <Button
            variant='text'
            onClick={props.onClose}
            color='primary'
            aria-label='close dialog'
          >
            OK
          </Button>
        </DialogActions>
      </Stack>
    </Dialog>
  );
};

const renderErrorFallback = (message: string) => {
  return (
    <Box display='flex' height='100%'>
      <Box
        className='error-boundary-msg'
        m='auto'
        paddingLeft='200px'
        paddingRight='200px'
        dangerouslySetInnerHTML={{ __html: message }}
      />
    </Box>
  );
};

const SimilarityFooter = (props: {
  metadata: Array<SimilarityMetadata>,
  skipExternalLinkWarning: boolean,
  banExternalLinks: boolean
}) => {
  const footer = [];
  if (props.metadata.length > 0) {
    footer.push(
      <Box style={{ fontWeight: 'light', color: 'grey' }} key='releases'>
        {'Releases: '}
        {props.metadata
          .map<React.ReactNode>((m, k) => <span key={k}>{m.collection} {m.release}</span>)
          .reduce((prev, curr) => [prev, ', ', curr])}
      </Box>,
    );
  }
  if (!props.skipExternalLinkWarning && !props.banExternalLinks) {
    footer.push(
      <Box style={{ fontWeight: 'light', color: 'grey' }} key='external'>
        Warning: links open an external website
      </Box>,
    );
  }
  return <> {footer} </>;
};

type SimilarityResultsDialogTitleProps = {
  similarStructures: Array<SimilarStructure>;
  reference: ReferenceStructure;
  loading: boolean;
  settings: SimilaritySettings;
  onChangeSettings: (settings: SimilaritySettings) => void;
  grouped: boolean;
  setGrouped: (grouped: boolean) => void;
  similaritySettings: SimilaritySettings;
};

const SimilarityResultsDialogTitle = (props: SimilarityResultsDialogTitleProps) => {
  const {
    similarStructures, loading, reference,
    settings, onChangeSettings,
    grouped, setGrouped, similaritySettings,
  } = props;
  const disabled = loading || !similarStructures.length;
  return (
    <DialogTitle className='muiDialog-title'>
      <Box display='flex' justifyContent='space-between'>
        <Stack
          direction='row'
          spacing={2}
          divider={<Divider orientation='vertical' flexItem />}
        >
          <span>Similar Structures</span>
          <CollectionFilters
            disabled={disabled}
            settings={settings}
            onChange={onChangeSettings}
          />
          <Stack direction='row'>
            <GroupButton
              disabled={disabled}
              onClick={() => setGrouped(!grouped)}
            />
            <SimilarityLaunchVisualizationButton
              disabled={disabled}
              reference={reference}
              similarStructures={similarStructures}
              displayProperties={settings.properties}
              similaritySettings={similaritySettings}
            />
            <DownloadSearchButton
              disabled={disabled}
              reference={reference}
              similarStructures={similarStructures}
              displayProperties={settings.properties}
              similaritySettings={similaritySettings}
            />
            <SimilaritySettingsButton
              disabled={disabled}
              settings={settings}
              onChange={onChangeSettings}
            />
          </Stack>
        </Stack>
        <SimilarityInfoButton />
      </Box>
    </DialogTitle>
  );
};

export { SimilarityResultsDialog, SimilarityResultsDialogProps };
