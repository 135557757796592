import React from 'react';
import { Box, List, ListItem, ListSubheader, Stack, Tooltip } from '@mui/material';
import { HazardClassIcons } from './HazardClassIcons';
import { get_hcode_data, get_pcode_data, group_pcodes, HCode, PCode, PrecautionaryType, SafetyData } from '../data';

export const PrecautionaryCodes = (props: {
  safetyData: SafetyData;
  add_details?: boolean;
  group?: boolean; // ignored if add_details is false
  add_tooltip?: boolean; // ignored if add_details is true
}) => {
  const { add_tooltip = true, add_details = false, group = true } = props;
  const pcodes = get_pcode_data(props.safetyData.pcodes);
  if (!add_details) {
    return (
      <Stack direction='row' divider={<span>,&nbsp;</span>}>
        {
          pcodes.map((pcode) => (
            <PrecautionaryCode key={pcode.code} pcode={pcode} add_tooltip={add_tooltip} />
          ))
        }
      </Stack >
    );
  }

  const grouped_pcodes = group ? group_pcodes(pcodes) : { '': pcodes };
  return (
    <List dense={true} disablePadding={true}>
      {Object.keys(grouped_pcodes).map((group, idx) => (
        <Box key={idx}>
          {group && <ListSubheader sx={{ lineHeight: 'normal', 'mt': '1em' }} key={idx} disableGutters={true}>
            {PrecautionaryType[group].toUpperCase()}
          </ListSubheader>}
          {grouped_pcodes[group].map((pcode, idx2) => (
            <ListItem key={`${idx}-${idx2}`} disablePadding={true}>
              {pcode.code}: {pcode.details}
            </ListItem>
          ))}
        </Box>
      ))}
    </List>
  );
};

const PrecautionaryCode = (props: {
  pcode: PCode;
  add_tooltip: boolean;
}) => {
  const { pcode } = props;
  if (!pcode) {
    return null;
  }

  const content = <span>{pcode.code}</span>;
  if (props.add_tooltip) {
    return <Tooltip title={pcode.details}>{content}</Tooltip>;
  }
  return content;
};

export const HazardCodes = (props: {
  safetyData: SafetyData;
  header?: string;
  add_tooltip?: boolean;
  add_details?: boolean;
  show_icon?: boolean;
  display_assume_toxic: boolean;
}) => {
  const { safetyData, add_tooltip = true, add_details = false } = props;

  if (safetyData.no_hazard_codes) {
    if (props.display_assume_toxic) {
      return <RenderSpecialCase message="Assume toxic" header={props.header} />;
    }
    return null;
  }
  if (safetyData.not_classified) {
    return <RenderSpecialCase message="Not classified" header={props.header} />;
  }
  const hcodes = get_hcode_data(safetyData.hcodes);
  if (!add_details) {
    return (
      <Stack direction='row' divider={<span>,&nbsp;</span>}>
        {
          hcodes.map((hcode) => (
            <HazardCode
              key={hcode.hazard_code}
              hcode={hcode}
              add_tooltip={add_tooltip}
              show_icon={props.show_icon}
              display_assume_toxic={props.display_assume_toxic}
            />
          ))
        }
      </Stack >
    );
  }
  return (
    <List dense={true} disablePadding={true}>
      {props.header && <ListSubheader sx={{ lineHeight: 'normal', 'mt': '1em' }} key='header' disableGutters={true}>
        {props.header.toUpperCase()}
      </ListSubheader>}
      {hcodes.map((hcode, idx) => (
        <ListItem key={idx} disablePadding={true}>
          {hcode.hazard_code}: {hcode.hazard_statement}
        </ListItem>
      ))}
    </List>
  );
};

const RenderSpecialCase = (props: {
  header?: string;
  message: string;
}) => {
  return (
    <List dense={true} disablePadding={true}>
      {props.header && <ListSubheader sx={{ lineHeight: 'normal', 'mt': '1em' }} key='header' disableGutters={true}>
        {props.header.toUpperCase()}
      </ListSubheader>}
      <ListItem disablePadding={true}>
        {props.message}
      </ListItem>
    </List>
  );
};

const HazardCode = (props: {
  hcode: HCode;
  add_tooltip: boolean;
  show_icon: boolean;
  display_assume_toxic: boolean;
}) => {
  const { hcode, add_tooltip, show_icon, display_assume_toxic } = props;

  const content = <span>{hcode.hazard_code}</span>;
  if (!add_tooltip) {
    return content;
  }
  let tooltip = <span>{hcode.hazard_statement}</span>;
  if (show_icon) {
    tooltip = (
      <Stack direction='row' alignItems='center' spacing={1}>
        <HazardClassIcons
          safetyData={{ hclasses: hcode.pictogram.split(',') } as SafetyData}
          display_assume_toxic={display_assume_toxic}
          size={20}
          sx={{ 'background-color': 'white' }} />
        {tooltip}
      </Stack>
    );
  }

  return (
    <Tooltip title={tooltip}>
      {content}
    </Tooltip>
  );
};
