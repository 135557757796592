import React, { Component } from 'react';
import { Autocomplete, TextField, CircularProgress } from '@mui/material';
import axios from 'axios';

export interface LabelAndValue {
  label: string;
  value: string | number;
}

interface Props {
  setValue?: (option: LabelAndValue) => void;
  value?: LabelAndValue;
  defaultValue?: LabelAndValue;
  disabled?: boolean;
  name?: string;
  NoSaltFreeBaseOrAcid?: string;
}

interface State {
  options: LabelAndValue[];
  loading: boolean;
}

export const extractLabelAndValue = (option: LabelAndValue | string | null) => {
  if (!option) {
    return { label: '', value: '' };
  }
  return (typeof option === 'string') ? { label: option, value: option } : option;
};

export class SaltPicker extends Component<Props, State> {
  state: State = {
    options: [],
    loading: false,
  };

  handleInputChange = (event: React.ChangeEvent, value: string) => {
    if (value.length > 0) {
      this.setState({ loading: true });
      this.getSaltPromise(value).then((options) => {
        this.setState({ options, loading: false });
      });
    } else {
      this.setState({ options: [], loading: false });
      if (this.props.value) {
        this.props.setValue?.(null);
      }
    }
  };

  getSaltPromise = (input: string) =>
    axios
      .get<Array<LabelAndValue>>('/support/salts/autocomplete_for_salt', { params: { term: input } })
      .then(response => response.data);

  setOption = (event: React.SyntheticEvent, option: { label: string, value: string }) => {
    if (this.props.setValue) {
      this.props.setValue(option);
    }
  };

  getOptionLabel = (option: LabelAndValue | string) => {
    const { label, value } = extractLabelAndValue(option);
    return (value ?? label).toString();
  };

  render() {
    const { disabled, NoSaltFreeBaseOrAcid, name } = this.props;
    const { loading } = this.state;
    // this seems dorky, but without doing too much extra surgery, just have value be null if it's the
    // same as the placeholder text
    const value = (this.props.value?.label === NoSaltFreeBaseOrAcid) ? null : this.props.value ?? null;

    // use the options from the state if they exist, otherwise use the value from the props so we have a matching option
    const options = this.state.options?.length
      ? this.state.options
      : [this.props.value].filter(v => !!v);

    return (
      <>
        <Autocomplete
          className={this.props.disabled ? 'Autocomplete-disabled' : ''}
          value={value}
          filterOptions={(options) => options}
          onChange={this.setOption}
          onInputChange={this.handleInputChange}
          options={options}
          getOptionLabel={this.getOptionLabel}
          isOptionEqualToValue={(option, value) => {
            return extractLabelAndValue(option).value == extractLabelAndValue(value).value;
          }}
          renderOption={(props, option) => (
            <li {...props}>
              {(typeof option === 'string')
                ? option
                : <span dangerouslySetInnerHTML={{ __html: option.label }} />
              }
            </li>)
          }
          loading={loading}
          disableClearable={!this.props.setValue}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={NoSaltFreeBaseOrAcid}
              variant="outlined"
              fullWidth
              aria-label='Salt Picker'
              InputProps={{
                ...params.InputProps,
                name,
                inputProps: {
                  ...params.inputProps,
                  'aria-label': 'Salt',
                },
                endAdornment: (
                  <>
                    <CircularProgress color="inherit" size={20} sx={{ opacity: loading ? 1 : 0 }} />
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          disabled={disabled}
        />
        <span className="salt-name" dangerouslySetInnerHTML={{ __html: extractLabelAndValue(value).label }} />
      </>
    );
  }
}
