import {
  FormControl,
  MenuItem,
  Select,
} from '@mui/material'
import React from 'react'

export default class FilterFieldSelect extends React.Component {
  handleChange = (event) => {
    this.props.handleFieldChange(event.target.value)
  }

  renderItems = (fieldDefinitions) => {
    const { selectedFieldValues } = this.props
    return fieldDefinitions
      .filter(fd => fd.selectValue != 'Structure')
      .map((fieldDefinition, index) => (
        <MenuItem
          className='search-bar__filters__field-name'
          style={{ margin: 0 }}
          key={fieldDefinition.id + fieldDefinition.name}
          value={fieldDefinition.selectValue}
          button
          disabled={selectedFieldValues.includes(fieldDefinition.selectValue)}
          divider={index == fieldDefinitions.size - 1}
        >
          {fieldDefinition.name}
        </MenuItem>
      ))
  }

  renderCategorizedMenuItems = () => {
    const { elnFieldDefinitions } = this.props

    return elnFieldDefinitions.byVault.map((fieldDefinitions, vaultName) => (
      [
        <MenuItem
          key={vaultName + '__divider_label'}
          disabled
          className='search-bar__filters__top-level-category'
        >
          {vaultName}
        </MenuItem>,
        ...this.renderItems(fieldDefinitions).toArray(),
      ]
    )).valueSeq().toArray()
  }

  render() {
    const { selectedValues, className, multiple } = this.props

    return (
      <FormControl className='filter-field-select'>
        <Select
          className={className}
          value={selectedValues}
          onChange={this.handleChange}
          multiple={multiple}
        >
          {this.renderCategorizedMenuItems()}
        </Select>
      </FormControl>
    )
  }
}
