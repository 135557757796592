/* eslint-disable multiline-ternary, no-nested-ternary */

import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Checkbox,
} from '@mui/material';
import React from 'react';
import { A } from '@/shared/components/sanitizedTags.js';
import '../Annotator/Templates/Template.sass';

type Props = {
  isOpen: boolean,
  fieldTitle: string,
  selectedValues: string[],
  observedValues: string[],
  unionOfValues: string[],
  onDialogCancel: () => void,
  onDialogSubmit: (selectedValues: string[]) => void,
};

type State = {
  redrawWatermark: number;
};

export class AdvancedSearchPickListDialog extends React.Component<Props, State> {
  private selectedValues: string[] = null;

  constructor(props) {
    super(props);

    this.state = {
      redrawWatermark: 0,
    };
  }

  get dialogTitle() {
    return `Pick List: ${this.props.fieldTitle}`;
  }

  public render() {
    const { isOpen } = this.props;

    if (!isOpen) {
      this.selectedValues = null;
    } else {
      if (!this.selectedValues) this.selectedValues = (this.props.selectedValues || []).slice(0);
    }

    return (
      <Dialog
        open={this.props.isOpen}
        onClose={this.handleClose}
        className='EditTeamDialog edit-account-object-dialog'
        PaperProps={{ className: 'main-dialog-paper' }}
      >
        {isOpen && this.renderContent()}
      </Dialog>
    );
  }

  private renderContent(): JSX.Element {
    const selectedValues = this.selectedValues || [];
    const observedValues = this.props.observedValues || [];
    const unionOfValues = this.props.unionOfValues || [];

    const setSelectedValue = (value: string, isChecked: boolean) => {
      if (isChecked) {
        if (this.selectedValues.includes(value)) return;
        this.selectedValues.push(value);
      } else {
        const idx = this.selectedValues.indexOf(value);
        if (idx < 0) return;
        this.selectedValues.splice(idx, 1);
      }
      this.props.onDialogSubmit(this.selectedValues);
      this.setState({ redrawWatermark: this.state.redrawWatermark + 1 });
    };

    const renderValue = (value: string, idx: number): JSX.Element => {
      const isChecked = selectedValues.includes(value);
      const isObserved = observedValues.includes(value);
      return (
        <div key={`picklist-line-${idx}`}>
          <label className={isObserved ? 'AdvancedSearch-checklabel' : 'AdvancedSearch-checkdisabled'}>
            <Checkbox
              checked={isChecked}
              disabled={!isObserved}
              onChange={(_, checked) => setSelectedValue(value, checked)}
              />
            {value}
          </label>
        </div>
      );
    };

    return (
      <>
        <DialogTitle className='muiDialog-title'>
          {this.dialogTitle}
        </DialogTitle>
        <DialogContent className="OntologyTemplate-toppadding">
          {unionOfValues.map((value, idx) => renderValue(value, idx))}
        </DialogContent>
        <DialogActions
          className="project__action TermPicker-actions"
          >
          <div/>
          <div className="orcancel">
            <A
              className="cancel"
              onClick={this.handleClose}
              href="#"
              style={{ color: 'black', margin: '0px 20px 0px 0px' }}
            >
              Close
            </A>
          </div>
        </DialogActions>
      </>
    );
  }

  private handleClose = (): void => {
    this.props.onDialogCancel();
  };
}
