/* eslint-disable multiline-ternary, no-nested-ternary */

import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Tooltip,
  Typography,
} from '@mui/material';
import React from 'react';
import { observer } from 'mobx-react';
import { A, Img } from '@/shared/components/sanitizedTags.js';
import { ProtocolsStore } from './protocolsStore';
import { PresentableAssignment, PresentableField, determinePresentableAssignments, determinePresentableFields } from './advancedSearchHelper';
import '../Annotator/Templates/Template.sass';
import branchDotIcon from 'ASSETS/images/branch_dot_icon.svg';
import { Protocol } from './types';
import { FieldDataType } from '@/FieldDefinitions/types';

type Props = {
  store: ProtocolsStore,
  isOpen: boolean,
  layerIndex: number;
  eligibleProtocols: Protocol[];
  onDialogClose: () => void,
};

@observer
export class AdvancedSearchFieldDialog extends React.Component<Props> {
  get dialogTitle() {
    return 'Select Field';
  }

  public render() {
    if (!this.props.isOpen) return null;

    return (
      <Dialog
        open={this.props.isOpen}
        // onClose={this.handleClose}
        className='EditTeamDialog edit-account-object-dialog'
        PaperProps={{ className: 'main-dialog-paper' }}
      >
        {this.renderContent()}
      </Dialog>
    );
  }

  private renderContent(): JSX.Element {
    const { store } = this.props;

    const presentFields = determinePresentableFields(store.fieldDefinitions, store.filterLayers, this.props.eligibleProtocols);
    const schema = store.currentTemplateSchema();
    const presentAssignments = determinePresentableAssignments(schema, store.filterLayers, this.props.eligibleProtocols);

    const composeField = (present: PresentableField, idx: number) => {
      const classList: string[] = [];
      if (present.observedValues.size == 0) {
        classList.push('TermPicker-inactive');
      } else if (present.usedInLayer >= 0) {
        classList.push('TermPicker-selected');
      } else {
        classList.push(...['AdvancedSearch-field', 'OntologyTemplate-clickable']);
      }
      const clickFunc = present.observedValues.size > 0 && present.usedInLayer != idx ? () => this.handleSelectField(present) : null;
      const NAME_MAP = {
        [FieldDataType.Text]: 'text',
        [FieldDataType.LongText]: 'long text',
        [FieldDataType.File]: 'file',
        [FieldDataType.BatchLink]: 'batch link',
        [FieldDataType.Number]: 'number',
        [FieldDataType.PickList]: 'pick list',
      };
      const strDataType = NAME_MAP[present.defn.data_type_name];

      return (
        <div
          key={`key-search-field-select-field-${idx}`}
          className="TermPicker-termline"
          style={{ marginBottom: '0.4em' }}
          >
            <Img className="TermPicker-icon" src={branchDotIcon}/>
            <div
              className={classList.join(' ')}
              onClick={clickFunc}
              >
              {present.defn.name}
            </div>
            <div className="TermPicker-inactive">({strDataType})</div>
        </div>
      );
    };

    const composeAssignment = (present: PresentableAssignment, idx: number) => {
      const { assn } = present;
      const inset = `${present.groupNest.length * 1.5}em`;
      const tooltip = (
        <Typography component="span" className="ProtocolAnnotator-tooltip">
          <div><b>{assn.propURI}</b></div>
          {assn.descr ? (<div>{assn.descr}</div>) : null}
          {!present.isPresent ? (<div style={{ paddingTop: '0.5em' }}>Is not used in currently available set of protocols.</div>) : null}
        </Typography>
      );

      const classList: string[] = [];
      if (!present.isPresent) {
        classList.push('TermPicker-inactive');
      } else if (present.usedInLayer >= 0) {
        classList.push('TermPicker-selected');
      } else {
        classList.push(...['AdvancedSearch-field', 'OntologyTemplate-clickable']);
      }
      const clickFunc = present.isPresent && present.usedInLayer != idx ? () => this.handleSelectAssignment(present) : null;

      return (
        <div
          key={`key-search-field-select-assn-${idx}`}
          className="TermPicker-termline"
          style={{ paddingLeft: inset, marginBottom: '0.4em' }}
          >
            <Img className="TermPicker-icon" src={branchDotIcon}/>
            <Tooltip
              title={tooltip}
              arrow
              placement="right"
              >
              <div
                className={classList.join(' ')}
                onClick={clickFunc}
                >
                {assn.name}
              </div>
            </Tooltip>
        </div>
      );
    };

    const fragProtoFields = presentFields.length > 0 ? (
      <div style={{ marginBottom: '1em' }}>
        <div className="AdvancedSearch-minortitle">Protocol Fields</div>
        {presentFields.map((present, idx) => composeField(present, idx))}
      </div>
    ) : null;

    const fragAssignments = presentAssignments.length > 0 ? (
      <div style={{ marginBottom: '1em' }}>
        <div className="AdvancedSearch-minortitle">
          Ontology: {store.currentTemplateSchema().template.root.name}
        </div>
        {presentAssignments.map((present, idx) => composeAssignment(present, idx))}
      </div>
    ) : null;

    return (
      <>
        <DialogTitle className='muiDialog-title'>
          {this.dialogTitle}
        </DialogTitle>
        <DialogContent className="OntologyTemplate-toppadding">
          {fragProtoFields}
          {fragAssignments}
        </DialogContent>
        <DialogActions className="project__action TermPicker-actions">
          <div/>
          <div className="orcancel">
            <A
              className="cancel"
              onClick={this.handleClose}
              href="#"
              style={{ color: 'black', margin: '0px 20px 0px 0px' }}
            >
              Close
            </A>
          </div>
        </DialogActions>
      </>
    );
  }

  private handleClose = (): void => {
    this.setState({ searchHits: null });
    this.props.onDialogClose();
  };

  private handleSelectField(present: PresentableField): void {
    const { dataType, defn } = present;
    const observedValues = Array.from(present.observedValues).sort();
    const unionOfValues = Array.from(present.unionOfValues).sort();
    const { store, layerIndex, onDialogClose } = this.props;
    store.setFilterLayerProtocolField(layerIndex, dataType, defn, observedValues, unionOfValues);
    onDialogClose();
  }

  private handleSelectAssignment(present: PresentableAssignment): void {
    const { assn, groupNest } = present;
    const { store, layerIndex, onDialogClose } = this.props;
    store.setFilterLayerOntologyTerm(layerIndex, assn.propURI, groupNest);
    onDialogClose();
  }
}
