import { FieldDataType, FieldDefinition } from '@/FieldDefinitions/types';
import { FieldDefinitionFormUtils } from '@/Samples/components/fieldDefinitionFormUtils';
import {
  currentAmountForSample,
  setEventCreditDebit,
} from '@/Samples/stores/sampleDataStore';
import { EditInventoryEvent, EditSample, LocationValue } from '@/Samples/types';
import { CDDModalForm } from '@/shared/components/CDDForm/CDDForm';
import { deleteButtonLayout } from '@/shared/components/CDDForm/cddElements';
import { layoutBuilder } from '@/shared/components/DDForm/layoutBuilder';
import { RowColumnDef } from '@/shared/components/DDForm/types/rowColumnDef';
import { AnyObject } from '@/types';
import { Dialog } from '@mui/material';
import { computed, makeObservable, observable, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import {
  INVENTORY_EVENT_FIELDS,
  InventoryDialog,
  SharedInventoryDialog,
  creditDebitToggleRow,
  eventNeedsUpdatedLocation,
  locationInUseWarningRow,
} from './SharedComponents';

type Props = {
  value: EditInventoryEvent;
  fieldDefinitions: Array<FieldDefinition>;
  unit: string;
  sample: EditSample;
} & SharedInventoryDialog;

type FormState = {
  errors: AnyObject;
  data: {
    originalLocation: LocationValue | null;
    creditOrDebit: 'credit' | 'debit';
    creditOrDebitValue: number;
  };
};
const { column } = layoutBuilder;

@observer
export default class EditInventoryEventDialog extends React.Component<Props> {
  get dialogClassName() {
    return 'EditInventoryEventDialog';
  }

  get paperClass() {
    return 'main-dialog-paper';
  }

  formState: FormState = {
    data: {
      creditOrDebit: 'debit',
      creditOrDebitValue: 0,
      originalLocation: null,
    },
    errors: {},
  };

  constructor(props: Props) {
    super(props);

    makeObservable(this, {
      formState: observable,
      needsUpdatedLocation: computed,
    });
  }

  get layoutFieldDefinitionError() {
    return (fieldDefinition: FieldDefinition) => {
      if (
        fieldDefinition.data_type_name === FieldDataType.Location &&
        this.needsUpdatedLocation
      ) {
        return ''; // 'Please select a new location to restore this sample';
      }
    };
  }

  get layoutColumns() {
    return column({ spacing: 2 }, [
      ...this.layoutRows,
      ...FieldDefinitionFormUtils.layoutForFieldDefinitions(
        this.filteredFieldDefinitions,
        this.props.value,
        INVENTORY_EVENT_FIELDS,
        false,
        this.layoutFieldDefinitionError,
      ),
    ]);
  }

  get filteredFieldDefinitions(): FieldDefinition[] {
    return this.props.fieldDefinitions.filter(
      (fieldDefinition) => !this.excludedNames.includes(fieldDefinition.name),
    );
  }

  get excludedNames() {
    return ['Current Amount', 'Credit', 'Debit'];
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    const propValueChanged = this.props.value !== prevProps.value;

    if (propValueChanged && this.props.value?.inventory_event_fields) {
      const { inventory_event_fields_name_keyed } = this.props.value;
      const Credit = inventory_event_fields_name_keyed?.Credit ?? 0;
      const Debit = inventory_event_fields_name_keyed?.Debit ?? 0;

      Object.assign(this.formState.data, {
        creditOrDebit: Credit > 0 ? 'credit' : 'debit',
        creditOrDebitValue: Credit || Debit || 0,
        originalLocation: inventory_event_fields_name_keyed.Location ?? null,
      });
    }
  }

  get needsUpdatedLocation() {
    const { sample, value } = this.props;
    return eventNeedsUpdatedLocation(
      sample.depleted,
      value,
      this.formState.data.originalLocation?.value,
    );
  }

  get isEdit() {
    return this.props?.value?.id !== undefined;
  }

  get dialogTitle() {
    return this.isEdit ? 'Edit Event' : 'Add Event';
  }

  get debitExceedsCurrent() {
    const currentAmount = currentAmountForSample(this.props.sample);
    const { creditOrDebitValue, creditOrDebit } = this.formState.data;
    return creditOrDebit === 'debit' && currentAmount - creditOrDebitValue < 0;
  }

  get layoutRows() {
    const { unit, sample } = this.props;
    const { creditOrDebitValue } = this.formState.data;

    const getErrorForCreditOrDebitValue = () => {
      if (!creditOrDebitValue && creditOrDebitValue !== 0) {
        return 'Required field';
      }
      if (this.debitExceedsCurrent) {
        return 'Debit exceeds current amount';
      }
    };

    const currentAmount = currentAmountForSample(sample);
    const unitString = unit ? `(${unit})` : '';
    const rows: Array<RowColumnDef> = [];

    if (this.needsUpdatedLocation) {
      rows.push(locationInUseWarningRow());
    }
    rows.push(
      creditDebitToggleRow({
        unitString,
        currentAmount,
        getError: getErrorForCreditOrDebitValue,
      }),
    );
    return rows;
  }

  get layout(): RowColumnDef {
    return this.layoutColumns;
  }

  handleAfterSetValue = (path: string) => {
    if (path === 'creditOrDebit' || path === 'creditOrDebitValue') {
      const { creditOrDebit, creditOrDebitValue } = this.formState.data;
      runInAction(() => {
        setEventCreditDebit(
          this.props.value,
          creditOrDebit === 'credit' ? creditOrDebitValue : -creditOrDebitValue,
        );
      });
    }
  };

  handleSubmit = () => {
    this.props.onSubmit();
  };

  handleCancelEdit = () => {
    this.props.onClose();
  };

  get bottomLeftElements() {
    return [
      deleteButtonLayout({
        visible: this.isEdit,
        onClickButton: this.props.onDelete,
      }),
    ];
  }

  get dialogContentProps() {
    return (
      <CDDModalForm
        data={this.props.value ?? {}}
        onCancel={this.handleCancelEdit}
        onOK={this.handleSubmit}
        bottomLeftElements={this.bottomLeftElements}
        terminology={{ OK: 'Save' }}
        formState={this.formState}
        layout={this.layout}
        onAfterSetValue={this.handleAfterSetValue}
        okDisabled={this.needsUpdatedLocation}
      />
    );
  }

  get renderValueIfPresent() {
    const { value } = this.props;
    if (value) {
      return (
        <InventoryDialog
          contentClassName="muiDialog-content-400"
          dialogTitle={this.dialogTitle}
        >
          {this.dialogContentProps}
        </InventoryDialog>
      );
    }
  }

  render() {
    const { isOpen, onClose } = this.props;
    return (
      <>
        <Dialog
          open={isOpen}
          onClose={onClose}
          className="EditInventoryEventDialog"
          PaperProps={{ className: 'main-dialog-paper' }}
        >
          {this.renderValueIfPresent}
        </Dialog>
      </>
    );
  }
}
