import React, { memo, MouseEvent, useCallback, useState } from 'react';
import {
  Box,
  FormControlLabel,
  Link,
  FormGroup,
  Tooltip,
} from '@mui/material';
import Checkbox, { checkboxClasses } from '@mui/material/Checkbox';
import { ReferenceStructure, SimilarStructure, SimilaritySettings } from './SimilarityResultsTypes';
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';
import { downloadSearch, similarityToBlob } from './SimilarityResultsUtilities';
import { ControlButton, DownloadButton, InfoButton, LaunchVisualizationButton, SettingsButton } from '../controls';
import { SimilaritySettingsDialog } from './SimilaritySettingsDialog';
import { SimilarityInfoDialog } from './SimilarityInfoDialog';
import { Collection, Identifier, getLinkInformation } from './Collections';

export const GroupButton = (props: {
  disabled: boolean;
  onClick: (e: MouseEvent) => void;
}) => {
  return (
    <ControlButton {...props} tooltip='Group by scaffold' ariaLabel='group'>
      <AutoAwesomeMotionIcon />
    </ControlButton>
  );
};

export const GotoCollectionButton = (props: { identifier: Identifier, banExternalLinks: boolean }) => {
  const { identifier, banExternalLinks } = props;
  const linkInformation = getLinkInformation(identifier);
  let content: JSX.Element;
  if (!linkInformation || banExternalLinks) {
    content = <span>{identifier.name}</span>;
  } else {
    content = (
      <Link
        href={linkInformation.url}
        underline='none'
        target='_blank'
        rel='noreferrer'
        aria-label={linkInformation.label}
        onClick={(e: MouseEvent) => e.stopPropagation()}
      >
        {identifier.name}
      </Link>
    );
  }
  if (linkInformation && linkInformation.tooltip) {
    return <Tooltip title={linkInformation.tooltip}>{content}</Tooltip>;
  }
  return content;
};

export const CollectionFilters = (props: {
  disabled: boolean;
  settings: SimilaritySettings;
  onChange: (e: SimilaritySettings) => void;
}) => {
  const { disabled, settings, onChange } = props;

  if (settings.collections.length <= 1) return null;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newFilters = new Set(settings.filters);
    if (e.target.checked) {
      newFilters.add(e.target.value as Collection);
    } else {
      newFilters.delete(e.target.value as Collection);
    }
    onChange({ ...settings, filters: [...newFilters] });
  };
  const whiteCheckbox = <Checkbox sx={{ [`&, &.${checkboxClasses.checked}`]: { color: 'white' } }} />;
  return (
    <Tooltip title='Filter by collection'>
      <Box>
        <FormGroup row aria-label='Filter by collection'>
          {settings.collections.map<React.ReactNode>((collection) => (
            <FormControlLabel
              key={collection}
              value={collection}
              label={collection}
              disabled={disabled}
              checked={settings.filters.includes(collection)}
              control={whiteCheckbox}
              onChange={handleChange}
            />
          ))}
        </FormGroup>
      </Box>
    </Tooltip>
  );
};

export const SimilaritySettingsButton = (props: {
  settings: SimilaritySettings;
  onChange: (settings: SimilaritySettings) => void;
  disabled: boolean;
}) => {
  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(true);
  };
  const handleClose = (settings: SimilaritySettings) => {
    setOpen(false);
    props.onChange(settings);
  };
  return (
    <>
      <SettingsButton
        onClick={handleClick}
        tooltip='Similarity settings'
        disabled={props.disabled}
      />
      {open && <SimilaritySettingsDialog
        settings={props.settings}
        open={open}
        onClose={handleClose}
      />}
    </>
  );
};

export const SimilarityInfoButton = () => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <InfoButton
        onClick={() => setOpen(true)}
        tooltip='Information'
      />
      {open && <SimilarityInfoDialog
        open={open}
        onClose={() => setOpen(false)}
      />}
    </>
  );
};

export const DownloadSearchButton = (props: {
  reference: ReferenceStructure
  similarStructures: Array<SimilarStructure>;
  displayProperties: string[];
  disabled: boolean;
  similaritySettings: SimilaritySettings;
}) => {
  const onClick = (e: MouseEvent) => {
    downloadSearch(props);
    e.preventDefault();
  };
  return (
    <DownloadButton
      disabled={props.disabled}
      onClick={onClick}
      tooltip='Download search results'
    />
  );
};

export const SimilarityLaunchVisualizationButton = memo(
  (props: {
    similarStructures: Array<SimilarStructure>;
    reference: ReferenceStructure;
    displayProperties: string[];
    disabled: boolean;
    similaritySettings: SimilaritySettings;
  }) => {
    const { reference, similarStructures, displayProperties, disabled, similaritySettings } = props;
    const getDataBlob = useCallback(() => {
      return similarityToBlob(props);
    }, [reference, similarStructures, displayProperties, similaritySettings]);

    return (
      <LaunchVisualizationButton
        getDataBlob={getDataBlob}
        filename={'search_result.csv'}
        disabled={disabled}
      />
    );
  },
);
SimilarityLaunchVisualizationButton.displayName = 'SimilarityLaunchVisualizationButton';
