/* eslint-disable react/forbid-dom-props */

import { CircularProgress } from '@mui/material';
import React from 'react';

import { FieldDefinitionValue } from '@/FieldDefinitions/types';
import { ID } from '@/Protocols/types';
import Icon from '@/shared/components/Icon.jsx';
import { CDD } from '@/typedJS';

type Props = {
  context: string;
  fieldValue: Partial<FieldDefinitionValue>;
  name: string;
  onFileChanged(fileID: ID | null, filename: string, formData?: FormData): void;
};

type State = Record<string, never>;

/*
  This component wraps the legacy server-rendered File form input system in an
  interface compatible with the ProtocolAnnotator's semi-controlled form style.

  For ugly legacy reasons, this component must be inside a <TR> element, and an
  exception will be thrown if it's not.

  The fieldValue prop sets the initial state of the file upload component, after
  which jQuery takes over managing the state of this component's elements. When
  the selected file is updated, the `onFileChanged()` callback will notify the
  parent of the newly selected file ID and filename, but otherwise this component
  stands alone.
*/
export default class LegacyFileInput extends React.Component<Props, State> {
  containerRef: React.RefObject<HTMLDivElement>;
  existingUploaderRef: React.RefObject<HTMLDivElement>;
  newUploaderRef: React.RefObject<HTMLDivElement>;

  constructor(props: Props) {
    super(props);

    this.containerRef = React.createRef();
    this.existingUploaderRef = React.createRef();
    this.newUploaderRef = React.createRef();
  }

  componentDidMount = () => {
    CDD.Form.setupReactFileUploader(this.existingUploaderRef.current);
    CDD.Form.setupReactFileUploader(this.newUploaderRef.current);
  };

  shouldComponentUpdate = () => {
    return false;
  };

  handleInputChanged = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { onFileChanged } = this.props;
    const newFileID = evt.target.value ?? null;

    if (newFileID) {
      let newFilename = '';
      const form = this.containerRef.current.closest('form');
      const formData = new FormData(form);

      // there are multiple 'a' elements, extract from the one that has the text
      this.containerRef.current
        .querySelector('.filePreview')
        .querySelectorAll('a')
        .forEach((el) => {
          newFilename = newFilename || el.textContent;
        });
      onFileChanged(newFileID, newFilename, formData);
    } else {
      onFileChanged(null, '');
    }
  };

  render = () => {
    const { context, fieldValue, name } = this.props;
    const uploadEndpoint = `${context}/files`;
    const singleFilesPath = `${context}/files/select_single`;
    return (
      <div ref={this.containerRef}>
        <input
          name={name}
          id={name}
          type="hidden"
          onInput={this.handleInputChanged}
          value={fieldValue.uploaded_file_id ?? ''}
        />
        <div
          className="file"
          style={{ display: fieldValue.uploaded_file_id ? 'initial' : 'none' }}
        >
          <div className="filePreview noThumb">
            <a
              href={`${context}/files/${fieldValue.uploaded_file_id}`}
              rel="noopener noreferrer"
              target="_blank"
              title={fieldValue.text_value}
            >
              {fieldValue.text_value}
            </a>
          </div>
          <div className="actions">
            <ul>
              <li>
                <a title="remove" className="cancel" href="#">
                  <Icon icon="delete" forceSize="16" alt="Delete" />
                  Remove this file
                </a>
              </li>
              <li>
                <div
                  className="readoutFileUploader"
                  ref={this.existingUploaderRef}
                  data-upload-endpoint={uploadEndpoint}
                  style={{
                    position: 'relative',
                    overflow: 'auto',
                    maxWidth: '900px',
                    direction: 'ltr',
                  }}
                >
                  <a className="uploadNewFileLink" href="#">
                    <Icon icon="add" forceSize="16" alt="Add" />
                    Upload a different file
                  </a>
                </div>
              </li>
              <li>
                <a
                  data-select-single-files-path={singleFilesPath}
                  className="singleFileSelectorLink"
                  href="#"
                >
                  <Icon icon="attach" forceSize="16" alt="Attach" />
                  Select a different existing file
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div
          className="noSelectedFile"
          style={{ display: fieldValue.uploaded_file_id ? 'none' : 'initial' }}
        >
          <div
            className="readoutFileUploader"
            ref={this.newUploaderRef}
            data-upload-endpoint={uploadEndpoint}
            style={{
              position: 'relative',
              overflow: 'hidden',
              direction: 'ltr',
            }}
          >
            <a className="uploadNewFileLink" href="#">
              <Icon icon="add" forceSize="16" alt="add" />
              Upload a file
            </a>
          </div>
          <span className="separator"> · </span>
          <a
            data-select-single-files-path={singleFilesPath}
            className="singleFileSelectorLink"
            href="#"
          >
            <Icon icon="attach" forceSize="16" alt="Attach" />
            Select an existing file
          </a>
        </div>
        <div className="fileUpload" style={{ display: 'none' }}>
          <p>
            <CircularProgress size={16} className="loading" />
            <span className="status"></span>
          </p>
        </div>
      </div>
    );
  };
}
