/* eslint-disable multiline-ternary, no-nested-ternary */

import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Tooltip,
  Typography,
} from '@mui/material';
import React from 'react';
import { OntologyTemplate, TemplateAssignment } from '../data/templates';
import './Template.sass';
import { Branch, OntologyTree } from '../data/OntologyTree';
import { Schema, SchemaBranch } from '../data/Schema';
import { A, Img } from '@/shared/components/sanitizedTags.js';
import { Annotation } from '../data/annotations';
import { expandPrefix, expandPrefixes } from '../data/utils';
import { SearchCache, SearchCacheHit } from '../data/SearchCache';
import branchOpenIcon from 'ASSETS/images/branch_open_icon.svg';
import branchCloseIcon from 'ASSETS/images/branch_close_icon.svg';
import branchDotIcon from 'ASSETS/images/branch_dot_icon.svg';

type Props = {
  isOpen: boolean,
  template: OntologyTemplate,
  selected: string[], // initial state; must be _collapsed_ URIs
  assn: TemplateAssignment,
  groupNest: string[],
  roots: SchemaBranch[],
  search: SearchCache,
  multiSelect?: boolean, // if on, clicking in terms toggles rather than closing dialog
  termCounts?: Record<string, number>; // optional counts per term for known occurrences, in context; uses collapsed prefixes
  disableUnknown?: boolean; // if on, only terms with at least one descendent count are clickable
  titlePrefix?: string;
  onDialogCancel: () => void,
  onDialogSubmit?: (assn: TemplateAssignment, annotation: Annotation) => void,
  onDialogSubmitSelected?: (selectedURIList: string[]) => void,
};

type State = {
  watermarkUpdate: number,
  searchHits: SearchCacheHit[],
  searchUnion: Set<string>,
  searchLastHit: SearchCacheHit,
  popoverID: string,
  popoverURI: string,
  popoverDescr: string,
};

export class PickTermDialog extends React.Component<Props, State> {
  private selected: string[] = [];
  private termDescendents: Record<string, number> = null;
  private openBranches: Set<string> = null;
  private triggerScrollURI:string = null;
  private branchToElement = new Map<string, React.RefObject<Element>>();

  constructor(props) {
    super(props);

    this.state = {
      watermarkUpdate: 0,
      searchHits: null,
      searchUnion: null,
      searchLastHit: null,
      popoverID: null,
      popoverURI: null,
      popoverDescr: null,
    };
  }

  get dialogTitle() {
    const prefix = this.props.titlePrefix || 'Pick Term:';
    return prefix + ' ' + (this.props.assn ? this.props.assn.name : '');
  }

  public render() {
    this.branchToElement.clear();

    if (!this.props.isOpen) {
      this.openBranches = null;
    } else {
      if (!this.openBranches) this.rederiveOpenness();
    }

    return (
      <Dialog
        disablePortal
        className='EditTeamDialog edit-account-object-dialog'
        open={this.props.isOpen}
        onClose={this.handleClose}
        PaperProps={{ className: 'main-dialog-paper' }}
      >
        {this.renderContent()}
      </Dialog>
    );
  }

  public componentDidUpdate(): void {
    if (this.triggerScrollURI) {
      const scrollRef = this.branchToElement.get(this.triggerScrollURI);
      if (scrollRef && scrollRef.current) {
        this.triggerScrollURI = null;
        scrollRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }

  private renderContent(): JSX.Element {
    const hits = this.state.searchHits;
    const hitKeys = hits ? new Set<string>() : null;
    const hitBranches = hits ? new Set<string>() : null;
    if (hits) {
      for (const hit of hits) {
        hitKeys.add(Schema.makeTreeKey(hit.sequence));
        for (let n = 0; n < hit.sequence.length; n++) {
          hitBranches.add(Schema.makeTreeKey(hit.sequence.slice(0, n + 1)));
        }
      }
    }

    return (
      <>
        <DialogTitle className='muiDialog-title'>
          {this.dialogTitle}
        </DialogTitle>
        <DialogContent className="OntologyTemplate-toppadding">
          {(this.props.roots || []).map((root) => this.renderBranch(root, 0, hitKeys, hitBranches))}
        </DialogContent>
        <DialogActions
          className="project__action TermPicker-actions"
          >
          <div>
            <input
              className="input-text"
              type="text"
              size={50}
              onKeyUp={(event) => this.changedSearch(event.key, (event.target as HTMLInputElement).value)}
              autoFocus={true}
              spellCheck={false}
              placeholder="Search"
              />
          </div>
          <div className="orcancel">
            <A
              className="cancel"
              onClick={this.handleClose}
              href="#"
              style={{ color: 'black', margin: '0px 20px 0px 0px' }}
            >
              Close
            </A>
          </div>
        </DialogActions>
      </>
    );
  }

  private renderBranch(branch: SchemaBranch, depth: number, hitKeys: Set<string>, hitBranches: Set<string>): JSX.Element {
    if (!this.openBranches) return null;

    const isOpen = this.openBranches.has(branch.uri);
    const isSelected = this.selected.includes(branch.uri);
    const searchMatched = hitKeys?.has(branch.treeKey);
    const ancestorMatched = hitBranches?.has(branch.treeKey);

    let toggle: JSX.Element = null;
    if (depth > 0 || this.props.roots.length > 1) {
      let svgPath = isOpen ? branchCloseIcon : branchOpenIcon;
      if (branch.children.length == 0) svgPath = branchDotIcon;
      const toggleClass = branch.children.length > 0 ? ['TermPicker-toggle'] : [];
      if (ancestorMatched && !searchMatched && !isOpen) toggleClass.push('TermPicker-ancestor-matched');
      toggle = (
        <div
          className={toggleClass.join(' ')}
          onClick={() => this.handleToggleBranch(branch)}
          >
          <Img className="TermPicker-icon" src={svgPath}/>
        </div>
      );
    }

    const lineClass = ['TermPicker-termline'];
    if (searchMatched) lineClass.push('TermPicker-searchhit');

    const popoverID = 'value-' + branch.uri;
    const tooltip = popoverID == this.state.popoverID ? (
      <Typography className="ProtocolAnnotator-tooltip">
        <b>{this.state.popoverURI}</b>
        <br/>
        {this.state.popoverDescr}
      </Typography>
    ) : '';

    const useRef = React.createRef<HTMLDivElement>();
    this.branchToElement.set(expandPrefix(branch.uri), useRef);

    let clsname = isSelected ? 'TermPicker-selected' : branch.inSchema ? 'TermPicker-label TermPicker-clickable' : 'TermPicker-inactive';
    if (isSelected && this.props.multiSelect) clsname += ' TermPicker-clickable';
    else if (Schema.isPlaceholderURI(branch.uri)) clsname = 'TermPicker-placeholder';

    const { termCounts } = this.props;
    let showCount: JSX.Element = null;
    if (termCounts) {
      const count = (this.props.termCounts ? this.props.termCounts[branch.uri] : null) || 0;
      const ndesc = (this.termDescendents ? this.termDescendents[branch.uri] : null) || 0;
      const delta = ndesc - count;
      const countText: string[] = [];
      if (count > 0) countText.push(`${count} term${count == 1 ? '' : 's'}`);
      if (delta > 0 && branch.inSchema) countText.push(`${delta} descendent${delta == 1 ? '' : 's'}`);
      showCount = countText.length > 0 ? (<span>({countText.join(', ')})</span>) : null;
      if (ndesc == 0) clsname = 'TermPicker-inactive';
    }

    const clickable = clsname.includes('TermPicker-clickable');

    return (
      <React.Fragment key={branch.uri}>
        <div
          ref={useRef}
          className={lineClass.join(' ')}
          style={{ paddingLeft: `${depth}em` }}
          >
          {toggle}
          <Tooltip
            title={tooltip}
            arrow
            placement="right"
            >
            <div
              className={clsname}
              onClick={clickable ? () => this.handlePickTerm(branch) : null}
              onMouseEnter={() => this.handlePopoverOpen(popoverID, branch)}
              >
              {branch.label}
            </div>
          </Tooltip>
          {showCount}
        </div>
        <div>
          {
            isOpen && branch.children.length > 0 ? (
              branch.children.map((child) => this.renderBranch(child as SchemaBranch, depth + 1, hitKeys, hitBranches))
            ) : null
          }
        </div>
      </React.Fragment>
    );
  }

  private handleToggleBranch(branch: Branch): void {
    if (this.openBranches.has(branch.uri)) {
      this.openBranches.delete(branch.uri);
    } else {
      this.openBranches.add(branch.uri);
      this.loadEverythingOpen().then();
    }
    this.setState({ watermarkUpdate: this.state.watermarkUpdate + 1 });
  }

  private handlePopoverOpen(popoverID: string, schemaBranch: SchemaBranch): void {
    const { uri } = schemaBranch;
    if (Schema.isPlaceholderURI(uri)) return;

    let descr = schemaBranch.description;
    if (descr == null) {
      const branch = OntologyTree.values.cachedBranch(uri);
      if (branch && branch.description != null) descr = branch.description;
    }

    if (descr != null) {
      this.setState({ popoverID, popoverURI: expandPrefix(uri), popoverDescr: descr });
      return;
    }

    (async () => {
      const branch = await OntologyTree.values.getBranch(uri, true, true);
      if (branch) {
        schemaBranch.description = branch.description;
      }
      this.setState({ popoverID, popoverURI: expandPrefix(uri), popoverDescr: branch ? branch.description : null });
    })();
  }

  private handlePickTerm(branch: SchemaBranch): void {
    if (!branch.inSchema) return;
    const { assn, groupNest, multiSelect, onDialogSubmit, onDialogSubmitSelected } = this.props;

    const uri = branch.uri;
    if (!this.selected.includes(uri)) {
      this.selected.push(uri);
    } else {
      if (!multiSelect) return;
      this.selected.splice(this.selected.indexOf(uri), 1);
    }

    this.setState({ searchHits: null, watermarkUpdate: this.state.watermarkUpdate + 1 });

    if (onDialogSubmit) {
      this.props.onDialogSubmit(assn, { propURI: expandPrefix(assn.propURI), groupNest: expandPrefixes(groupNest), valueURI: expandPrefix(uri) });
    } else if (onDialogSubmitSelected) {
      this.props.onDialogSubmitSelected(expandPrefixes(this.selected));
    }
  }

  private handleClose = (): void => {
    this.setState({ searchHits: null });
    this.props.onDialogCancel();
  };

  private changedSearch(key: string, value: string): void {
    const search = this.props.search;
    if (!search || !this.props.isOpen) return;

    if (!value) {
      this.setState({ searchHits: null });
      return;
    }

    if (key == 'Enter') {
      this.handleSearchLook();
      return;
    }

    search.performSearch(value, (hits: SearchCacheHit[]) => {
      if (!this.props.isOpen) return;
      this.setState({ searchHits: hits });
    });
  }

  private handleSearchLook(): void {
    const { searchHits, searchLastHit } = this.state;
    if (!searchHits?.length) return;

    let idxMatch = -1;
    if (searchLastHit) {
      const lastURI = WebMolKit.Vec.last(searchLastHit.sequence);
      for (let n = 0; n < searchHits.length; n++) {
        if (WebMolKit.Vec.last(searchHits[n].sequence) == lastURI) {
          idxMatch = n;
          break;
        }
      }
    }
    const curHit = searchHits[(idxMatch + 1) % searchHits.length], curURI = WebMolKit.Vec.last(curHit.sequence);

    const scanForMatches = (branch: Branch): void => {
      if (branch.uri == curURI) {
        for (let look = branch.parent; look; look = look.parent) {
          this.openBranches.add(look.uri);
        }
        this.triggerScrollURI = expandPrefix(branch.uri);
        this.setState({ searchLastHit: curHit, watermarkUpdate: this.state.watermarkUpdate + 1 });
        return;
      }
      for (const child of branch.children) scanForMatches(child);
    };

    const { roots } = this.props;
    for (const root of roots) scanForMatches(root);
  }

  // dialog has been opened with new branch info, so decide which of the branches should be open
  private rederiveOpenness(): void {
    this.selected = [...this.props.selected];
    this.openBranches = new Set<string>();
    const { termCounts } = this.props;
    if (termCounts) this.termDescendents = {};
    const residualSelected = new Set<string>(this.selected);

    const scanForSelected = (branch: Branch): void => {
      if (termCounts) {
        const counts = termCounts[branch.uri];
        if (counts > 0) {
          for (let look = branch; look; look = look.parent) {
            this.termDescendents[look.uri] = (this.termDescendents[look.uri] || 0) + counts;
          }
        }
      }

      if (residualSelected.has(branch.uri)) {
        residualSelected.delete(branch.uri);
        for (let look = branch.parent; look; look = look.parent) {
          this.openBranches.add(look.uri);
        }
      }

      for (const child of branch.children) scanForSelected(child);
    };

    for (const root of this.props.roots) {
      this.openBranches.add(root.uri);
      residualSelected.delete(root.uri);
      scanForSelected(root);
    }

    this.loadEverythingOpen().then();
  }

  // we need to guarantee that all of the visible nodes have their labels loaded, which could involve fetching
  private async loadEverythingOpen(): Promise<void> {
    const checkList: Branch[] = [];
    const recursiveCheck = (branch: Branch): void => {
      if (branch.label == null) checkList.push(branch);
      if (this.openBranches.has(branch.uri)) {
        for (const child of branch.children) recursiveCheck(child);
      }
    };
    for (const root of this.props.roots) recursiveCheck(root);
    for (const branch of checkList) {
      branch.label = (await OntologyTree.values.getBranch(branch.uri)).label;
    }

    if (checkList.length > 0) this.setState({ watermarkUpdate: this.state.watermarkUpdate + 1 });
  }
}
