import { LocationValue } from '@/Samples/types';
import { getRootStore } from '@/stores/rootStore';
import { LocationPickerDialog } from './LocationPickerDialog';
import { LocationUtils } from './locationUtils';
import { Button, FormHelperText } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';

type Props = {
  value?: LocationValue;
  error?: string;
  onSubmit: (value: LocationValue) => void;
};

type State = {
  open?: boolean;
  value?: LocationValue;
};

@observer
export class LocationPickerField extends React.Component<Props, State> {
  static getDerivedStateFromProps(nextProps: Props, prevState: State): State {
    return {
      value: nextProps.value,
      ...prevState,
    };
  }

  constructor(props: Props) {
    super(props);
    this.state = { open: false };
  }

  get store() {
    return getRootStore().locationStore;
  }

  handleOpenPicker = () => {
    this.store.loadLocations();
    this.setState({ open: true, value: this.props.value });
  };

  render() {
    const { locations, fetchBoxContents } = this.store;
    const { value, error } = this.props;
    return <div className={`LocationBoxPickerField ${(error !== undefined && error !== null) ? 'error' : ''}`}>
      <Button disableRipple variant='contained' onClick={this.handleOpenPicker} fullWidth color='inherit'>
        {value?.value ? <><legend>Location:</legend>{value.value}</> : 'Click to Pick Location'}
      </Button>
      {error && <FormHelperText error={true} style={{ textAlign: 'center', fontSize: 12 }}>{error}</FormHelperText>}
      <LocationPickerDialog
        {...this.props}
        open={this.state?.open}
        value={this.state.open ? this.state.value : null}
        onCancel={() => this.setState({ open: false, value: null })}
        onChange={value => this.setState({ value })}
        onSubmit={value => {
          this.setState({ open: false, value: null });
          if (!value.value) {
            value.value = LocationUtils.getLabelForLocation(value, locations);
          }
          this.props.onSubmit(value);
        }}
        locations={locations}
        fetchBoxContents={fetchBoxContents}
      ></LocationPickerDialog>
    </div>;
  }
}
