import React from 'react';
import { InventoryFieldDefinitionsList } from './InventoryFieldDefinitionsList';
import axios from 'axios';
import { FieldDefinition } from '@/FieldDefinitions/types';
import { FieldDefinitionUtils } from '@/FieldDefinitions/shared/fieldDefinitionUtils';
import { getRootStore } from '@/stores/rootStore';
import { observer } from 'mobx-react';

type Props = {
  inventory_sample_field_definitions: {
    inventory_sample_field_definition: FieldDefinition
  }[];
  inventory_event_field_definitions: {
    inventory_event_field_definition: FieldDefinition
  }[];
  inventory_sample_field_definitions_url: string;
  using_registration_system: boolean;
  inventory_sample_field_definitions_locked: boolean;
  vault_has_inventory_samples: boolean;
}

type State = Props & {
  currentlyEditing: 'inventory_sample' | 'inventory_event' | null;
  sampleRows: Array<FieldDefinition>;
  eventRows: Array<FieldDefinition>;
  errorMessages: Array<string> | null;
}

@observer
export default class InventoryFieldDefinitions extends React.Component<Props, State> {
  sortFields = (a: FieldDefinition, b: FieldDefinition) => {
    return a.display_order - b.display_order;
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      ...props,
      sampleRows: props.inventory_sample_field_definitions.map(({ inventory_sample_field_definition }) => inventory_sample_field_definition)
        .sort(this.sortFields),
      eventRows: props.inventory_event_field_definitions.map(({ inventory_event_field_definition }) => inventory_event_field_definition)
        .sort(this.sortFields),
      currentlyEditing: null,
      errorMessages: null,
    };
  }

  saveRows() {
    const { sampleRows, eventRows } = this.state;
    const bodyFormData = FieldDefinitionUtils.toFormData({
      inventory_sample_field_definitions: sampleRows,
      inventory_event_field_definitions: eventRows,
    });

    axios<unknown, {
      inventory_sample_field_definitions: FieldDefinition[],
      inventory_event_field_definitions: FieldDefinition[]
    }>({
      method: 'put',
      url: this.props.inventory_sample_field_definitions_url,
      data: bodyFormData,
    })
      .then(response => {
        if (response.status === 200) {
          this.setState({
            currentlyEditing: null,
            sampleRows: response.data.inventory_sample_field_definitions.sort(this.sortFields),
            eventRows: response.data.inventory_event_field_definitions.sort(this.sortFields),
          });
        }
      })
      .catch((error) => {
        if (error) {
          let errorMessages = null;
          if (error.response?.data) {
            if (Array.isArray(error.response.data)) {
              errorMessages = error.response.data;
            } else {
              errorMessages = [error.response.data];
            }
          }
          this.setState({ errorMessages });
        }
      });
  }

  handleSubmitSamples = (rows: Array<FieldDefinition>) => {
    this.setState({
      sampleRows: rows.slice(),
    }, this.saveRows);
  };

  handleSubmitEvents = (rows: Array<FieldDefinition>) => {
    this.setState({
      eventRows: rows.slice(),
    }, this.saveRows);
  };

  handleEditSamples = () => {
    this.setState({ currentlyEditing: 'inventory_sample' });
  };

  handleEditEvents = () => {
    this.setState({ currentlyEditing: 'inventory_event' });
  };

  handleCancelEdit = () => {
    this.setState({
      currentlyEditing: null,
      errorMessages: null,
    });
  };

  render() {
    // while editing locations, disable the submit/cancel buttons
    const editingLocations = !!getRootStore().locationStore.currentlyEditingLocations;

    return <div className='InventoryFieldDefinitions'>
      <InventoryFieldDefinitionsList
        type='inventory_sample'
        rows={this.state.sampleRows}
        errorMessages={this.state.errorMessages}
        usingRegistrationSystem={this.props.using_registration_system}
        isEditing={this.state.currentlyEditing === 'inventory_sample'}
        locked={this.props.inventory_sample_field_definitions_locked || this.state.currentlyEditing === 'inventory_event'}
        onEdit={this.handleEditSamples}
        onCancelEdit={this.handleCancelEdit}
        onSubmit={this.handleSubmitSamples}
        preventUncheckingSampleId={this.props.vault_has_inventory_samples}
      />

      <InventoryFieldDefinitionsList
        type='inventory_event'
        rows={this.state.eventRows}
        errorMessages={this.state.errorMessages}
        usingRegistrationSystem={this.props.using_registration_system}
        isEditing={this.state.currentlyEditing === 'inventory_event'}
        locked={this.props.inventory_sample_field_definitions_locked || this.state.currentlyEditing === 'inventory_sample'}
        onEdit={this.handleEditEvents}
        onCancelEdit={this.handleCancelEdit}
        onSubmit={this.handleSubmitEvents}
        preventUncheckingSampleId={this.props.vault_has_inventory_samples}
        disableSubmitOrCancel={editingLocations}
      />
    </div>;
  }
}
