import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { DoseResponsePlot } from '@cdd/vision/dist/app/entrypoints/doseResponsePlot';
import { RenderIfVisible } from '@/components/RenderIfVisible/RenderIfVisible';
import { A, Form } from '@/shared/components/sanitizedTags.js';

type DoseResponsePlotProps = Parameters<typeof DoseResponsePlot>[0];
type DoseResponsePlotData = Pick<DoseResponsePlotProps, 'data'>;
type DatalessDoseResponsePlotProps = Omit<DoseResponsePlotProps, 'data'>;
interface showUrlParams {
  [key: string]: string | string[];
}
interface dataUrlParams {
  [key: string]: string | string[];
}
interface editUrlParams {
  [key: string]: string | string[];
}
type DoseResponsePlotWrapperProps = DatalessDoseResponsePlotProps & {
  dataUrl?: string;
  dataUrlParams?: dataUrlParams;
};
type DelayedDoseResponsePlotProps = DoseResponsePlotWrapperProps & {
  children?: ReactNode;
  showUrl?: string;
  showUrlParams?: showUrlParams;
  showLinkLabel?: string;
  editUrl?: string;
  editUrlParams?: editUrlParams;
  editLinkLabel?: string;
  shouldShowEditLink?: boolean;
};

const DoseResponsePlotWrapper = (props: DoseResponsePlotWrapperProps) => {
  const [state, setState] = useState<DoseResponsePlotData>({ data: null });
  const { dataUrl, dataUrlParams, ...rest } = props;

  useEffect(() => {
    fetch(dataUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(dataUrlParams),
    })
      .then((response) => response.json())
      .then((data) => setState({ data: Array.isArray(data) ? data : [data] }));
  }, []);

  if (!state.data) {
    return null;
  }

  return <DoseResponsePlot {...rest} {...state} />;
};

const DelayedDoseResponsePlot = (props: DelayedDoseResponsePlotProps) => {
  const { children, showUrl, showUrlParams, showLinkLabel, editUrl, editUrlParams, editLinkLabel, shouldShowEditLink, ...rest } = props;
  const ref = useRef<HTMLFormElement>();
  const refShowForm = useRef<HTMLFormElement>();
  const refEditForm = useRef<HTMLFormElement>();

  const handleClick = () => {
    ref.current.submit();
    return false;
  };

  const handleClickShow = (event) => {
    event.preventDefault();
    event.stopPropagation();
    refShowForm.current.submit();
    return false;
  };

  const handleClickEdit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    refEditForm.current.submit();
    return false;
  };

  return (
    <RenderIfVisible>
      {/* eslint-disable-next-line react/forbid-dom-props */}
      <Form className="hidden" ref={refShowForm} name="" method="post" action={showUrl} target="newWindow">
        {Object.entries(showUrlParams).map(([name, value]) => {
          return <input key={name} type="hidden" name={name} value={value} />;
        })}
      </Form>
      {/* eslint-disable-next-line react/forbid-dom-props */}
      <Form className="hidden" ref={refEditForm} name="" method="post" action={editUrl} target="newWindow">
        {Object.entries(editUrlParams).map(([name, value]) => {
          return <input key={name} type="hidden" name={name} value={value} />;
        })}
      </Form>
      {/* eslint-disable-next-line react/forbid-dom-props */}
      <Form className="hidden" ref={ref} name="" method="post" action={showUrl} target="newWindow">
        {Object.entries(showUrlParams).map(([name, value]) => {
          return <input key={name} type="hidden" name={name} value={value} />;
        })}
      </Form>

      <A onClick={handleClick} href="#" style={{ display: 'contents' }}>
        <DoseResponsePlotWrapper {...rest} />
      </A>
      {children}
      <div className={'dose-response-plot-miniapp-toggle'}>
        <A
          href="#"
          onClick={(e) => handleClickShow(e)}
          dangerouslySetInnerHTML={{ __html: showLinkLabel }}
        />
      </div>
      {shouldShowEditLink && (
        <div className={'outlier-selection-link'}>
          <A
            href="#"
            onClick={(e) => handleClickEdit(e)}
          >{editLinkLabel}</A>
        </div>
      )}
    </RenderIfVisible>
  );
};

export default DelayedDoseResponsePlot;
