import React from 'react';

interface CheckboxProps {
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  checked: boolean;
  disabled?: boolean;
  dataTypeName?: string;
  disabledText?: string;
  readOnly?: boolean;
  uneditable?: boolean;
  name?: string;
}

export const UniqueCheckbox: React.FC<CheckboxProps> = (props) => {
  const {
    onChange,
    checked,
    disabled = false,
    dataTypeName = 'Text',
    disabledText,
    readOnly = false,
    uneditable = false,
  } = props;

  const disabledFromDataType = dataTypeName !== 'Text';
  const isDisabled = disabled || disabledFromDataType;

  let title: string | null = null;
  if (disabledText && isDisabled) {
    title = disabledText;
  } else if (disabledFromDataType) {
    title = 'Only text fields can be unique';
  }

  const name = props.name || 'unique';

  if (uneditable) {
    return null;
  } else {
    return (
      <input
        type="checkbox"
        name={name}
        onChange={onChange}
        checked={checked}
        disabled={isDisabled}
        readOnly={readOnly}
        title={title || undefined}
      />
    );
  }
};

export const OverwritableCheckbox: React.FC<CheckboxProps> = (props) => {
  const {
    onChange,
    checked,
    disabled = false,
    disabledText,
    readOnly = false,
    uneditable = false,
  } = props;

  const isDisabled = disabled;

  let title: string | null = null;
  if (disabledText && isDisabled) {
    title = disabledText;
  }

  const name = props.name || 'overwritable';

  if (uneditable) {
    return null;
  } else {
    return (
      <input
        type="checkbox"
        name={name}
        onChange={onChange}
        checked={checked}
        disabled={isDisabled}
        readOnly={readOnly}
        title={title || undefined}
      />
    );
  }
};
