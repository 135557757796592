import React from 'react';
import {
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import SaltAndSolventEditor from './SaltAndSolventEditor';

const ShouldUseStoichiometry = 'should_use_stoichiometry';
const ShouldStrip = 'should_strip';

type StripOrSaltProps = React.ComponentProps<typeof SaltAndSolventEditor> & {
  should_use_stoichiometry?: boolean;
}

interface StripOrSaltState {
  specified_batch: string;
}

export class StripOrSalt extends React.Component<StripOrSaltProps, StripOrSaltState> {
  static noTheme = true;

  constructor(props: StripOrSaltProps) {
    super(props);
    const { should_use_stoichiometry } = this.props;
    const specified_batch = should_use_stoichiometry ? ShouldUseStoichiometry : ShouldStrip;
    this.state = {
      specified_batch,
    };
  }

  stripSaltsDisabled = (): boolean => {
    return this.state.specified_batch === ShouldUseStoichiometry;
  };

  useSaltsDisabled = (): boolean => {
    return !this.stripSaltsDisabled();
  };

  handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    this.setState({ specified_batch: event.target.value });
  };

  render() {
    const radioGroupProps = {
      height: '100%',
      name: 'specified_batch',
      onChange: this.handleRadioChange,
      value: this.state.specified_batch,
    };

    return (
      <div className='SaltAndSolventEditor'>
        <FormControl component="fieldset">
          <Grid container alignItems='center' spacing={1}>
            <Grid item xs={2}>
              <RadioGroup {...radioGroupProps}>
                <FormControlLabel
                  id='specified_batch_should_strip_salts'
                  value="should_strip"
                  className="SaltAndSolventEditor_text"
                  control={<Radio color={'primary'} />}
                  label={<Typography className='SaltAndSolventEditor_text'>Strip:</Typography>}
                />
              </RadioGroup>
            </Grid>
            <Grid item xs={10}>
              <Typography className='SaltAndSolventEditor_text'>
                Strip salts and solvents from structure during registration
              </Typography>
            </Grid>

            <Grid item xs={2}>
              <RadioGroup {...radioGroupProps}>
                <FormControlLabel
                  control={<Radio color={'primary'} />}
                  className='SaltAndSolventEditor_text'
                  id='specified_batch_should_use_stoichiometry'
                  label={<Typography className='SaltAndSolventEditor_text'>Stoichiometry:</Typography>}
                  value="should_use_stoichiometry"
                />
              </RadioGroup>
            </Grid>

            <Grid item xs={10}>
              <SaltAndSolventEditor
                disabled={this.useSaltsDisabled()}
                {...this.props}
              />
            </Grid>
          </Grid>
        </FormControl>
      </div>
    );
  }
}
