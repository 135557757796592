import {
  ColumnDef,
} from '@/components';
import EditFieldDefinitionView from '@/FieldDefinitions/shared/EditFieldDefinitionView';
import { EditFieldDefinitionViewProps, FieldDefinitionUtils } from '@/FieldDefinitions/shared/fieldDefinitionUtils';
import { term } from '@/shared/utils/stringUtils';
import React from 'react';
import { FieldDefinition } from '../types';

type Props = EditFieldDefinitionViewProps;
type State = Props;

export class RunFieldDefinitions extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = FieldDefinitionUtils.stripEntitiesFromProps(WebMolKit.deepClone(props), 'run') as Props;
  }

  dataTypeOptions = [
    'Text',
    'LongText',
    'PickList',
    'File',
    'Number',
    'BatchLink',
  ];

  get columns(): Array<ColumnDef> {
    const columns: Array<ColumnDef & { id: keyof FieldDefinition; }> = [
      {
        label: 'Name',
        id: 'name',
        width: 140,
      },
      {
        label: 'Data Type',
        id: 'data_type_name',
        type: 'pickList',
        width: 120,
      },
      {
        label: 'Dropdown Display',
        className: 'dropdown-display',
        id: 'is_display_identifier',
        type: 'checkmark',
        width: 50,
        style: { textAlign: 'center' },
      },
      {
        label: 'This Field',
        id: 'required_group_number',
        name: 'requirement',
        className: 'requirement',
        width: 230,
      },
    ];
    return columns as Array<ColumnDef>;
  }

  get fixedRows(): Array<FieldDefinition> {
    return [
      {
        id: 'run-date',
        name: `${term('run', true)} Date`,
        unique_value: true,
        is_display_identifier: true,
        _required_group_label: 'is required',
      },
    ];
  }

  render() {
    return <EditFieldDefinitionView
      {...this.state}
      columns={this.columns}
      fixedRows={this.fixedRows}
      dataType={'run'}
      dataTypeOptions={this.dataTypeOptions}
    />;
  }
}
