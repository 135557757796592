/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, multiline-ternary */

import { Annotation } from '@/Annotator/data/annotations';
import { OntologyTree } from '@/Annotator/data/OntologyTree';
import { Schema, SchemaBranch } from '@/Annotator/data/Schema';
import { SearchCache, SearchCacheRoot } from '@/Annotator/data/SearchCache';
import { TemplateAssignment } from '@/Annotator/data/templates';
import { cherryPickBranches, collapsePrefix, collapsePrefixes, initializeOntologies, keyPropGroupValue } from '@/Annotator/data/utils';
import { PickTermDialog } from '@/Annotator/Templates/PickTermDialog';
import { FieldDataType } from '@/FieldDefinitions/types';
import { EditFormDefinitionStore } from '@/FormDefinitions/stores/editFormDefinitionStore';
import { FormLayoutForEditing } from '@/FormDefinitions/types';
import { CDDModalForm } from '@/shared/components/CDDForm/CDDForm';
import { DDFormProps } from '@/shared/components/DDForm/DDForm';
import { layoutBuilder } from '@/shared/components/DDForm/layoutBuilder';
import { ElementType, FieldValueType } from '@/shared/components/DDForm/types';
import { FieldDef } from '@/shared/components/DDForm/types/base/fieldDef';
import { CustomFieldDef } from '@/shared/components/DDForm/types/customFieldDef';
import { Dialog, DialogContent, DialogTitle, Tooltip, Typography } from '@mui/material';
import { deleteButtonLayout } from '@/shared/components/CDDForm/cddElements';
import { computed, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { A } from '@/shared/components/sanitizedTags.js';
import React from 'react';

const {
  row,
  column,
  textInput,
  numberInput,
  dateInput,
  select,
  checkbox,
  typography,
  custom,
} = layoutBuilder;

enum PickerPurpose {
  DefaultTerm,
  AllowedBranch,
}

type Props = {
  store: EditFormDefinitionStore;
};

type State = {
  isPickerOpen: boolean,
  pickerAssn: TemplateAssignment,
  pickerNest: string[],
  pickerRoots: SchemaBranch[],
  pickerSelected: string[],
  pickerSearch: SearchCache,
  pickerPurpose: PickerPurpose,
  popoverID: string,
  popoverURI: string,
  popoverDescr: string,
};

@observer
export class EditFieldDialog extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    (async () => {
      await initializeOntologies();
    })();

    makeObservable(this, {
      layout: computed,
      bottomRowElements: computed,
    });

    this.state = {
      isPickerOpen: false,
      pickerAssn: null,
      pickerNest: null,
      pickerRoots: null,
      pickerSelected: null,
      pickerSearch: null,
      pickerPurpose: null,
      popoverID: null,
      popoverURI: null,
      popoverDescr: null,
    };
  }

  get value() {
    return this.props.store.currentEditItem;
  }

  handleSubmit = () => {
    this.props.store.handleSubmitEditField();
  };

  handleCancel = () => {
    this.props.store.handleCancelEditField();
  };

  handleClose = (event: unknown, reason: 'backdropClick' | 'escapeKeyDown') => {
    if (reason === 'escapeKeyDown') {
      this.props.store.handleCancelEditField();
    }
  };

  handleChangeLabel = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.value.label = e.target.value;
  };

  handleChangeSpan = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.value.span = parseInt(e.target.value, 10);
  };

  get fieldDefinition() {
    const { store, store: { currentEditItem } } = this.props;

    return store.getFieldDefinition(currentEditItem);
  }

  get bottomRowElements() {
    const { currentEditItem, currentFieldDefinitionsRequiredGroups } = this.props.store;

    if (currentEditItem) {
      const canDelete = (!currentEditItem.isRequired) &&
        (typeof currentEditItem.fieldID !== 'string') && // a special field like protocol name or run date, always required
        (currentFieldDefinitionsRequiredGroups[currentEditItem.fieldID]?.canDelete ?? true);

      return [
        deleteButtonLayout({
          label: 'Remove Field',
          onClickButton: this.handleDelete,
          disabled: !canDelete,
          tooltip: canDelete ? '' : 'This field is required and cannot be removed.',
        }),
      ];
    }
  }

  get layout() {
    const {
      fieldDefinition,
      props: {
        store: {
          currentEditItem,
          currentFieldDefinitionsRequiredGroups,
          bumpState, // necessary in order to cause reload triggering for ontology term downloads
        },
      },
    } = this;

    const isProtocolField = currentEditItem.fieldID !== null && currentEditItem.fieldID !== undefined;
    const isOntologyField = currentEditItem.ontologyAssn !== null && currentEditItem.ontologyAssn !== undefined;
    const isJustLabel = !isProtocolField && !isOntologyField;

    const canEditText = isJustLabel || isOntologyField;

    const dataTypeName = fieldDefinition?.data_type_name;

    // if this field is required via its definition, then it will have a required label (which may indicate that it is one of
    // a group of required fields). In this case, we shouldn't allow unchecking Required.
    const isFieldDefinitionRequired = !!currentFieldDefinitionsRequiredGroups[currentEditItem.fieldID]?.requiredLabel;

    const requiredLabel = isOntologyField ? null : currentFieldDefinitionsRequiredGroups[currentEditItem.fieldID]?.requiredLabel;
    const isRequired = currentEditItem.isRequired || requiredLabel !== null;

    const pickListValues = ((dataTypeName === FieldDataType.PickList)
      ? (fieldDefinition.pick_list_values ?? [])
      : [])
      .filter(item => item !== null && item !== undefined && !item.hidden);

    const result = column({ controlAttributes: { style: { marginBottom: '2em' } } }, [
      textInput({ key: 'label', label: 'Field Label', disabled: !canEditText, autoFocus: canEditText }),
    ]);

    if (!isJustLabel) {
      result.children.push(
        row([
          checkbox({
            key: 'isRequired',
            label: 'Required',
            visible: isProtocolField || isOntologyField,
            disabled: isProtocolField && ((requiredLabel !== null && !currentEditItem.isRequired) || isFieldDefinitionRequired),
            translateGetValue: (value) => {
              return value || isRequired;
            },
          }),
          typography({
            label: requiredLabel,
            visible: !!requiredLabel,
          }),
        ]),
      );

      const defaultValueKeyAndLabel = { key: 'defaultValue', label: 'Default Value' };
      const lockValue = { key: 'isLocked', label: 'Lock Value' };
      switch (dataTypeName) {
        case FieldDataType.Number:
        case FieldDataType.Float:
          result.children.push(numberInput(defaultValueKeyAndLabel));
          result.children.push(checkbox(lockValue));
          break;

        case FieldDataType.Text:
        case FieldDataType.LongText:
          result.children.push(textInput(defaultValueKeyAndLabel));
          result.children.push(checkbox(lockValue));
          break;

        case FieldDataType.Date:
          result.children.push(dateInput(defaultValueKeyAndLabel));
          result.children.push(checkbox(lockValue));
          break;

        case FieldDataType.PickList:
          result.children.push(select({
            ...defaultValueKeyAndLabel,
            selectOptions: pickListValues.filter(item =>
              (!currentEditItem.allowedValues?.length || currentEditItem.allowedValues.includes(item.value))),
            optionFieldLabel: 'value',
            valueType: 'number',
          }));
          result.children.push(checkbox(lockValue));

          if (pickListValues.length > 0) {
            result.children.push(column({ legend: 'Allowed Pick List Values' },
              pickListValues.map(item => checkbox({
                key: 'allowedValues',
                label: item.value,
                translateGetValue: (value: any) => {
                  return (currentEditItem.allowedValues ?? []).includes(item.value) ||
                    !currentEditItem.allowedValues?.length;
                },
                translateSetValue: (value: any) => {
                  const allowedValues = currentEditItem.allowedValues?.length
                    ? currentEditItem.allowedValues
                    : pickListValues.map(item => item.value);

                  if (value) {
                    if (!allowedValues.includes(item.value)) {
                      allowedValues.push(item.value);
                    }
                  } else {
                    const index = allowedValues.indexOf(item.value);
                    if (index >= 0) {
                      allowedValues.splice(index, 1);
                    }
                  }
                  return allowedValues;
                },
              }))));
          }

          break;
      }

      if (isOntologyField) {
        const defaultChildren: ElementType[] = [this.renderDefaultTerm(currentEditItem)];
        if (currentEditItem.defaultValue) {
          defaultChildren.push(checkbox(lockValue));
        }
        result.children.push(column({ legend: defaultValueKeyAndLabel.label }, defaultChildren));

        result.children.push(column(
          { legend: 'Allowed Ontology Branches' },
          [this.renderAllowedBranches(currentEditItem)],
        ));
      }
    }

    return result;
  }

  handleDelete = () => {
    this.props.store.deleteField(this.value.layoutID);
    this.handleCancel();
  };

  render() {
    const {
      store: {
        currentEditItem,
        selectedTemplate,
      },
    } = this.props;

    return (
      <>
        <Dialog
          open={currentEditItem !== null}
          onClose={this.handleClose}
        >
          {currentEditItem && (
            <>
              <DialogTitle className='muiDialog-title'>
                Edit Field
              </DialogTitle>

              <DialogContent>
                <CDDModalForm
                  data={currentEditItem}
                  layout={this.layout}
                  onOK={this.handleSubmit}
                  onCancel={this.handleCancel}
                  terminology={{ OK: 'Save' }}
                  bottomLeftElements={this.bottomRowElements}
                />
              </DialogContent>

              <PickTermDialog
                isOpen={this.state.isPickerOpen}
                template={selectedTemplate}
                assn={this.state.pickerAssn}
                groupNest={this.state.pickerNest}
                roots={this.state.pickerRoots || []}
                selected={this.state.pickerSelected || []}
                search={this.state.pickerSearch}
                onDialogCancel={this.handlePickerDialogCancel}
                onDialogSubmit={this.handlePickerDialogSubmit}
              />
            </>
          )}
        </Dialog>
      </>);
  }

  renderDefaultTerm(currentEditItem: FormLayoutForEditing): CustomFieldDef {
    let payload: JSX.Element;
    const valueURI = currentEditItem.defaultValue;
    if (valueURI) {
      const schema = new Schema(this.props.store.currentTemplate);
      const propURI = currentEditItem.ontologyAssn[0], groupNest = currentEditItem.ontologyAssn.slice(1);
      const label = schema?.labelForURI(propURI, groupNest, valueURI) || OntologyTree.values.cachedLabel(valueURI);
      if (label) {
        payload = this.renderTerm(label, propURI, groupNest, valueURI, () => this.handleClearDefault());
      } else {
        payload = <>Loading...</>;
        setTimeout(() => this.updateOntologyCache([valueURI]).then(), 0);
      }
    } else {
      payload = (
        <A
          href="#"
          onClick={() => this.handlePickDefaultTerm(currentEditItem)}
        >
          Select Term
        </A>
      );
    }

    return custom({ key: 'defaultValue', render: () => <div>{payload}</div> });
  }

  renderAllowedBranches(currentEditItem: FormLayoutForEditing): CustomFieldDef {
    const schema = new Schema(this.props.store.currentTemplate);
    const propURI = currentEditItem.ontologyAssn[0], groupNest = currentEditItem.ontologyAssn.slice(1);
    const uncachedValues: string[] = [];
    const allowedValues = currentEditItem.allowedValues ?? [];

    let row = 0;
    const createBranchContent = (valueURI: string): JSX.Element => {
      row++;
      const label = schema?.labelForURI(propURI, groupNest, valueURI) || OntologyTree.values.cachedLabel(valueURI);
      if (!label) {
        uncachedValues.push(valueURI);
      }

      const style: React.CSSProperties = { gridArea: `${row} / term` };
      return (
        <div key={`term${row}`} className="FormField-ontobranch-item" style={style}>
          {this.renderTerm(label, propURI, groupNest, valueURI, () => this.handleDeleteBranch(valueURI))}
        </div>
      );
    };

    const createButtonRow = (): JSX.Element => {
      row++;
      return (
        <div key="button" style={{ gridArea: `${row} / start / ${row} / end`, justifySelf: allowedValues.length == 0 ? 'start' : 'end' }}>
          <A
            href="#"
            onClick={() => this.handleAddBranch(currentEditItem)}
          >
            Add Branch
          </A>
        </div>
      );
    };

    let payload = (
      <div className="FormField-ontobranch-grid">
        {allowedValues.map(createBranchContent)}
        {createButtonRow()}
      </div>
    );
    if (uncachedValues.length > 0) {
      payload = <div>Loading...</div>;
      setTimeout(() => this.updateOntologyCache(uncachedValues).then(), 0);
    }

    return custom({ key: 'selectedBranches', render: () => payload });
  }

  private renderTerm(label: string, propURI: string, groupNest: string[], valueURI: string, onClick:() => void): JSX.Element {
    const {
      popoverDescr,
      popoverID: statePopoverID,
      popoverURI,
    } = this.state;
    const popoverID = 'term-' + keyPropGroupValue(propURI, groupNest, valueURI);
    const tooltip = popoverID == statePopoverID ? (
      <Typography className="ProtocolAnnotator-tooltip">
        <b>{popoverURI}</b>
        <br />
        {popoverDescr}
      </Typography>
    ) : '';

    return (
      <Tooltip
        title={tooltip}
        arrow
        placement="right"
      >
        <div
          className="ProtocolAnnotator-annotation"
          onMouseEnter={() => this.handleValuePopoverOpen(popoverID, valueURI)}
        >
          {label || collapsePrefix(valueURI)}
          &nbsp;
          <div className="ProtocolAnnotator-term-button" onClick={onClick}>
            {'\u{00D7}'}
          </div>
        </div>
      </Tooltip>
    );
  }

  private async updateOntologyCache(uriList: string[]): Promise<void> {
    let anything = false;
    for (const uri of uriList) {
      if (await OntologyTree.values.getBranch(uri, true, false, false)) {
        anything = true;
      }
    }
    if (anything) {
      this.props.store.triggerReload();
    }
  }

  async handlePickDefaultTerm(currentEditItem: FormLayoutForEditing): Promise<void> {
    const schema = new Schema(this.props.store.currentTemplate);
    const propURI = currentEditItem.ontologyAssn[0], groupNest = currentEditItem.ontologyAssn.slice(1);
    const assn = schema.findAssignment(propURI, groupNest);
    let roots = await schema.composeBranch(assn) ?? [];
    if (currentEditItem.allowedValues?.length > 0) {
      roots = cherryPickBranches(roots, currentEditItem.allowedValues as string[]);
    }

    const cacheRoots: SearchCacheRoot[] = roots.map((branch) => { return { assn, groupNest, branch }; });

    this.setState({
      isPickerOpen: true,
      pickerAssn: assn,
      pickerNest: groupNest,
      pickerRoots: roots,
      pickerSelected: [],
      pickerSearch: new SearchCache(schema, cacheRoots),
      pickerPurpose: PickerPurpose.DefaultTerm,
    });
  }

  async handleAddBranch(currentEditItem: FormLayoutForEditing): Promise<void> {
    const schema = new Schema(this.props.store.currentTemplate);
    const propURI = currentEditItem.ontologyAssn[0], groupNest = currentEditItem.ontologyAssn.slice(1);
    const assn = schema.findAssignment(propURI, groupNest);
    const selected = currentEditItem.allowedValues as string[] ?? [];
    const roots = await schema.composeBranch(assn, selected) ?? [];
    const cacheRoots: SearchCacheRoot[] = roots.map((branch) => { return { assn, groupNest, branch }; });

    this.setState({
      isPickerOpen: true,
      pickerAssn: assn,
      pickerNest: groupNest,
      pickerRoots: roots,
      pickerSelected: collapsePrefixes(selected),
      pickerSearch: new SearchCache(schema, cacheRoots),
      pickerPurpose: PickerPurpose.AllowedBranch,
    });
  }

  private handleDeleteBranch(branchURI: string): void {
    const allowedValues = this.value.allowedValues as string[] ?? [];
    this.props.store.replaceAllowedValues(allowedValues.filter((uri) => uri != branchURI));
  }

  private handlePickerDialogCancel = () => {
    this.setState({ isPickerOpen: false });
  };

  private handlePickerDialogSubmit = (assn: TemplateAssignment, annotation: Annotation) => {
    const { pickerPurpose } = this.state;
    if (pickerPurpose == PickerPurpose.DefaultTerm) {
      this.props.store.replaceDefaultValue(annotation.valueURI);
    } else if (pickerPurpose == PickerPurpose.AllowedBranch) {
      const allowedValues = this.value.allowedValues as string[] ?? [];
      this.props.store.replaceAllowedValues([...(allowedValues.filter((uri) => uri != annotation.valueURI)), annotation.valueURI]);
    }
    this.setState({ isPickerOpen: false });
  };

  private handleClearDefault(): void {
    this.props.store.replaceDefaultValue(null);
  }

  private handleValuePopoverOpen = (popoverID: string, uri: string) => {
    const branch = OntologyTree.values.cachedBranch(uri);
    if (branch && branch.description != null) {
      this.setState({ popoverID, popoverURI: uri, popoverDescr: branch.description });
      return;
    }

    (async () => {
      const branch = await OntologyTree.values.getBranch(uri, true, true);
      this.setState({ popoverID, popoverURI: uri, popoverDescr: branch ? branch.description : null });
    })();
  };
}
