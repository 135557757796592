import { observer } from 'mobx-react';
import React, { CSSProperties } from 'react';

import { LocationNode } from '@/Samples/types';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Tooltip, IconButton } from '@mui/material';
import { Box } from '@mui/system';
import { LocationUtils } from '../location/locationUtils';
import { SortableTreeView } from '../../../components/SortableTreeView/SortableTreeView';

import { Img } from '@/shared/components/sanitizedTags.js';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import addIcon from 'ASSETS/images/cdd30/icons/add.png';
import deleteIcon from 'ASSETS/images/cdd30/icons/delete.png';
import GridOnIcon from '@mui/icons-material/GridOn';
import PlaceIcon from '@mui/icons-material/Place';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { StringOrNumber } from '@/types';
import { toJS } from 'mobx';

type Props = Omit<React.ComponentProps<typeof SortableTreeView>, 'renderItemContents'>
  & {
    nodes: Array<LocationNode>;
    selectedTreeNode: LocationNode;
    onChangeOrder(nodes: Array<LocationNode>,
      draggedNodeId?: StringOrNumber,
      expanded?: Array<string>): void;
    onSetSelectedNode(nodeId: StringOrNumber): void;
  };

@observer
export class EditLocationTreeView extends React.Component<Props> {
  handleChangeOrder = (nodes: Array<LocationNode>,
    draggedNodeId?: StringOrNumber,
    expanded?: Array<string>) => {
    this.props.onChangeOrder(nodes, draggedNodeId, expanded);
  };

  renderIcons = (node: LocationNode) => {
    const availableFeatures = LocationUtils.availableFeaturesAtLevel(node);
    const { nodes, onSetSelectedNode } = this.props;

    const selectNode = (nodeId: StringOrNumber | LocationNode) => {
      const id = (typeof nodeId === 'object') ? nodeId.id : nodeId;
      if (id) {
        setTimeout(() => {
          onSetSelectedNode(id);

          // find the DOM element with the data-treenodeid attribute matching id
          const element = document.querySelector(`[data-treenodeid="${id}"]`);
          if (element) {
            // scroll into view
            element.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }
        });
      }
    };

    const handleClickNewLocation = () => {
      const selectedTreeNode = LocationUtils.createNew(node, false);
      selectNode(selectedTreeNode);
    };

    const handleClickNewBox = () => {
      const selectedTreeNode = LocationUtils.createNew(node, true);
      selectNode(selectedTreeNode);
    };

    const handleClickDuplicate = () => {
      const selectedTreeNode = LocationUtils.duplicateLocation(node, node.parent);
      selectNode(selectedTreeNode);

      this.props.nodes.forEach(node => LocationUtils.ensureParentsOnChildren(node as LocationNode));

      const expanded = Array.from(new Set<string>([
        ...this.props.expanded,
        ...LocationUtils.getAllExpandedNodes([selectedTreeNode]),
      ]));

      // expand any nested nodes created by duplicate
      if (JSON.stringify(expanded) !== JSON.stringify(this.props.expanded)) {
        this.props.onChangeOrder(this.props.nodes, selectedTreeNode.id, expanded);
      }
    };

    const handleClickDelete = () => {
      if (!availableFeatures.deleteDisabled) {
        const newNodes = toJS(nodes) as Array<LocationNode>;
        newNodes.forEach(node => LocationUtils.ensureParentsOnChildren(node, true));
        LocationUtils.deleteNode(newNodes, node.id);
        this.setState({
          selectedTreeNode: null,
        });
        this.props.onChangeOrder(newNodes);
      }
    };

    const getVisibleStyle = (visible: boolean) =>
      ({ display: visible ? 'inline-block' : 'none' }) as CSSProperties;
    return <Box className='tree-item-icons'
      sx={{
        ':hover': {
          transition: 'opacity 0.3s',
          opacity: 1,
        },
        opacity: node.parent ? 0.3 : 1,
      }}
    >

      <Tooltip title="Create new location" style={getVisibleStyle(availableFeatures.addLocation)}>
        <IconButton onClick={handleClickNewLocation}>
          <Img src={addIcon} />
        </IconButton>
      </Tooltip>

      <Tooltip title="Create new organized or unorganized box" style={getVisibleStyle(availableFeatures.addBox)}>
        <IconButton onClick={handleClickNewBox}>
          <AddBoxOutlinedIcon />
        </IconButton>
      </Tooltip>

      <Tooltip title="Duplicate" style={getVisibleStyle(availableFeatures.duplicate)}>
        <IconButton onClick={handleClickDuplicate}>
          <ContentCopyIcon />
        </IconButton>
      </Tooltip>

      <Tooltip
        title={availableFeatures.deleteDisabled ? 'There are existing samples under this level, so delete is unavailable' : 'Delete'}
        style={getVisibleStyle(availableFeatures.delete)}>
        <IconButton onClick={handleClickDelete} className={availableFeatures.deleteDisabled ? 'disabled' : ''}>
          <Img src={deleteIcon} />
        </IconButton>
      </Tooltip>
    </Box>;
  };

  renderItemContents = (node: LocationNode) => {
    const childCounts = LocationUtils.countChildren(node);
    const label = node.value;
    const title = childCounts.used ? `${childCounts.used}/${childCounts.total}` : null;
    const labelAttributes = {};
    if (node.parent) {
      labelAttributes['data-draggable'] = true;
    }

    let icon = <PlaceIcon/>;
    if (LocationUtils.isNodeBox(node)) {
      icon = node.organized ? <GridOnIcon/> : <CheckBoxOutlineBlankIcon/>;
    }

    return <div className='tree-item-contents' data-treenodeid={'' + node.id}>
      <div className='label' {...labelAttributes}>
        <span className={`location-box-icon ${childCounts.used ? 'filled-box' : ''}`}>
          <Tooltip title={title}>{icon}</Tooltip>
        </span>
        {label}
      </div>
      <span className='icons'>
        {this.renderIcons(node)}</span>
    </div>;
  };

  canDragInto = (from: LocationNode, into: LocationNode) => {
    let result: boolean | LocationNode = true;

    const fromIsLocation = LocationUtils.isNodeLocation(from);
    const intoIsLocation = LocationUtils.isNodeLocation(into);
    const intoContainsLocations = intoIsLocation ? into.children?.some(child => LocationUtils.isNodeLocation(child)) : false;
    const intoContainsBoxes = intoIsLocation ? into.children?.some(child => LocationUtils.isNodeBox(child)) : false;

    if (!intoIsLocation) {
      // if dragging into a box, consider the box's parent instead
      result = into = into.parent;
    }

    if (fromIsLocation) {
      // dragging a location
      if (intoContainsBoxes) {
        // can't drag a location into a location that contains boxes
        return false;
      }
    } else {
      // dragging a box
      if (intoContainsLocations) {
        // can't drag a box into a location that contains locations
        return false;
      }
    }

    return result;
  };

  render() {
    const { selectedTreeNode, expanded, onSetSelectedNode, ...rest } = this.props; // eslint-disable-line @typescript-eslint/no-unused-vars

    if (!expanded.includes('-1')) {
      expanded.push('-1');
    }
    return <>
      <SortableTreeView
        {...rest}
        expanded={expanded}
        aria-label="location picker"
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        sx={{ height: '100%', flexGrow: 1 }}
        selected={'' + selectedTreeNode?.id}
        renderItemContents={this.renderItemContents}
        canDragInto={this.canDragInto}
        onChangeOrder={this.handleChangeOrder}
      />
      {/* in order to get the observer to trigger render on name change: */}
      <div className='hidden'>{selectedTreeNode?.value}</div>
    </>;
  }
}
