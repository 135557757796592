import DownloadIcon from '@mui/icons-material/Download';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import SettingsIcon from '@mui/icons-material/Settings';
import { Box, IconButton, Tooltip } from '@mui/material';
import React, { MouseEvent, useCallback } from 'react';
import launchVisualizationIcon from 'ASSETS/images/cdd30/icons/launch_visualization.svg';
import { Img } from '@/shared/components/sanitizedTags.js';

type ControlButtonProps = {
  onClick: (e: MouseEvent) => void;
  tooltip: string;
  disabled?: boolean;
};

export const ControlButton = (props: {
  onClick: (e: MouseEvent) => void,
  disabled?: boolean,
  tooltip: string,
  ariaLabel: string,
  children: JSX.Element,
  color?: string,
}) => {
  const { onClick, disabled, tooltip, ariaLabel, children } = props;
  return (
    <Tooltip title={tooltip}>
      <Box>
        <IconButton
          style={{ color: props.color || (disabled ? 'white' : 'primary') }}
          aria-label={ariaLabel}
          onClick={onClick}
          disabled={disabled}
        >
          {children}
        </IconButton>
      </Box>
    </Tooltip>
  );
};

export const DownloadButton = (props: ControlButtonProps) => {
  return (
    <ControlButton {...props} ariaLabel='download'>
      <DownloadIcon />
    </ControlButton>
  );
};

export const SettingsButton = (props: ControlButtonProps) => {
  return (
    <ControlButton {...props} ariaLabel='settings'>
      <SettingsIcon />
    </ControlButton>
  );
};

export const InfoButton = (props: ControlButtonProps) => {
  return (
    <ControlButton {...props} color='white' ariaLabel='information' >
      <HelpOutlineIcon />
    </ControlButton>
  );
};

export const LaunchVisualizationButton = (props: {
  getDataBlob: () => Blob;
  filename: string;
  disabled: boolean;
  tooltip?: string;
}) => {
  const { getDataBlob, filename, disabled, tooltip } = props;
  const handleClick = useCallback(() => {
    const blob = getDataBlob();
    const file = new File([blob], filename);

    const bc = new BroadcastChannel('file_channel');

    bc.onmessage = ({ data }) => {
      if (data.type === 'ready') {
        const { fileId } = data;
        bc.postMessage({ type: 'file', file, fileId });
        bc.close();
      }
    };

    const url = `${window.location.origin}/visualization`;
    const opened = window.open(url, '_blank');
    const fileId = new Date().getTime();
    opened.fileId = fileId;
  }, [getDataBlob]);
  return (
    <Box>
      <Tooltip title={tooltip || 'Launch Visualization'}>
        <Box>
          <IconButton
            aria-label='launch visualization'
            onClick={handleClick}
            disabled={disabled}
          >
            <Img src={launchVisualizationIcon} />
          </IconButton>
        </Box>
      </Tooltip>
    </Box>
  );
};
