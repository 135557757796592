import { FieldDataType, FieldDefinition } from '@/FieldDefinitions/types';
import EditFieldDefinitions, { EditFieldDefinitionRow } from './EditFieldDefinitionsList';

export type EditFieldDefinitionViewProps = React.ComponentProps<typeof EditFieldDefinitions> & {
  field_definitions: {
    field_definition: EditFieldDefinitionRow
  }[];
  vault_field_definitions_url: string;
  using_registration_system: boolean;
  locked: boolean;
};

export class FieldDefinitionUtils {
  static toFormData(collection: Record<string, FieldDefinition[]>, dataType?: string): FormData {
    const result = new FormData();
    collection = WebMolKit.deepClone(collection);

    // get the object keys, sorted in the order used in existing code. Not strictly necessary but helpful
    // for comparison.
    const getObjectKeys = (obj: Record<string, unknown> | FieldDefinition) => {
      const objectKeys = Object.keys(obj);
      const sortedKeys = [
        'molecule_fields_count',
        'unique_value',
        'required_group_number',
        'name',
        'display_order',
        'overwritable',
        'temp_group_number',
        'id',
        'inventory_sample_fields_count',
        'data_type_name',
        'is_sample_id',
        'disabled'];

      return [
        ...sortedKeys.filter(k => objectKeys.includes(k)),
        ...objectKeys.filter(k => !sortedKeys.includes(k)),
      ];
    };

    const preprocessRowFields = (rows: FieldDefinition[], index: number) => {
      const row = rows[index];
      if (row.data_type_name !== FieldDataType.PickList) {
        delete row.pick_list_values;
      }
      Object.assign(row, {
        data_type_name: FieldDataType.Text,
        ...row,
        display_order: index,
      });
      (row.pick_list_values ?? []).forEach((value) => {
        if (('' + value.id).startsWith('FAKE-ID')) {
          delete value.id;
        }
      });
    };

    const appendFormData = (prefix: string, row: FieldDefinition, key: string) => {
      const value = row[key] ?? '';
      if (typeof value === 'object') {
        getObjectKeys(value).forEach(subkey => {
          appendFormData(`${prefix}[${key}]`, value, subkey);
        });
      } else {
        result.append(`${prefix}[${key}]`, value);
      }
    };

    Object.keys(collection).forEach(collectionKey => {
      const rows = collection[collectionKey];
      rows.forEach((row, index) => {
        if (row.data_type_name !== FieldDataType.Project) {
          preprocessRowFields(rows, index);
          getObjectKeys(row).forEach(key => {
            appendFormData(`vault[${dataType ? `${dataType}_` : ''}${collectionKey}_attributes][${index}]`, row, key);
          });
        }
      });
    });

    return result;
  }

  static stripEntitiesFromPropsInPlace(props: any, entityName: string) {
    props.field_definitions = (props[`${entityName}_field_definitions`] ?? [])
      .map((child) => ({ field_definition: child[`${entityName}_field_definition`] }));
    props.vault_field_definitions_url = props[`vault_${entityName}_field_definitions_url`];
    props.locked = props[`vault_${entityName}_field_definitions_locked`];
    props.locked = props[`vault_${entityName}_field_definitions_locked`];

    delete props[`${entityName}_field_definitions`];
    delete props[`vault_${entityName}_field_definitions_url`];
    delete props[`${entityName}_field_definitions_locked`];
  }

  static stripEntitiesFromProps(props: any, entityName: string) {
    const result: EditFieldDefinitionViewProps = {
      ...props,
      field_definitions: (props[`${entityName}_field_definitions`] ?? [])
        .map((child) => (
          {
            field_definition: child[`${entityName}_field_definition`] ?? child,
          })),
      vault_field_definitions_url: props[`vault_${entityName}_field_definitions_url`],
      locked: props[`${entityName}_field_definitions_locked`],
    };

    delete result[`${entityName}_field_definitions`];
    delete result[`vault_${entityName}_field_definitions_url`];
    delete result[`${entityName}_field_definitions_locked`];

    return result;
  }

  static insertEntitiesIntoProps(props: EditFieldDefinitionViewProps, entityName: string) {
    const result = {
      ...props,
    };
    result[`${entityName}_field_definitions`] = props.field_definitions.map((child) => ({
      [`${entityName}_field_definition`]: child.field_definition,
    }));
    result[`vault_${entityName}_field_definitions_url`] = props.vault_field_definitions_url;
    result[`${entityName}_field_definitions_locked`] = props.locked;

    delete result.field_definitions;
    delete result.vault_field_definitions_url;
    delete result.locked;

    return result;
  }
}
