import { FieldDefinition } from '@/FieldDefinitions/types';
import { StringOrNumber } from '@/types';
import axios from 'axios';
import React from 'react';
import { ColumnDef } from '../../components/SimpleDataTable/SimpleDataTable';
import EditFieldDefinitions from './EditFieldDefinitionsList';
import { EditFieldDefinitionViewProps, FieldDefinitionUtils } from './fieldDefinitionUtils';
import { CDD } from '@/typedJS';

type Props = EditFieldDefinitionViewProps & {
  dataType: React.ComponentProps<typeof EditFieldDefinitions>['dataType'];
  columns: Array<ColumnDef>;
  fixedRows: Array<FieldDefinition>;
  dataTypeOptions?: Array<string>;
  processRows?: (rows: Array<FieldDefinition>) => Array<FieldDefinition>;
  renderCellForEditing?: (columnId: StringOrNumber, data: FieldDefinition) => React.ReactNode;
  renderCellForShow?: (columnId: StringOrNumber, data: FieldDefinition) => React.ReactNode;
};

type State = {
  rows: Array<FieldDefinition>;
  isEditing: boolean;
  errorMessages: Array<string> | null;
};

export default class EditFieldDefinitionView extends React.Component<Props, State> {
  sortFields = (a: FieldDefinition, b: FieldDefinition) => {
    return a.display_order - b.display_order;
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      ...props,
      rows: props.field_definitions.map(({ field_definition }) => field_definition)
        .sort(this.sortFields),
      isEditing: false,
      errorMessages: null,
    };
  }

  handleEdit = () => {
    this.setState({ isEditing: true });
  };

  handleCancelEdit = () => {
    this.setState({
      isEditing: false,
      errorMessages: null,
    });
  };

  handleSaveRows = (rows: Array<FieldDefinition>) => {
    const bodyFormData = FieldDefinitionUtils.toFormData(
      { field_definitions: rows }, this.props.dataType);
    const { processRows } = this.props;

    axios<unknown, { field_definition: FieldDefinition }[]>({
      method: 'put',
      url: this.props.vault_field_definitions_url,
      data: bodyFormData,
    })
      .then(response => {
        if (response.status === 200) {
          const key = `${this.props.dataType}_field_definition`;
          const rows = response.data.map(val => (val[key] ?? val));
          this.setState({
            isEditing: false,
            rows: (processRows?.(rows) ?? rows)
              .sort(this.sortFields),
          });
        }
      })
      .catch((error) => {
        let errorMessages = error.response?.data;
        if (errorMessages) {
          errorMessages = errorMessages instanceof Array ? errorMessages : [errorMessages];
        } else if (error.response?.status === 403) {
          errorMessages = [CDD.Axios.invalidCSRFMessage];
        } else {
          errorMessages = [
            'There was an error while submitting. Please contact support@collaborativedrug.com if the problem persists',
          ];
        }
        this.setState({ errorMessages, rows });
      });
  };

  render() {
    const { isEditing } = this.state;
    const { dataType, columns, fixedRows, dataTypeOptions, locked } = this.props;
    const rows = this.state.rows.filter(row => !row.amount_type && row.data_type_name !== 'Location');
    const customUniquenessNote = '';
    const id = `${dataType}FieldDefinitions${(isEditing ? '-edit' : '-show')}`;

    return <div id={`${dataType}FieldDefinitions`}>
      <div className='EditFieldDefinitionView' id={id}>
        <EditFieldDefinitions
          {...this.props}
          dataType={dataType}
          isSaving={false}
          isEditing={isEditing}
          onEdit={this.handleEdit}
          onCancelEdit={this.handleCancelEdit}
          onSubmit={this.handleSaveRows}
          columns={columns}
          locked={locked}
          rows={[
            ...fixedRows,
            ...rows]
          }
          dataTypeOptions={dataTypeOptions}
          errorMessages={this.state.errorMessages}
          numFixedRows={fixedRows.length}
          customUniquenessNote={customUniquenessNote}
        />
      </div>
    </div>;
  }
}
